import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import PropTypes from "prop-types";
import {
  Button,
  makeStyles,
  Typography,
  Backdrop,
  CircularProgress,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Drawer,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { zIndexes } from "../styles/defaultTheme";
import { PickedTransaction } from "./PickedOverview";

import { v4 as uuidv4 } from "uuid";
import { PairingDataContext } from "../components/context/PairingDataContext";
import { keyboardKey } from "../utils";
import CloseIcon from "@material-ui/icons/Close";
import { Comment } from "./Comment";

function CustomSelect({ optionsArray, value, onChangeValue }) {
  const UNASSIGNED = "unassigned";
  return (
    <FormControl variant="outlined" size="small">
      <InputLabel id="custom-select-assignee">Asignee</InputLabel>
      <Select
        labelId="custom-select-assignee"
        label="Assignee"
        onChange={(event) => {
          if (event.target.value === UNASSIGNED) {
            onChangeValue(null);
          } else {
            onChangeValue(event.target.value);
          }
        }}
        value={value ?? UNASSIGNED}
        style={{ minWidth: 120 }}
      >
        <MenuItem key={UNASSIGNED} value={UNASSIGNED}>
          No assignee
        </MenuItem>
        {optionsArray.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: zIndexes.commentTransactionOverlay,
    backgroundColor: "rgba(255, 255, 255, 0.65)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    backgroundColor: "#fff",
  },
  backdrop: {
    zIndex: zIndexes.commentTransactionOverlay,
    color: "#fff",
  },
  message: {
    marginTop: theme.spacing(2),
  },
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  drawerPaper: {
    width: 420,
  },
}));

function CommentTransactionOpen(props) {
  const {
    pairingData: { financeTeamJiraIdList },
  } = useContext(PairingDataContext);
  const { closeCommentTransactionPopup } = props;

  const [commentText, setCommentText] = useState("");
  const [assignedTo, setAssigendTo] = useState(null);
  const transactionPairingCommentUuid = useMemo(() => uuidv4(), []);

  const styles = useStyles();

  const textInputRef = React.useRef(null);
  useEffect(() => {
    textInputRef.current.focus();
  }, []);

  const onKeyDown = useCallback(
    (e) => {
      const key = keyboardKey(e);
      if (key.isEscape) {
        e.stopImmediatePropagation();
        closeCommentTransactionPopup();
      }
    },
    [closeCommentTransactionPopup]
  );

  useEffect(() => {
    const oldOnKeyDown = onKeyDown;
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", oldOnKeyDown);
    };
  }, [onKeyDown]);

  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const { addNewTransactionPairingCommentToAlreadyFetchedData } =
    useContext(PairingDataContext);

  const onSubmit = useCallback(
    ({
      action,
      transactionPairingCommentUuid,
      transactionUuid,
      commentText,
      assignedTo,
    }) => {
      setIsSubmitInProgress(true);
      setSubmitError(null);
      const newTransactionPairingComment = {
        uuid: transactionPairingCommentUuid,
        transaction_uuid: transactionUuid,
        text: commentText,
        assigned_to: assignedTo,
      };
      const url =
        action === "create"
          ? "/client-update/createTransactionPairingComment"
          : "/client-update/resolveTransactionPairingComment";

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newTransactionPairingComment),
      })
        .then(async (response) => {
          const body = await response.json();
          setIsSubmitInProgress(false);
          if (
            response.status === 200 &&
            body.ok === true &&
            body.transactionPairingComments
          ) {
            // success response
            addNewTransactionPairingCommentToAlreadyFetchedData(
              body.transactionPairingComments
            );
            closeCommentTransactionPopup();
          } else if (body.errors) {
            setSubmitError(body.error);
          } else {
            setSubmitError(
              body.error ?? `something went wrong? ${response.status}`
            );
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
          setSubmitError(`Error submiting request ${e}`);
          setIsSubmitInProgress(false);
        });
      // we can't perform this in finaly because when we get to finally this component can already be unloaded
      // so we update this state in respective branches correctly
      //.finally(() => setIsSubmitInProgress(false));
    },
    [
      setIsSubmitInProgress,
      setSubmitError,
      closeCommentTransactionPopup,
      addNewTransactionPairingCommentToAlreadyFetchedData,
    ]
  );

  const transactionUuid = props.transaction?.uuid;
  return (
    <>
      <DialogTitle className={styles.title}>
        {closeCommentTransactionPopup && (
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={closeCommentTransactionPopup}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {submitError && (
          <Alert severity="error">SubmitError: {submitError}</Alert>
        )}
        <div>
          <PickedTransaction {...props.transaction} />
          <div>
            {props.transaction.comments && (
              <section>
                {props.transaction.comments.map((comment) => (
                  <Comment comment={comment} key={comment.uuid} />
                ))}
              </section>
            )}
          </div>
        </div>
      </DialogContent>
      <Box px={3} pt={2} pb={1}>
        <TextField
          id="outlined-multiline-static"
          label="Comment"
          multiline
          rows={3}
          variant="outlined"
          ref={textInputRef}
          onChange={(e) => setCommentText(e.target.value)}
          value={commentText ?? ""}
          style={{ width: "100%" }}
        />
      </Box>
      <DialogActions>
        <Box mr="auto">
          <CustomSelect
            optionsArray={financeTeamJiraIdList}
            value={assignedTo}
            onChangeValue={setAssigendTo}
          />
        </Box>
        <Button
          onClick={() =>
            onSubmit({
              action: "resolve",
              transactionPairingCommentUuid,
              transactionUuid,
              commentText,
              assignedTo,
            })
          }
          disabled={isSubmitInProgress}
        >
          Resolve
        </Button>
        <Button
          onClick={() =>
            onSubmit({
              action: "create",
              transactionPairingCommentUuid,
              transactionUuid,
              commentText,
              assignedTo,
            })
          }
          disabled={isSubmitInProgress}
          color="primary"
        >
          Comment
        </Button>
      </DialogActions>
      <Backdrop className={styles.backdrop} open={isSubmitInProgress}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography>Submit is in progress</Typography>
        </div>
      </Backdrop>
    </>
  );
}

export function CommentTransaction(props) {
  const { setCommentTransaction } = props;
  const closeCommentTransactionPopup = useCallback(() => {
    setCommentTransaction(null);
  }, [setCommentTransaction]);

  const styles = useStyles();

  return (
    <Drawer
      classes={{
        paper: styles.drawerPaper,
      }}
      variant="temporary"
      anchor="right"
      open={props.commentTransaction !== null}
      onClose={closeCommentTransactionPopup}
    >
      {props.commentTransaction !== null && (
        <CommentTransactionOpen
          {...props}
          transaction={props.commentTransaction}
          closeCommentTransactionPopup={closeCommentTransactionPopup}
        />
      )}
    </Drawer>
  );
}

CommentTransaction.propTypes = {
  commentTransaction: PropTypes.object,
  setCommentTransaction: PropTypes.func,
};
