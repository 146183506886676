import React from "react";
import { STEP } from "./reducer";
import { MAIN_INVOICE_COLUMN_DEFINITION } from "./PickInvoice";
import { MAIN_TRANSACTION_COLUMN_DEFINITION } from "./PickTransaction";
import TableContainer from "@material-ui/core/TableContainer";
import {
  Box,
  Button,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

function takeValueFromFormatedValue(fomatedValue) {
  return Array.isArray(fomatedValue) ? `${fomatedValue[0]}` : `${fomatedValue}`;
}

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: 12,
    whiteSpace: "nowrap",
    tableLayout: "fixed",
  },
  th: {
    backgroundColor: "rgb(182, 223, 223)",
    borderBottom: "2px solid #7a81a0",
    textAlign: "left",
    borderRight: "1px dashed #fff",
    padding: "4px 8px",
    overflow: "hidden",
  },
  td: {
    borderRight: "1px dashed lightgrey",
    padding: "4px 8px",
  },
}));

export function PickedTransaction(props) {
  const styles = useStyles();
  return (
    <div>
      <h3>PICKED TRANSACTION:</h3>
      <TableContainer>
        <table className={styles.table}>
          <thead>
            <tr>
              {MAIN_TRANSACTION_COLUMN_DEFINITION.map((column, index) => {
                return (
                  <th
                    className={styles.th}
                    style={{ maxWidth: column.minWidth ?? 100 }}
                    key={index}
                    title={column.text ?? column.key}
                  >
                    {column.text ?? column.key}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {MAIN_TRANSACTION_COLUMN_DEFINITION.map((column, index) => {
                return (
                  <td key={index} className={styles.td}>
                    {column.format
                      ? takeValueFromFormatedValue(
                          column.format(props[column.key])
                        )
                      : `${props[column.key]}`}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <Divider />
    </div>
  );
}

function PickedInvoice(props) {
  const styles = useStyles();
  return (
    <div>
      <h3>PICKED INVOICE:</h3>
      {/* TODO not sure how to take correct text size */}
      <TableContainer>
        <table className={styles.table}>
          <thead>
            <tr>
              {MAIN_INVOICE_COLUMN_DEFINITION.map((column, i) => {
                return (
                  <th key={i} className={styles.th}>
                    {column.text ?? column.key}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {MAIN_INVOICE_COLUMN_DEFINITION.map((column, i) => {
                return (
                  <td key={i} className={styles.td}>
                    {column.format
                      ? takeValueFromFormatedValue(
                          column.format(props[column.key])
                        )
                      : `${props[column.key]}`}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <Divider />
    </div>
  );
}

function StepToHelperText(props) {
  switch (props.state.step) {
    case STEP.FIRST_TABLE:
      return (
        <Typography variant="h6" component="h1">
          Step 1: Pick {props.state.firstTableToShow} with Ctrl+P
        </Typography>
      );
    case STEP.SECOND_TABLE:
      return (
        <Typography variant="h6" component="h1">
          Step 2: Pick{" "}
          {props.state.firstTableToShow === "transaction"
            ? "invoice"
            : "transaction"}{" "}
          with Ctrl+P or skip with Ctrl+I
        </Typography>
      );
    case STEP.FILL_DETAILS:
      return (
        <Typography variant="h6" component="h1">
          Step 3: Fill additional details
        </Typography>
      );
    case STEP.SUBMITING:
      return (
        <Typography variant="h6" component="h1">
          Submiting pairing to server
        </Typography>
      );
    case STEP.SUBMIT_ERROR:
      return (
        <Typography variant="h6" component="h1">
          There was error during submit
        </Typography>
      );
    default:
      return null;
  }
}

export function PickedOverview(props) {
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ flexGrow: 0 }}>
        {props.state.step !== STEP.FIRST_TABLE && (
          <Button
            style={{ position: "absolute", right: 8, top: 8 }}
            onClick={() => props.goToBegining()}
            variant="contained"
          >
            <CloseIcon /> Discard changes and go back
          </Button>
        )}
        <Box p={1}>
          <StepToHelperText state={props.state} />
        </Box>
        {props.state.transaction ? (
          <PickedTransaction {...props.state.transaction} />
        ) : null}
        {props.state.invoice ? (
          <PickedInvoice {...props.state.invoice} />
        ) : null}
      </div>
      <div style={{ flexGrow: 1 }}>{props.children}</div>
    </div>
  );
}
