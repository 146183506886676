import React, {
  useMemo,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import _ from "lodash/fp";
import { FilterDrawerContextProvider } from "../components/context/FilterDrawerContext";
import ObjectTable from "../components/ObjectTable";

import * as format from "../definitions/format";
import { color } from "../components/style";
import { createPairingForTransactionWithMatchingRules } from "./matchingRules";
import { PairingDataContext } from "../components/context/PairingDataContext";
import { useMergeTransactionPairingCommentsIntoTransactions } from "./useMergeTransactionsInvoicesWithPairings";
import { CommentTransaction } from "./CommentTransaction";

const MAIN_TRANSACTION_COLUMN_DEFINITION = [
  {
    key: "hasUnresolvedComments",
    minWidth: 30,
    format: (v) => (v ? "\u274C" : ""),
  },
  {
    key: "hasCommentsCount",
    minWidth: 40,
    format: (v) => (v > 0 ? `${v}\u{1F4AC}` : ""),
  },
  {
    key: "hasUnresolvedCommentsAsignedToMe",
    minWidth: 30,
    format: (v) => (v ? "\u{2753}" : ""),
  },
  { key: "date", format: format.date },
  { key: "settlement_date", format: format.date },
  { key: "entity", minWidth: 50 },
  { key: "bank_account" },
  { key: "type", minWidth: 50 },
  { key: "amount", format: format.decimal },
  { key: "currency", minWidth: 50 },
  // { key: "amountUnpairedEur", format: format.eur },
  { key: "amountEur", format: format.eur },
  { key: "amount_original", format: format.decimal },
  { key: "currency_original", minWidth: 50 },

  { key: "card" },
  { key: "counterparty_name", minWidth: 120 },
  { key: "message" },
  { key: "refProcessed", minWidth: 30 },
  { key: "additional_info", minWidth: 30 },
  { key: "counterparty_bank", minWidth: 30 },
  { key: "counterparty_account", minWidth: 30 },
  { key: "uuid", minWidth: 75, format: (v) => `${v}` },
  //{ key: "account_type" },
  //{ key: "counter_party_type" },
  //{ key: "center_type" },
];

const NESTED_PAIRING_COLUMN_DEFINITION = [
  {
    key: "error",
    format: (e) =>
      e == null || e === "" ? "" : [`${e}`, { backgroundColor: color.red }],
  },
  {},
  {},
  //{ key: "uuid" },
  //{ key: "amount", format: format.decimal },
  //{ key: "currency" },
  //{ key: "account_from" },
  { key: "account_to" },
  { key: "po" },
  { key: "center" },
  { key: "non_tax_expense" },
  { key: "pid" },
];

function ShowPairedTransactions(props) {
  const [sortBy, sortChange] = useState(null);

  const [commentTransaction, setCommentTransaction] = useState(null);
  const transactionsWithComments =
    useMergeTransactionPairingCommentsIntoTransactions(
      props.transactions,
      props.transactionPairingComments
    );

  const { deleteTranscationId, getSelectedObjectsRef } = props;
  const onCtrlD = useCallback(
    ({ nestedObject, mainObject }) => {
      const { mainObjectsIncludingParentsOfNetesd = [] } =
        getSelectedObjectsRef?.current?.() ?? {};
      mainObjectsIncludingParentsOfNetesd.forEach((t) => {
        deleteTranscationId(t.uuid);
      });
    },
    [getSelectedObjectsRef, deleteTranscationId]
  );

  return (
    <FilterDrawerContextProvider defaultOpen={Boolean(false)}>
      <CommentTransaction
        commentTransaction={commentTransaction}
        setCommentTransaction={setCommentTransaction}
      />
      <ObjectTable
        dataObjectList={transactionsWithComments}
        sortBy={sortBy}
        sortChange={sortChange}
        mainColumnDefinition={MAIN_TRANSACTION_COLUMN_DEFINITION}
        nestedColumnDefinition={NESTED_PAIRING_COLUMN_DEFINITION}
        nestedProperty={"proposed_pairings"}
        onCtrlP={() => {
          /*do nothing*/
        }}
        onCtrlI={() => {
          /*do nothing*/
        }}
        onCtrlD={onCtrlD}
        onCtrlK={({ nestedObject, mainObject }) =>
          mainObject && setCommentTransaction(mainObject)
        }
        getSelectedObjectsRef={getSelectedObjectsRef}
      />
    </FilterDrawerContextProvider>
  );
}

export const PairWithMatchingRules = (props) => {
  const { addNewPairingsToAlreadyFetchedData } = useContext(PairingDataContext);
  const getSelectedObjectsRef = useRef(null);

  const [deletedTransactionsIds, setDeletedTransactionsIds] = useState([]);
  const deleteTranscationId = useCallback(
    (transactionId) => {
      setDeletedTransactionsIds((deletedTransactions) => [
        ...deletedTransactions,
        transactionId,
      ]);
    },
    [setDeletedTransactionsIds]
  );

  const matchedTransactions = useMemo(() => {
    const filteredTransactions = props.transactions
      .filter((t) => t.whitelisted)
      .filter((t) => t.pairings.length === 0);
    filteredTransactions.forEach((transaction) => {
      const pairing = createPairingForTransactionWithMatchingRules(
        transaction,
        props.matchingRules
      );
      transaction.proposed_pairings = pairing != null ? [pairing] : [];
    });
    return filteredTransactions.filter((t) => t.proposed_pairings.length > 0);
  }, [props.transactions, props.matchingRules]);

  const matchedTransactionWithoutDeleted = useMemo(() => {
    return _.take(
      20, //TODO after we have most transaction paired
      matchedTransactions
        .filter((t) => deletedTransactionsIds.indexOf(t.uuid) === -1)
        .sort((a, b) => Math.abs(b.amountEur) - Math.abs(a.amountEur))
    );
  }, [matchedTransactions, deletedTransactionsIds]);

  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [errorPairings, setErrorPairings] = useState(null);

  const onSubmit = useCallback(
    (allTransactions) => {
      const { mainObjects: selectedMainObjects = [] } =
        getSelectedObjectsRef?.current?.() ?? {};
      const selectedTransactionsUuid = new Set(
        selectedMainObjects.map((t) => t.uuid)
      );
      const transactions = allTransactions.filter((t) =>
        selectedTransactionsUuid.has(t.uuid)
      );
      if (transactions.length === 0) {
        setSubmitError("No picked transcations to submit");
        return;
      }

      setIsSubmitInProgress(true);
      setSubmitError(null);
      setErrorPairings(null);
      const transactionWithoutUnresolvedComments = transactions.filter(
        (t) => t.hasUnresolvedComments === false
      );
      const pairings = transactionWithoutUnresolvedComments.map(
        (t) => t.proposed_pairings[0]
      );
      fetch("/client-update/pairTransactionInvoice", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pairings),
      })
        .then(async (response) => {
          const body = await response.json();
          if (response.status === 200 && body.ok === true && body.pairings) {
            // success response
            addNewPairingsToAlreadyFetchedData(body.pairings);
          } else if (body.errors) {
            setSubmitError(body.error);
            setErrorPairings(body.errors);
          } else {
            setSubmitError(
              body.error ?? `something went wrong? ${response.status}`
            );
          }
        })
        .catch((e) => {
          setSubmitError(`Error submiting request ${e}`);
        })
        .finally(() => setIsSubmitInProgress(false));
    },
    [
      setIsSubmitInProgress,
      setSubmitError,
      setErrorPairings,
      addNewPairingsToAlreadyFetchedData,
    ]
  );

  const matchedTransactionWithoutDeletedWithErrors = useMemo(() => {
    matchedTransactionWithoutDeleted.forEach((transaction) => {
      if (!errorPairings) return;
      if (transaction.proposed_pairings) {
        const pairing = transaction.proposed_pairings[0];
        if (errorPairings[pairing.uuid] != null) {
          pairing.error = errorPairings[pairing.uuid];
        }
      }
      return;
    });
    return matchedTransactionWithoutDeleted;
  }, [matchedTransactionWithoutDeleted, errorPairings]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ flexGrow: 0 }}>
        <button
          disabled={isSubmitInProgress}
          onClick={() => onSubmit(matchedTransactionWithoutDeletedWithErrors)}
        >
          Submit selected transactions
        </button>
        {submitError ? `Error: ${submitError}` : null}
      </div>
      <div style={{ flexGrow: 1 }}>
        <ShowPairedTransactions
          {...props}
          getSelectedObjectsRef={getSelectedObjectsRef}
          transactions={matchedTransactionWithoutDeletedWithErrors}
          deleteTranscationId={deleteTranscationId}
        />
      </div>
    </div>
  );
};
