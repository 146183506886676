import _ from "lodash";

export const color = {
  headerBg: "#F9F8F6",
  nestedHeaderBg: "#b6dfdf",
  headerText: "#252B41",
  positive: "#252B41",
  negative: "#FF0000",
  matchGeneral: "#98F4A1",
  matchSpecific: "#1EDC31",
  iconBg: "#EDECEB",
  iconHoverBg: "#F9F8F6",
  headerIconBg: "#EDECEB",
  headerIconHoverBg: "#F9F8F6",
  nestedRows: "#dbfcff",
  red: "#ff0000",
};

export const headerStyle = {
  color: color.headerText,
  backgroundColor: color.headerBg,
  bold: true,
};

export const nestedHeaderStyle = {
  color: color.headerText,
  backgroundColor: color.nestedHeaderBg,
  bold: true,
};

export const highlightedHeaderStyle = {
  ...headerStyle,
  color: "#FF0000",
};

export const iconFonts = {
  regular: '12px "Font Awesome 5 Free"',
  small: '10px "Font Awesome 5 Free"',
};

export const grandTotalBold = true;

const gridRowsColors = {
  lvl6: "#D6D6D6",
  lvl5: "#DBDBDB",
  lvl4: "#E0E0E0",
  lvl3: "#E5E5E5",
  lvl2: "#EFEFEF",
  lvl1: "#F9F9F9",
};

const getRowColor = (depth) => {
  return gridRowsColors[`lvl${Math.min(6, depth)}`];
};

// gradually changing colors for totals in different depth
// from smaller subtotals to grand total
export const totalStyles = _.range(
  1,
  Object.keys(gridRowsColors).length + 1
).map((depth) => ({
  backgroundColor: getRowColor(depth),
}));
