import React, { Component } from "react";

const CellDetailContext = React.createContext();

class CellDetailContextProvider extends Component {
  state = {
    isCellDetail: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isCellDetail !== prevState.isCellDetail) {
      return { isCellDetail: nextProps.isCellDetail };
    }
    return null;
  }

  render() {
    return (
      <CellDetailContext.Provider
        value={{
          isCellDetail: this.state.isCellDetail,
        }}
      >
        {this.props.children}
      </CellDetailContext.Provider>
    );
  }
}

export { CellDetailContext, CellDetailContextProvider };
