import React, { Component } from "react";

const PivotMenuContext = React.createContext();

class PivotMenuContextProvider extends Component {
  state = {
    isOpen: false,
    isSearchOpen: false,
  };

  setIsOpen = (isOpen) => {
    this.setState({ isOpen });
  };

  setIsSearchOpen = (isSearchOpen) => {
    this.setState({ isSearchOpen });
  };

  render() {
    return (
      <PivotMenuContext.Provider
        value={{
          isOpen: this.state.isOpen,
          setIsOpen: this.setIsOpen,
          isSearchOpen: this.state.isSearchOpen,
          setIsSearchOpen: this.setIsSearchOpen,
        }}
      >
        {this.props.children}
      </PivotMenuContext.Provider>
    );
  }
}

export { PivotMenuContext, PivotMenuContextProvider };
