import React, { Component } from "react";

const RefContext = React.createContext();

class RefContextProvider extends Component {
  state = {
    pivotRef: null,
    gridCanvasWrapperRef: null,
  };

  setPivotRef = (pivotRef) => {
    this.setState({ pivotRef });
  };

  setGridCanvasWrapperRef = (gridCanvasWrapperRef) => {
    this.setState({ gridCanvasWrapperRef });
  };

  render() {
    return (
      <RefContext.Provider
        value={{
          pivotRef: this.state.pivotRef,
          setPivotRef: this.setPivotRef,
          gridCanvasWrapperRef: this.state.gridCanvasWrapperRef,
          setGridCanvasWrapperRef: this.setGridCanvasWrapperRef,
        }}
      >
        {this.props.children}
      </RefContext.Provider>
    );
  }
}

export { RefContext, RefContextProvider };
