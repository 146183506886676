module.exports = {
  budget: "budget",
  payroll: "payroll",
  overheads: "overheads",
  spent: "spent",
  delta: "delta",
  offAndFlex: "off+flex",
  hours: "hours",
  simplematicsError: "simplematics error",
  equity: "equity"
};