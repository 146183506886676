import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  accordion: {
    "&, &.Mui-expanded, &.Mui-expanded:first-child": {
      margin: theme.spacing(2, 0, 0, 0),
    },
    "&.MuiAccordion-rounded": {
      borderRadius: "4px",
    },
    "&::before": {
      display: "none",
    },
  },
  heading: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
  headingValue: {
    fontWeight: 700,
  },
  summary: {
    "&.Mui-expanded": {
      backgroundColor: theme.palette.grey.light,
    },
  },
  summaryInner: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inner: {
    padding: theme.spacing(2, 4),
  },
  cell: {
    padding: theme.spacing(0.5, 2),
    textAlign: "right",
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },
  headCell: {
    padding: theme.spacing(1, 2),
    fontWeight: 700,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));

const ValidationAccordion = ({
  title,
  headCols,
  tableRows,
  errorsCount,
  visibleCount,
  isTruncated,
  defaultExpanded,
  colWidths,
}) => {
  const styles = useStyles();

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      classes={{
        root: styles.accordion,
      }}
      TransitionProps={{
        timeout: 0,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-accordion`}
        id={`${title}-accordion`}
        classes={{
          root: styles.summary,
        }}
      >
        <div className={styles.summaryInner}>
          <Typography className={styles.heading}>{title}</Typography>
          {errorsCount &&
            (isTruncated && visibleCount ? (
              <Typography variant="body2">
                Showing
                <span className={styles.headingValue}> {visibleCount} </span>
                issues out of
                <span className={styles.headingValue}> {errorsCount} </span>
              </Typography>
            ) : (
              <Typography variant="body2">
                <span className={styles.headingValue}>{errorsCount}</span>{" "}
                issues
              </Typography>
            ))}
        </div>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: styles.inner,
        }}
      >
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              {headCols.map((col, i) => (
                <TableCell
                  key={i}
                  className={clsx(styles.cell, styles.headCell)}
                  style={colWidths && { width: `${colWidths[i]}%` }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

ValidationAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  headCols: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableRows: PropTypes.arrayOf(PropTypes.node).isRequired,
  errorsCount: PropTypes.number.isRequired,
  visibleCount: PropTypes.number,
  isTruncated: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  colWidths: PropTypes.arrayOf(PropTypes.number),
};

export default ValidationAccordion;
