import _ from "lodash";
import { format as formatDate, addMonths } from "date-fns";
import {
  // ToDateFilter,
  PLByMonthFilter,
  PLByMonthSplitFilter,
  PLFilter,
  BudgetFilter,
  PayrollFilter,
  CardholderViewFilter,
  StatutoryAccountingFilter,
  EmployeeOverheadsFilter,
  // BalanceSheetFilter,
  ReVsLeClaimsFilter,
  // CounterpartyBalancesFilter,
  NonTaxTransactionsFilter,
} from "./components";
import { arr2str } from "../pivot";
import * as pivotTransforms from "./pivotTransforms";
import * as sourceTransforms from "./sourceTransforms";

import * as format from "./format";
import * as source from "./source";
import * as pivot from "./pivot";

import $ from "@pivottable/common/gl";
import $c from "@pivottable/common/literals";
import { $sa, colWidths as saColWidths } from "@pivottable/common/sa";
import { sourceLink, sourceLinkSA } from "../utils";

import { sum, sumWithNaN } from "./aggregate.js";

const domainsOrder = ["Labs", "Back office", "Group", "SO", "XC", "Wincent"];
const accountOrder = ["incomes", "expenses"];
const accountTypesOrder = [
  "BCF",
  "ASSETS",
  "LIABILITIES",
  "EQUITY",
  "NULLABLES",
  "P&L",
];
const payrollColOrder = { 2: [$c.payroll, $c.overheads, $c.equity, $c.hours] };
const colDetailWidths = [
  170, 90, 120, 120, 90, 90, 60, 140, 180, 180, 120, 300, 120, 120, 100, 0, 0,
  100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 130,
];

const sourceFormat = {
  [$.date]: format.date,
  [$.amtEur]: format.eur,
  [$.amtCur]: format.decimal,
  [$.month]: format.month,
  [$.from]: (v) => [v.replace("expenses", "exp"), {}],
  [$.to]: (v) => [v.replace("expenses", "exp"), {}],
  [$.lastEditedAt]: format.dateTime,
};
const saSourceFormat = {
  [$sa.date]: format.date,
  [$sa.taxDate]: format.date,
  [$sa.createdAt]: format.date,
  [$sa.savedAt]: format.date,
  [$sa.amtEur]: format.eur,
  [$sa.amtCur]: format.decimal,
  [$sa.month]: format.month,
};

function getPartnerType(name) {
  const match = name.match(/^([^:-]*[:-])/);
  if (match == null) return "other";
  else if (name.startsWith("c:") && name.endsWith("SP")) return "c:SP";
  else if (name.startsWith("c:") && name.endsWith("RU")) return "c:RU";
  else return match[1];
}

function getAccountType(account) {
  if (account.startsWith("expenses")) {
    return "expenses";
  } else if (account.startsWith("incomes")) {
    return "incomes";
  }
  return "unknown";
}

function getAccountPrefix(account, length) {
  if (account) return account.slice(0, length);
  return account;
}

function PayrollFormat(value, titles, filterState) {
  if (titles[2] === $c.hours || filterState.type === $c.hours)
    return format.hour(value);
  return value === 0 ? [""] : format.eur(value);
}

function PLNumberFormat(title) {
  if (title[0] === $c.hours) return format.hour;
  if (title[0] === "gr. margin") return format.percent;
  return format.eur;
}

function PLStatusData(tree, filter) {
  return [
    [
      "IT Revenue",
      format.eur(
        _.get(
          tree,
          [arr2str(["VL", "Labs", "IT-Client"]), arr2str(["incomes"])],
          0
        ) +
          _.get(
            tree,
            [arr2str(["VL", "Labs", "Outsourcing"]), arr2str(["incomes"])],
            0
          )
      )[0],
    ],
  ];
}

// sometimes we want to aggregate by transaction native currency and in case
// currencies differ we switch to euro, values are pre-converted
function sumCurrencyAndEur(
  [[val1Cur, val1Eur], [val2Cur, val2Eur]],
  [cur1, cur2]
) {
  if (cur1 === cur2) return [val1Cur + val2Cur, val1Eur + val2Eur];
  else return [val1Eur + val2Eur, val1Eur + val2Eur];
}

function balanceSheetStatusData(tree, filter) {
  return [
    [
      "A+L+Eq",
      format.eur(
        _.get(tree, [arr2str(["ASSETS"]), ""], 0) +
          _.get(tree, [arr2str(["LIABILITIES"]), ""], 0) +
          _.get(tree, [arr2str(["EQUITY"]), ""], 0)
      )[0],
    ],
  ];
}

function getDefaultPayrollFilter() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();

  // if date is between 1.1-15.2, from = January of previous year
  // otherwise from = January of current year
  const from =
    currentMonth === 0 || (currentMonth === 1 && currentDay <= 15)
      ? new Date(currentYear - 1, 0)
      : new Date(currentYear, 0);

  // if date is between 1.1-15.1, to = November of previous year,
  // else if date is between 16.1-15.2, to = December of previous year,
  // else if date is between 1.-15. of current month, to = (current month - 2) of current year,
  // else if date is bigger than 15. of current month, to = (current month - 1) of current year,
  const to = (function () {
    if (currentMonth === 0 && currentDay <= 15) {
      return new Date(currentYear - 1, 10);
    } else if (currentMonth === 0 || (currentMonth === 1 && currentDay <= 15)) {
      return new Date(currentYear - 1, 11);
    } else if (currentDay <= 15) {
      return new Date(currentYear, currentMonth - 2);
    } else {
      return new Date(currentYear, currentMonth - 1);
    }
  })();

  return { from: formatDate(from, "YYYY-MM"), to: formatDate(to, "YYYY-MM") };
}

export const pageTypes = {
  PIVOT_TABLE: "pivotTable",
  DATA_TABLE: "dataTable",
};

export const tableNames = {
  counterpartiesBalances: "Counterparties balances",
  balanceSheet: "Balance sheet",
  PL: "P&L",
  PLByMonth: "P&L by month",
  PLByMonthSplit: "P&L by month split",
  nonTax: "Non-Tax transactions",
  budgeting: "Budgeting",
  futureCommisions: "Future Commissions",
  payrolExpenses: "Payroll Expenses Overview",
  cardholder: "Cardholder View",
  statutoryAccounting: "Statutory accounting by month",
  employeeOverheads: "Employee Overheads",
  reVsLeClaims: "RE vs. LE",
  pairingFirstTransactionThenInvoice:
    "Pair transaction with invoice optionally",
  pairingFirstInvoiceThanTransaction:
    "Pair invoice with transaction optionally",
  pairingTransationWithMatchingRules:
    "Automatch transactions by matching rules",
  pairingTransationWithMatchingInvoices: "Automatch transactions with invoices",
  employeeLiability: "Employee Liability",
};

export const tableUrlNames = _.fromPairs(
  _.toPairs(tableNames).map(([key, name]) => [key, name.replaceAll(" ", "_")])
);

export default (
  rates,
  cards,
  allowedHierarchy,
  jiraId,
  monthlyForex,
  employeeActiveUntil
) => [
  {
    name: tableNames.counterpartiesBalances,
    urlName: tableUrlNames.counterpartiesBalances,
    groupBy: [[(r) => getPartnerType(r[$.po]), $.po, $.cur], [$.to]],
    valueKey: $.appx0,
    fn: (values = [], titles = []) => {
      if (values.length === 0) return [0, 0];
      const currs = titles.map((t) => t[0][2] || "EUR");
      if (titles.some(([, [account]]) => _.isNil(account))) {
        values = values.map((v) => v.map(Math.abs));
      }
      const res = sumCurrencyAndEur(values, currs);
      return res;
    },
    sourceTransform:
      sourceTransforms.transformCounterpartiesBalancesSource(monthlyForex),
    // component: CounterpartyBalancesFilter,
    defaultFilter: () => ({ from: null, to: format.today()[0] }),
    pivotTransform: pivotTransforms.counterpartiesBalances(monthlyForex),
    dropSubTotals: [[0, 1, 2], []],
    dropCollapsing: [[1], []],
    sourceFormat,
    pivotFormat: format.decimal,
    colWidths: [[80, 150, 50], 100],
    colDetailWidths,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
    headerFormat: (titles, dim, i, j) =>
      dim === 1 && _.get(titles, [dim, i, j], null) == null
        ? () => "Sum of ||"
        : null,
  },
  {
    name: tableNames.balanceSheet,
    urlName: tableUrlNames.balanceSheet,
    groupBy: [
      [$.appx1, $.appx2, $.to, $.pid],
      [$.cur, $.appx0],
    ],
    valueKey: $.appx3,
    fn: (values = [], titles = []) => {
      if (values.length === 0) return [0, 0];
      const currs = titles.map(
        (t) => (t[1][0] === "Other" ? t[1][1] : t[1][0]) || "EUR"
      );
      return sumCurrencyAndEur(values, currs);
    },
    sourceTransform: sourceTransforms.transformBalanceSource(monthlyForex),
    pivotTransform: pivotTransforms.balance,
    // component: BalanceSheetFilter,
    defaultFilter: () => ({
      to: format.today()[0],
      reportingEntities: [],
      legalEntities: [],
    }),
    dropSubTotals: [[], [1]],
    sourceFormat,
    pivotFormat: format.decimal,
    hiddenCategories: [[3], [1]],
    colWidths: [[40, 40, 70, 140], 100],
    colDetailWidths,
    sourceLink,
    statusData: balanceSheetStatusData,
    pageType: pageTypes.PIVOT_TABLE,
    rowTitlesOrder: { 0: accountTypesOrder },
  },
  {
    name: tableNames.PL,
    urlName: tableUrlNames.PL,
    groupBy: [[$.domain, $.unit, $.department, $.category, $.center], [$.from]],
    valueKey: $.amtEur,
    pivotFormat: (v, t) => PLNumberFormat(t)(v),
    fn: sum,
    component: PLFilter,
    defaultFilter: () => ({
      from: formatDate(addMonths(new Date(), -1), "YYYY-MM"),
      to: formatDate(addMonths(new Date(), -1), "YYYY-MM"),
      glAccount: [],
    }),
    sourceTransform: sourceTransforms.transformPLSource(allowedHierarchy),
    pivotTransform: pivotTransforms.PL,
    sourceFormat,
    hiddenCategories: [[2, 3, 4], []],
    colWidths: [[50, 40, 40, 40, 150], 120],
    colDetailWidths,
    sourceLink,
    statusData: PLStatusData,
    pageType: pageTypes.PIVOT_TABLE,
    rowTitlesOrder: { 0: domainsOrder },
    customCellDetailComparisonFunctions: [
      [
        1,
        0,
        (rv, qv, row) =>
          !qv
            ? true
            : qv === "hours"
            ? row[$.hours] !== "" && row[$.from] !== "expenses/overheads"
            : qv === rv,
      ],
    ],
  },
  {
    name: tableNames.PLByMonth,
    urlName: tableUrlNames.PLByMonth,
    groupBy: [
      [$.domain, $.unit, $.department, $.category, $.center],
      [$.year, $.month],
    ],
    valueKey: $.amtEur,
    fn: sum,
    component: PLByMonthFilter,
    defaultFilter: () => ({
      months: ["YTD"],
      glAccount: [],
    }),
    sourceTransform: sourceTransforms.transformPLSource(allowedHierarchy),
    sourceFormat,
    hiddenCategories: [[2, 3, 4], []],
    colWidths: [[50, 40, 40, 40, 150], 120],
    colDetailWidths,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
    rowTitlesOrder: { 0: domainsOrder },
  },
  {
    name: tableNames.PLByMonthSplit,
    urlName: tableUrlNames.PLByMonthSplit,
    groupBy: [
      [
        (r) => getAccountType(r[$.from]),
        $.domain,
        $.unit,
        $.department,
        $.category,
        $.center,
      ],
      [$.year, $.month],
    ],
    valueKey: $.amtEur,
    fn: sum,
    component: PLByMonthSplitFilter,
    defaultFilter: () => ({
      months: ["YTD"],
      glAccount: [],
    }),
    sourceTransform: sourceTransforms.transformPLSource(allowedHierarchy),
    sourceFormat,
    hiddenCategories: [[3, 4], []],
    colWidths: [[50, 50, 40, 40, 40, 150], 120],
    colDetailWidths,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
    rowTitlesOrder: { 0: accountOrder, 1: domainsOrder },
  },
  {
    name: tableNames.nonTax,
    urlName: tableUrlNames.nonTax,
    groupBy: [
      [$.domain, $.unit, $.department, $.category, $.center],
      [$.year, $.month],
    ],
    valueKey: $.amtEur,
    fn: sum,
    component: NonTaxTransactionsFilter,
    defaultFilter: () => ({ months: [`${new Date().getFullYear()}`] }),
    sourceTransform: sourceTransforms.transformNonTaxTransactionsSource,
    sourceFormat,
    hiddenCategories: [[2, 3, 4], [1]],
    colWidths: [[50, 40, 40, 40, 150], 120],
    colDetailWidths,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
    rowTitlesOrder: { 0: domainsOrder },
  },
  {
    name: tableNames.budgeting,
    urlName: tableUrlNames.budgeting,
    groupBy: [
      [$.domain, $.unit, $.department, $.category, $.center, $.appx0, $.appx1],
      [$.month, $.appx2],
    ],
    valueKey: $.amtEur,
    defaultFilter: () => ({
      year: `${new Date().getFullYear()}`,
      view: "monthly",
    }),
    pivotFormat: (v) => (isNaN(v) ? [""] : format.eurWithoutDecimal(v)),
    fn: sumWithNaN,
    component: BudgetFilter,
    sourceTransform: sourceTransforms.transformBudgetSource(allowedHierarchy),
    pivotTransform: pivotTransforms.budget,
    dropSubTotals: [[], [0]],
    sourceFormat,
    hiddenCategories: [[2, 3, 4, 5], []],
    colWidths: [[50, 40, 40, 40, 40, 100, 100], 90],
    colDetailWidths,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
    dropCollapsing: [[], [0]],
    rowTitlesOrder: { 0: domainsOrder },
    customCellDetailComparisonFunctions: [
      [1, 0, (rv, qv) => (!qv || qv.startsWith("Total") ? true : qv === rv)],
      [1, 1, (rv, qv) => (qv === $c.delta ? true : qv === rv)],
    ],
  },
  {
    name: tableNames.futureCommisions,
    urlName: tableUrlNames.futureCommisions,
    groupBy: [[$.po, $.center], []],
    valueKey: $.amtEur,
    fn: sum,
    sourceFormat,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
    sourceTransform: () =>
      source.filter((r) => r[$.to] === "liabilities/future"),
    pivotTransform: (table) =>
      pivot.filter(table, ([rt, v]) => 1e-2 < Math.abs(v[0])),
    colWidths: [[150, 110], 90],
    colDetailWidths,
  },
  {
    name: tableNames.payrolExpenses,
    urlName: tableUrlNames.payrolExpenses,
    groupBy: [[$.po], [$.year, $.month, $.appx0]],
    valueKey: $.appx1,
    pivotFormat: PayrollFormat,
    fn: sum,
    component: PayrollFilter,
    defaultFilter: () => getDefaultPayrollFilter(),
    sourceTransform: sourceTransforms.transformPayrollSource,
    pivotTransform: pivotTransforms.payrollExpenses,
    dropSubTotals: [[], [0, 1, 2]],
    sourceFormat,
    hiddenCategories: [[], [1, 2]],
    colWidths: [[170], 100],
    colDetailWidths,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
    customCellDetailComparisonFunctions: [
      [
        1,
        0,
        (rv, qv) => (!qv || qv.startsWith("Grand Total") ? true : qv === rv),
      ],
      [1, 1, (rv, qv) => (!qv || qv === "Total" ? true : qv === rv)],
      [
        1,
        2,
        (rv, qv, row) => (qv ? qv === rv : rv !== "hours" || row[$.appx3]),
      ],
    ],
    colTitlesOrder: payrollColOrder,
  },
  {
    name: tableNames.cardholder,
    urlName: tableUrlNames.cardholder,
    groupBy: [
      [$.appx0, $.appx1],
      [$.year, $.month],
    ],
    valueKey: $.amtEur,
    fn: sum,
    component: CardholderViewFilter,
    defaultFilter: () => ({ months: ["YTD"] }),
    sourceTransform: sourceTransforms.transformCardholderSource(
      allowedHierarchy,
      cards,
      jiraId
    ),
    sourceFormat,
    colDetailWidths,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
  },
  {
    name: tableNames.statutoryAccounting,
    urlName: tableUrlNames.statutoryAccounting,
    groupBy: [
      [
        (r) => getAccountPrefix(r[$sa.account], 1),
        (r) => getAccountPrefix(r[$sa.account], 3),
        $sa.account,
      ],
      [$sa.year, $sa.month],
    ],
    valueKey: $sa.amtEur,
    fn: sum,
    component: StatutoryAccountingFilter,
    defaultFilter: () => ({ entities: ["vacuumlabs s.r.o."], period: "YTD" }),
    sourceTransform: sourceTransforms.transformStatutoryAccountingSource,
    pivotTransform: pivotTransforms.statutoryAccounting,
    dropSubTotals: [[0], [0, 1]],
    sourceFormat: saSourceFormat,
    hiddenCategories: [[2, 3], []],
    colWidths: [[70, 70, 70], 100],
    colDetailWidths: saColWidths,
    sourceLink: sourceLinkSA,
    pageType: pageTypes.PIVOT_TABLE,
  },
  {
    name: tableNames.employeeOverheads,
    urlName: tableUrlNames.employeeOverheads,
    groupBy: [
      [$.appx0, $.pid, $.centerKey],
      [$.year, $.month],
    ],
    valueKey: $.amtEur,
    fn: sum,
    component: EmployeeOverheadsFilter,
    defaultFilter: () => getDefaultPayrollFilter(),
    sourceTransform: sourceTransforms.transformEmployeeOverheadsSource,
    dropSubTotals: [[], [1]],
    sourceFormat,
    hiddenCategories: [[1, 2], []],
    colWidths: [[60, 170, 170], 100],
    colDetailWidths,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
  },
  {
    name: tableNames.reVsLeClaims,
    urlName: tableUrlNames.reVsLeClaims,
    groupBy: [
      [$.reportingEntity, $.entity],
      [$.year, $.month],
    ],
    valueKey: $.amtEur,
    fn: sum,
    component: ReVsLeClaimsFilter,
    defaultFilter: () => ({ months: ["YTD"] }),
    sourceTransform: sourceTransforms.reVsLeClaimsSource,
    sourceFormat,
    colWidths: [[80, 120], 100],
    colDetailWidths,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
  },
  {
    name: tableNames.employeeLiability,
    urlName: tableUrlNames.employeeLiability,
    groupBy: [[$.appx2, $.po], [$.cur]],
    valueKey: $.appx0,
    pivotFormat: format.decimal,
    //fn: sum,
    fn: (values = [], titles = []) => {
      if (values.length === 0) return [0, 0];
      const currs = titles.map((t) => t[1][0] || "EUR");
      const res = sumCurrencyAndEur(values, currs);
      return res;
    },
    // component: ToDateFilter,
    defaultFilter: () => ({ to: format.today()[0] }),
    sourceTransform: sourceTransforms.employeeLiability(employeeActiveUntil),
    pivotTransform: ({ titles, values }) => {
      values = _.map(values, (row) => _.map(row, (v) => v && v[0]));
      return { titles, values };
    },
    sourceFormat,
    colWidths: [[100, 170], 100],
    colDetailWidths,
    sourceLink,
    pageType: pageTypes.PIVOT_TABLE,
  },
  {
    name: tableNames.pairingTransationWithMatchingRules,
    urlName: tableUrlNames.pairingTransationWithMatchingRules,
  },
  {
    name: tableNames.pairingTransationWithMatchingInvoices,
    urlName: tableUrlNames.pairingTransationWithMatchingInvoices,
  },
  {
    name: tableNames.pairingFirstTransactionThenInvoice,
    urlName: tableUrlNames.pairingFirstTransactionThenInvoice,
  },
  {
    name: tableNames.pairingFirstInvoiceThanTransaction,
    urlName: tableUrlNames.pairingFirstInvoiceThanTransaction,
  },
];

export const urlNamesThatRequireFinanceTeamMembership = new Set([
  tableUrlNames.pairingTransationWithMatchingRules,
  tableUrlNames.pairingTransationWithMatchingInvoices,
  tableUrlNames.pairingFirstTransactionThenInvoice,
  tableUrlNames.pairingFirstInvoiceThanTransaction,
]);
