import { useContext, useMemo } from "react";
import { createPairingForTransactionWithMatchingRules } from "../pairingComponents/matchingRules";
import { simplematicsLinkFromInvoice } from "@pivottable/common/utils";
import _ from "lodash/fp";
import { AppContext } from "../components/DataLoader";
import { normalise } from "@pivottable/common/latinMap";

//Extracting vs from reference, reused in matching
function reference2vs(t) {
  const emptyReference = ["NOTPROVIDED"];
  if (emptyReference.includes(t.reference)) {
    return null;
  }
  const vsRegex = /\/VS(?<vsExtracted>\d+)\/.*/;
  return vsRegex.test(t.reference)
    ? t.reference.match(vsRegex).groups.vsExtracted
    : t.reference;
}

function invoiceId2IdNumber(i) {
  const idDashesRemoved = i.id.split("-").join("");
  const idNumberRegex = /[^\d]*([\d]+)/;
  return idNumberRegex.test(idDashesRemoved)
    ? idDashesRemoved.match(idNumberRegex)[1]
    : i.id;
}

//extracting reasonable phrase from invoice counterparty
function counterpartyExtracted(i) {
  if (!i.counterparty) return null;
  if (i.counterparty === "Všeobecný dodávateľ") return null;

  const prohibitedMatches = [
    "limited",
    "company",
    "slovakia",
    "czechia",
    "ireland",
    "ceska",
    "czech",
    "slovensko",
    "slovenska",
    "republika",
    "republic",
    "hungary",
    "bratislava",
    "_nepouzivat",
  ];
  const counterpartyLongEnough = i.counterparty
    .split(/[\s,.]+/)
    .filter((s) => s.length > 4)
    .filter((s) => !prohibitedMatches.includes(normalise(s)));

  return counterpartyLongEnough.map(normalise);
}

// Point of this hook is to transform transaction and invoice
// into better shape
// it add pairings to according objects
// calculate amounts in eur currency
// add additional fields
export function useMergeTransactionsInvoicesWithPairings(
  transactions,
  invoices,
  transactionInvoicePairing,
  convertToEur,
  matchingRules,
  dailyForex,
  monthlyForex
) {
  const transationUuidToIndex = useMemo(() => {
    const uuidToIndex = {};
    transactions.forEach((transaction, index) => {
      if (transaction.uuid) {
        uuidToIndex[transaction.uuid] = index;
      }
    });
    return uuidToIndex;
  }, [transactions]);

  const invoiceEntityIdToIndex = useMemo(() => {
    const idToIndex = {};
    invoices.forEach((invoice, index) => {
      if (invoice.id && invoice.entity) {
        idToIndex[`${invoice.entity}-${invoice.id}`] = index;
      }
    });
    return idToIndex;
  }, [invoices]);

  const [transactionWithPairings, invoicesWithPairings] = useMemo(() => {
    // 1. DELETE EXISTING pairings FROM transactions AND invoices
    transactions.forEach((t) => (t.pairings = []));
    invoices.forEach((i) => (i.pairings = []));

    // 2. ADD transcaction, invoice INTO pairing
    // 3. POTENTIONALY ADD pairing TO transactions[x].pairings AND invoices[y].pairings
    transactionInvoicePairing.forEach((pairing) => {
      pairing.amountEur = convertToEur(pairing.amount, pairing.currency);
      // add transaction to pairing if it contains transaction_uuid
      if (
        pairing.transaction_uuid &&
        transationUuidToIndex[pairing.transaction_uuid] != null
      ) {
        pairing.transaction =
          transactions[transationUuidToIndex[pairing.transaction_uuid]];
      }
      // add invoice to pairing if it contains invoice_entity and invoice_id
      const invoiceEntityId =
        pairing.invoice_entity && pairing.invoice_id
          ? `${pairing.invoice_entity}-${pairing.invoice_id}`
          : null;
      if (invoiceEntityId && invoiceEntityIdToIndex[invoiceEntityId] != null) {
        pairing.invoice = invoices[invoiceEntityIdToIndex[invoiceEntityId]];
      }

      if (pairing.invoice) {
        pairing.invoice.pairings.push(pairing);
      }
      if (pairing.transaction) {
        pairing.transaction.pairings.push(pairing);
      }
    });

    // 4. ADD ADDITIONAL KEYS TO TRANSACTIONS

    transactions.forEach((t) => {
      t.is_matched_by_matching_rules =
        createPairingForTransactionWithMatchingRules(t, matchingRules) != null;
      t.amountEur = convertToEur(t.amount, t.currency);
      const pairedAmount = _.sumBy("amountEur", t.pairings);
      t.amountUnpairedEur = t.amountEur - pairedAmount;
      t.counterparty_merged = `A:${t.counterparty_account ?? ""} B:${
        t.counterparty_bank ?? ""
      } N:${t.counterparty_name ?? ""}`;
      t.refProcessed = reference2vs(t);
      t.normalizedCounterpartyName = normalise(t.counterparty_name);
      t.last_matched_at = _.maxBy("created_at", t.pairings)?.created_at;
      t.last_matched_by = _.maxBy("created_at", t.pairings)?.created_by;
    });

    // 5. ADD ADDITIONAL KEYS TO INVOICE

    invoices.forEach((i) => {
      i.entity_id = `${i.entity}-${i.id}`;

      i.amount = (i.incoming ? -1 : 1) * i.pay;
      const pairedAmount = _.sumBy("amount", i.pairings);
      i.amountUnpaired = i.amount - pairedAmount;

      i.amountEur = convertToEur(i.amount, i.currency); //TODO redo this using forex rates we have in DB
      const pairedAmountEur = _.sumBy("amountEur", i.pairings);
      i.amountUnpairedEur = i.amountEur - pairedAmountEur;

      i.url = simplematicsLinkFromInvoice(i);

      i.counterpartyExtracted = counterpartyExtracted(i);

      i.idNumber = invoiceId2IdNumber(i);

      i.last_matched_at = _.maxBy("created_at", i.pairings)?.created_at;
      i.last_matched_by = _.maxBy("created_at", i.pairings)?.created_by;
    });
    // for every new recalculation we need to create new instance so children under us can see changes
    return [[...transactions], [...invoices]];
  }, [
    transactions,
    transactionInvoicePairing,
    invoices,
    invoiceEntityIdToIndex,
    transationUuidToIndex,
    convertToEur,
    matchingRules,
  ]);
  return [transactionWithPairings, invoicesWithPairings];
}

// this is not part of previous function as commenst can be changed often
// so we want to merge them as last thing especialy when we propose pairings
// with mathcing rules or mathcing invoices otherwise we get recomputation
// more often and also in every recomputation of proposed pairinges we generate
// new uuids that is problematic
export function useMergeTransactionPairingCommentsIntoTransactions(
  transactions,
  transactionPairingComments
) {
  const { jiraId } = useContext(AppContext);

  const newTransactions = useMemo(() => {
    const transactionUuidToComments = {};
    transactionPairingComments.forEach((comment) => {
      if (!transactionUuidToComments[comment.transaction_uuid]) {
        transactionUuidToComments[comment.transaction_uuid] = [];
      }
      transactionUuidToComments[comment.transaction_uuid].push(comment);
    });
    return transactions.map((t) => {
      const existingComments = transactionUuidToComments[t.uuid];
      if (existingComments) {
        t.comments = existingComments;
        t.hasCommentsCount = existingComments.length;
        t.hasUnresolvedComments = existingComments.some(
          (comment) => comment.resolved_at === null
        );
        t.hasUnresolvedCommentsAsignedToMe = existingComments.some(
          (comment) =>
            comment.resolved_at === null && comment.assigned_to === jiraId
        );
      } else {
        t.comments = null;
        t.hasCommentsCount = 0;
        t.hasUnresolvedComments = false;
        t.hasUnresolvedCommentsAsignedToMe = false;
      }
      return t;
    });
  }, [transactions, transactionPairingComments, jiraId]);
  return newTransactions;
}
