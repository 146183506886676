import React from "react";
import ReactDOM from "react-dom";
import { Box, styled } from "@material-ui/core";
import { filterWidth } from "../styles/defaultTheme";

const Wrapper = styled(Box)({
  overflow: "hidden",
});

export class AutoSizer extends React.PureComponent {
  state = { size: null };
  animationLoopId = null;

  componentDidMount() {
    this.measure();
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationLoopId);
  }

  measure = () => {
    const el = ReactDOM.findDOMNode(this);
    const oldSize = this.state.size;
    const newSize = [
      el.offsetWidth - (this.props.isFilterOpen ? filterWidth : 0),
      el.offsetHeight,
    ];
    if (
      oldSize == null ||
      oldSize[0] !== newSize[0] ||
      oldSize[1] !== newSize[1]
    ) {
      this.setState({ size: newSize });
    }

    this.animationLoopId = window.requestAnimationFrame(this.measure);
  };

  render() {
    return (
      <Wrapper className="autosizer">
        {this.state.size && this.props.children(this.state.size)}
      </Wrapper>
    );
  }
}
