import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Typography } from "@material-ui/core";
import HelpButton from "./HelpButton";
import { RefContext } from "./context/RefContext";
import { AppContext } from "./DataLoader";

const useTopBarStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    paddingRight: theme.spacing(2),
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey.main,
  },
  searchWrapper: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
    marginLeft: "auto",
  },
  helpWrapper: {
    margin: theme.spacing(0.75, 0, 0.75, 2),
    userSelect: "none",
  },
  showValidationWrapper: {
    margin: theme.spacing(0.75, 0, 0.75, 2),
    userSelect: "none",
  },
}));

const useSectionStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    borderRight: "1px solid",
    borderRightColor: theme.palette.grey.main,
  },
  smallWrapper: {
    margin: theme.spacing(1, 0),
  },
  noBorderWrapper: {
    border: "none",
  },
  negativeText: {
    color: theme.palette.error.dark,
  },
  boldText: {
    fontWeight: "bold",
  },
  statusText: {
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.grey.button,
    color: theme.palette.text.primary,
    padding: theme.spacing(0.25, 0.75, 0.25, 1),
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "20px",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const SumComponent = ({ sum, sumFormatter, sumSpecialStatus }) => {
  const styles = useSectionStyles();

  return (
    <div className={styles.wrapper}>
      <Typography
        variant="body2"
        className={clsx(
          sum < 0 && styles.negativeText,
          sumSpecialStatus && styles.boldText
        )}
      >
        {sumSpecialStatus && "SUM: "}
        {sumFormatter(sum)}
      </Typography>
    </div>
  );
};

const ShowValidationButton = () => {
  const styles = useSectionStyles();
  const { showValidationFunc } = useContext(AppContext);

  return (
    <div role="button" className={styles.button} onClick={showValidationFunc}>
      Show validation
    </div>
  );
};

const CountComponent = ({ count }) => {
  const styles = useSectionStyles();

  return (
    <div className={styles.wrapper}>
      <Typography variant="body2">Count: {count}</Typography>
    </div>
  );
};

const StatusComponent = ({ statusData }) => {
  const styles = useSectionStyles();

  return (
    <div
      className={clsx(
        styles.wrapper,
        styles.smallWrapper,
        styles.noBorderWrapper
      )}
    >
      {statusData.map((r, i) => (
        <Typography variant="body2" className={styles.statusText} key={i}>
          {r[0]}: {r[1]}
        </Typography>
      ))}
    </div>
  );
};

const TopBar = ({
  count,
  sum,
  sumFormatter,
  sumSpecialStatus,
  statusData,
  searchComponent,
}) => {
  const { setPivotRef } = useContext(RefContext);
  const setRef = useCallback((el) => setPivotRef(el), [setPivotRef]);
  const styles = useTopBarStyles();
  const { validation } = useContext(AppContext);

  return (
    <div className={styles.wrapper}>
      <div id="pivot-menu" ref={setRef} />
      {sum !== null && sum !== "" && (
        <SumComponent {...{ sum, sumFormatter, sumSpecialStatus }} />
      )}
      <CountComponent {...{ count }} />
      {statusData && <StatusComponent {...{ statusData }} />}
      <div className={styles.searchWrapper}>{searchComponent}</div>
      {validation && (
        <div className={styles.showValidationWrapper}>
          <ShowValidationButton />
        </div>
      )}
      <div className={styles.helpWrapper}>
        <HelpButton />
      </div>
    </div>
  );
};

const sumComponentPropTypes = {
  sum: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]), // number but can be string if e.g. title is selected
  sumFormatter: PropTypes.func,
  sumSpecialStatus: PropTypes.bool,
};

const countComponentPropTypes = {
  count: PropTypes.number,
};

const statusComponentPropTypes = {
  statusData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

const topBarPropTypes = {
  ...sumComponentPropTypes,
  ...countComponentPropTypes,
  ...statusComponentPropTypes,
  searchComponent: PropTypes.node,
};

SumComponent.propTypes = sumComponentPropTypes;
CountComponent.propTypes = countComponentPropTypes;
StatusComponent.propTypes = statusComponentPropTypes;
TopBar.propTypes = topBarPropTypes;

export default TopBar;
