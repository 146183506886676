import * as accountTypes from "@pivottable/common/accounts";

export const Subtypes = {
  bcf: "Bcf",
  investments: "Investments",
  cash: "Cash",
  cashTransfers: "Cash transfers",
  bankAccounts: "Bank accounts",
  receivables: "Receivables",
  liabilities: "Liabilities",
  accruedTaxes: "Accrued taxes",
  equity: "Equity",
  nullables: "Nullables",
  pl: "P&L",
};

// prepare map with entries - account subtype = {accounts: array, prefix: string}
// then subtype includes account A if meets one of conditions:
// 1) array 'accounts' includes A or
// 2) A starts with 'prefix' (if prefix is null, condition isn't applied)
export const BSStructure = {
  // ATTENTION!!! - correct subtype determination depends
  // on order of conditions (keys have to be strings)
  [Subtypes.bcf]: {
    accounts: ["2018/bcf", "cash/unknown"],
    prefix: "bcf/",
  },
  [Subtypes.investments]: {
    accounts: [],
    prefix: "investments",
  },
  [Subtypes.cash]: {
    accounts: ["cash/braintree/rc", "cash/other"],
    prefix: "cash/register",
  },
  [Subtypes.cashTransfers]: {
    accounts: ["cash/transit", "intercompany"],
    prefix: null,
  },
  [Subtypes.bankAccounts]: {
    accounts: [],
    prefix: "cash",
  },
  [Subtypes.receivables]: {
    accounts: ["receivables", "expenses/pass"],
    prefix: "claims",
  },
  [Subtypes.liabilities]: {
    accounts: ["payables", "lita", "salaries"],
    prefix: "liabilities",
  },
  [Subtypes.accruedTaxes]: {
    accounts: [],
    prefix: "vat",
  },
  [Subtypes.equity]: {
    accounts: ["equity"],
    prefix: null,
  },
  [Subtypes.pl]: {
    accounts: Array.from(accountTypes.PLX),
    prefix: null,
  },
};

export const BSSubtypeToType = {
  [Subtypes.bcf]: "BCF",
  [Subtypes.investments]: "ASSETS",
  [Subtypes.cash]: "ASSETS",
  [Subtypes.bankAccounts]: "ASSETS",
  [Subtypes.cashTransfers]: "ASSETS",
  [Subtypes.receivables]: "ASSETS",
  [Subtypes.liabilities]: "LIABILITIES",
  [Subtypes.accruedTaxes]: "LIABILITIES",
  [Subtypes.equity]: "EQUITY",
  [Subtypes.nullables]: "NULLABLES",
  [Subtypes.pl]: "P&L",
};
