import _ from "lodash";
import $ from "@pivottable/common/gl";
import $c from "@pivottable/common/literals";
import { formatDate } from "@pivottable/common/utils";
import { findAvgRatesForMonth } from "@pivottable/common/forexUtils";
import { arr2str, pivot as pivotMap } from "../pivot";
import { sum } from "./aggregate";
import { hasPrefix } from "../table";
import { PL as PLaccounts } from "@pivottable/common/accounts";
import * as pivot from "./pivot";

const currenciesCols = ["EUR", "CZK", "HUF", "GBP", "USD", "ADA"];

export const counterpartiesBalances =
  (monthlyForex) =>
  (table, data, { to } = {}) => {
    const rates = findAvgRatesForMonth(
      monthlyForex,
      (to || formatDate(new Date())).substring(0, 7)
    );
    const { titles, values } = table;
    for (const row of values) {
      for (let i = 0; i < row.length; i++) {
        row[i] = row[i] ? row[i][0] : row[i];
      }
    }
    return pivot.filter(
      { titles, values },
      ([rt, v]) => 1e-2 < _.last(v) / rates[rt[2]] || rt[0] === "e:"
    );
  };

export function balance({ titles, values }) {
  for (const row of values) {
    for (let i = 0; i < row.length; i++) {
      row[i] = row[i] ? row[i][0] : row[i];
    }
  }
  const currencies = [...currenciesCols, "Other"];
  // prepare map specifying order of currency
  const currencyOrder = currencies.reduce(
    (res, c, idx) => ({ ...res, [c]: idx + 1 }),
    {}
  );

  // prepare array [title, oldIndex, currencyOrder], sort it by currencyOrder, then assign new index
  const columnIdxToIdx = titles[1]
    .map((t, idx) => [t, idx, currencyOrder[t[0]] || currencies.length + 1])
    .sort((t1, t2) => (t1[2] < t2[2] ? -1 : t1[2] === t2[2] ? 0 : 1))
    .map((t, idx) => [t[0], t[1], idx]);

  // reorder columns from old to new indexes
  columnIdxToIdx.forEach(([t, , newIdx]) => {
    titles[1][newIdx] = t;
  });

  // reorder values to new indexes
  for (const [, row] of _.zip(titles[0], values)) {
    columnIdxToIdx
      .map(([, i, j]) => [row[i], j])
      .forEach(([val, j]) => {
        row[j] = val;
      });
  }
  return { titles, values };
}

export function budget({ titles, values }, __, filterState) {
  const totalColumns = [$c.budget, $c.spent, $c.delta];

  values = values.map((r) => {
    const yearSum = {
      [$c.budget]: null,
      [$c.spent]: 0,
    };
    r.forEach((value, idx) => {
      const columnTitle = titles[1][idx];
      const isDataColumn = columnTitle.length === 2;

      if (isDataColumn) {
        const columnValueType = columnTitle[1];
        if (yearSum[columnValueType] !== undefined && !isNaN(value)) {
          yearSum[columnValueType] += value;
        }
      }
    });
    // add sum of budget, sum of spent values and delta sum (delta = budget + spent)
    // to the end of value row
    r.push(
      yearSum[$c.budget],
      yearSum[$c.spent],
      yearSum[$c.budget] + yearSum[$c.spent]
    );

    return r;
  });
  // add 'summary' columns spent, budget and left (for whole year)
  titles[1].push(...totalColumns.map((col) => ["Total", col]));

  titles[1].forEach((title) => {
    // as total column for month is sum of spent and budget columns, it is possible to use
    // this column as delta column
    const isTotalColumn = title.length === 1;
    if (isTotalColumn) {
      title.push($c.delta);
    }
  });

  // hide budget and left columns for months (keep them only for year total)
  if (filterState && filterState.view === "yearly") {
    const hiddenColsIdxes = new Set(
      _.entries(titles[1])
        .filter((t) => t[1][0] !== "Total" && t[1][1] !== "spent")
        .map((t) => t[0])
    );

    titles[1] = titles[1].filter((t, idx) => !hiddenColsIdxes.has(`${idx}`));
    values = values.map((r) =>
      r.filter((value, idx) => !hiddenColsIdxes.has(`${idx}`))
    );
  }

  // hide (budget) records with flag 'budget' on last no-empty level
  // (to avoid empty line in last level)
  [titles[0] = [], values = []] = _.unzip(
    _.zip(titles[0], values).filter((r) => {
      const budgetFlagIdx = r[0].indexOf($c.budget);
      return !(
        budgetFlagIdx !== -1 &&
        r[0].slice(budgetFlagIdx + 1).every((t) => t === "")
      );
    })
  );
  return { titles, values };
}

export function PL({ titles, values }, data) {
  const val = (row, rg) =>
    _.sum(
      Array.from(titles[1].entries())
        .filter(([, [t]]) => rg.test(t))
        .map(([i]) => row[i])
    );

  const lastIdx = titles[1].length - 1;

  data = data.filter(
    (r) => r[$.hours] !== "" && r[$.from] !== "expenses/overheads"
  );
  const hours = pivotMap(
    data,
    [
      [$.domain, $.unit, $.department, $.category, $.center].map(
        (c) => (r) => r[c]
      ),
      [],
    ],
    $.hours,
    sum
  );
  let cols = Array.from(PLaccounts)
    .map((s) => [s, titles[1].findIndex((v) => v[0] === s)])
    .filter(([, index]) => index >= 0);
  cols = _.zipWith(cols, cols.map(([, i]) => i).sort(), (c, i) => [...c, i]);
  const extras = ["hours", "eff. rate", "profit/hr", "gr. margin"];
  cols.forEach(([t, , i]) => {
    titles[1][i][0] = t;
  });
  titles[1].push(...extras.map((x) => [x]));
  for (const [title, row] of _.zip(titles[0], values)) {
    cols
      .map(([, i, j]) => [row[i], j])
      .forEach(([val, j]) => {
        row[j] = val;
      });
    const key = arr2str(title);
    if (hours[key] == null) {
      row.push(..._.times(extras.length, _.constant(null)));
      continue;
    }
    const rowHours = hours[key][arr2str([])];
    row.push(rowHours);

    if (hasPrefix(["VL", "Labs", "IT-Client"], title) === -1) {
      row.push(..._.times(extras.length - 1, _.constant(null)));
      continue;
    }

    const effRate =
      (val(row, /incomes/) + val(row, /expenses\/regular/)) / rowHours;

    const profPerHour = row[lastIdx] / rowHours;

    const grMargin =
      (row[lastIdx] - val(row, /expenses\/onetime/)) /
      (val(row, /incomes/) + val(row, /expenses\/regular/));

    row.push(effRate, profPerHour, grMargin);
  }
  return { titles, values };
}

function isDataColumn(columnTitle) {
  return columnTitle.length === 3;
}

function isMonthTotalColumn(columnTitle) {
  return columnTitle.length === 2;
}

function isYearTotalColumn(columnTitle) {
  return columnTitle.length === 1;
}

export function payrollExpenses({ titles, values }) {
  const foundTypes = new Set(
    titles[1].reduce((acc, t) => {
      if (isDataColumn(t)) acc.push(t[2]);
      return acc;
    }, [])
  );
  const order = [$c.payroll, $c.overheads, $c.equity, $c.hours];
  const extraTotals = order.filter((type) => foundTypes.has(type));
  const onlyHours = extraTotals.length === 1 && extraTotals[0] === $c.hours;
  const totalCalc = onlyHours
    ? (payroll, overhead, equity, hours) => hours
    : (payroll, overhead, equity, hours) => payroll + overhead + equity;

  // modify horizontal titles by adding "total" columns
  const titlesWithTotals = [];
  titles[1].forEach((t) => {
    // total columns for "year" have title with length 1 (e.g. ['2020'])
    if (t.length === 1) {
      // add Total column 'group' for every year instead of default year total column
      titlesWithTotals.push(
        ...extraTotals.map((o) => [t[0], "Total", o]),
        [t[0], "Total"],
        [t[0]]
      );
    } else {
      titlesWithTotals.push(t);
    }
  });
  // add Grand Total column 'group' after very last column
  titlesWithTotals.push(
    ...extraTotals.map((o) => ["Grand Total", "", o]),
    ["Grand Total", ""],
    ["Grand Total"]
  );
  // calculate sums of payroll, overheads, equity and hours for years and total sums in every row
  values = values.map((r) => {
    const yearSum = {
      [$c.payroll]: 0,
      [$c.overheads]: 0,
      [$c.equity]: 0,
      [$c.hours]: 0,
    };
    const allSum = {
      [$c.payroll]: 0,
      [$c.overheads]: 0,
      [$c.equity]: 0,
      [$c.hours]: 0,
    };
    let lastHour = 0;
    const valuesWithTotals = [];
    r.forEach((v, idx) => {
      const columnTitle = titles[1][idx];

      if (isDataColumn(columnTitle)) {
        valuesWithTotals.push(v);

        const columnValueType = columnTitle[2];
        if (yearSum[columnValueType] !== undefined) {
          yearSum[columnValueType] += v;
        }
        if (columnValueType === $c.hours) {
          lastHour = v;
        }
      } else if (isMonthTotalColumn(columnTitle)) {
        // hours have to be subtracted from sum in month total column
        valuesWithTotals.push(extraTotals.length === 1 ? v : v - lastHour);
        lastHour = 0;
      } else if (isYearTotalColumn(columnTitle)) {
        // push years sums instead of value of year total column
        const yearTotalSum = totalCalc(...order.map((o) => yearSum[o]));
        valuesWithTotals.push(
          ...extraTotals.map((o) => yearSum[o]),
          yearTotalSum,
          yearTotalSum
        );
        order.forEach((o) => {
          allSum[o] += yearSum[o];
          yearSum[o] = 0;
        });
      } else {
        valuesWithTotals.push(v);
      }
    });
    // push total sums at the end of every values row
    const allTotalSum = totalCalc(...order.map((o) => allSum[o]));
    valuesWithTotals.push(
      ...extraTotals.map((o) => allSum[o]),
      allTotalSum,
      allTotalSum
    );
    return valuesWithTotals;
  });

  titles[1] = titlesWithTotals;
  return { titles, values };
}

function isTotalColumn(columnTitle) {
  return columnTitle.length !== 2;
}

export function statutoryAccounting({ titles, values }, data, filterState) {
  values.forEach((r) => {
    // set state on account for first column of view (if it's null, set it to 0 eur)
    r[0] = r[0] || 0;
    for (let i = 1; i < r.length; i++) {
      if (isTotalColumn(titles[1][i])) {
        // for total columns cell value = state on account after period => state on account
        // after last month of the period to which the total belongs
        r[i] = r[i - 1];
      } else {
        // for non total columns cell value = state on account at the end of the month
        // => sum of transactions in month + state on account after previous month
        r[i] = r[i] + r[i - 1];
      }
    }
  });
  // when calculation of the end of month states on accounts is done,
  // remove columns of months not belonging to period
  if (filterState.period) {
    let year = filterState.period;
    if (filterState.period === "YTD") {
      year = `${new Date().getFullYear()}`;
    }

    // hide not belonging months columns from vertical titles
    const firstTitleIsYear = (title) => title[0] === year;
    const hiddenColsIdxes = new Set(
      _.entries(titles[1])
        .filter((t) => !firstTitleIsYear(t[1]))
        .map((t) => t[0])
    );
    titles[1] = titles[1].filter((t) => firstTitleIsYear(t));

    // remove values on hidden indexes
    values = values.map((r) =>
      r.filter((value, idx) => !hiddenColsIdxes.has(`${idx}`))
    );
  }
  return { titles, values };
}
