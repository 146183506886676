import _ from "lodash";

// NULL char used to separate multi-atribute key in maps
export const SEP = String.fromCharCode(0);

export function arr2str(arr) {
  return (arr.length ? SEP : "") + arr.join(SEP);
}

export function str2arr(key) {
  return key.length ? key.substr(1).split(SEP) : [];
}

export function strRelKey(k1, k2) {
  [k1, k2] = [k1, k2].map((k) => (typeof k === "string" ? k : arr2str(k)));
  if (k1.length > k2.length) [k1, k2] = [k2, k1];

  return (
    k1 === k2 || (k2[k1.length] === SEP && k2.substring(0, k1.length) === k1)
  );
}

function computeSubtotals(tree, path, key, combiner) {
  const value = _.get(tree, [...path, key]);
  let subkey = key;
  while (subkey) {
    subkey = subkey.substr(0, subkey.lastIndexOf(SEP));
    tree = _.set(
      tree,
      [...path, subkey],
      combiner(
        [_.get(tree, [...path, subkey], combiner()), value],
        [[...path, subkey].map(str2arr), [...path, key].map(str2arr)]
      )
    );
  }
  return tree;
}

export function pivot(data, groupBy, valueKey, fn) {
  let tree = {};
  const identity = fn();
  // calculate values for leafs
  for (const row of data) {
    const key = groupBy.map((gbd) => gbd.map((gb) => gb(row))).map(arr2str);
    _.set(tree, key, fn([row[valueKey], _.get(tree, key, identity)]));
  }
  for (const row of _.keys(tree)) {
    for (const col of _.keys(tree[row]))
      tree = computeSubtotals(tree, [row], col, fn);
  }
  const merger = ([x, y] = [{}, {}], [[tr0], [tr1]] = [[[]], [[]]]) => {
    for (const key of Object.keys(y)) {
      if (x[key] === undefined) x[key] = identity;
      // some agg functions are slow and its better not to execute them unless
      // necessary, so skip runs with identity element
      if (!_.isEqual(y[key], identity)) {
        x[key] = fn(
          [x[key], y[key]],
          [
            [tr0, str2arr(key)],
            [tr1, str2arr(key)],
          ]
        );
      }
    }
    return x;
  };
  for (const row of _.keys(tree)) {
    tree = computeSubtotals(tree, [], row, merger);
  }
  return tree;
}
