import React from "react";
import _ from "lodash/fp";
import memoize from "memoize-one";
import GridCanvasWrapper from "./GridCanvasWrapper";
import PropTypes from "prop-types";
import { pivot as _pivot, arr2str, SEP } from "../pivot";
import { table, pivotTable } from "../table";
import {
  headerStyle,
  highlightedHeaderStyle,
  totalStyles,
  color,
  iconFonts,
  grandTotalBold,
} from "./style";
import { Box, styled } from "@material-ui/core";
import $ from "@pivottable/common/gl";
import { tableNames } from "../definitions";

const PageWrapper = styled(Box)({
  height: "100%",
});

function getRowsWithValueTitles(data, groupingKeys, key, value) {
  const rowsWithValueTitles = new Set();
  data.forEach((row) => {
    if (row[key] === value) {
      const rowTitles = arr2str(groupingKeys.map((groupKey) => row[groupKey]));
      rowsWithValueTitles.add(rowTitles);
    }
  });
  return rowsWithValueTitles;
}

export default class Table extends React.Component {
  state = {
    userHide: _.getOr(
      {
        hide: [new Set(), new Set()],
        show: [new Set(), new Set()],
      },
      ["state", "userHide"],
      window.history
    ),
  };
  pivot = memoize(_pivot);

  _tableData = memoize(
    (
      data,
      filterState,
      groupBy,
      valueKey,
      fn,
      pivotTransform,
      dropSubTotals,
      hiddenCategories,
      userHide,
      sort,
      rowTitlesOrder,
      colTitlesOrder,
      groupingKeys,
      tableName
    ) => {
      const tree = this.pivot(data, groupBy, valueKey, fn);
      const hide = userHide.hide;
      if (hiddenCategories) {
        [_.keys(tree), _.keys(tree[""])].forEach((r, d) =>
          r.forEach((t) => {
            if (
              !userHide.show[d].has(t) &&
              hiddenCategories[d].includes(
                [...t].filter((c) => c === SEP).length
              )
            ) {
              hide[d].add(t);
            }
          })
        );
      }
      let pTable = pivotTable(tree);
      if (pivotTransform) pTable = pivotTransform(pTable, data, filterState);

      const highlightedTitles =
        tableName === tableNames.budgeting
          ? getRowsWithValueTitles(data, groupingKeys[0], $.from, "off")
          : null;

      return {
        ...table(
          pTable,
          dropSubTotals,
          hide,
          sort,
          rowTitlesOrder,
          colTitlesOrder
        ),
        pivot: tree,
        hide,
        highlightedTitles,
      };
    }
  );

  tableData() {
    const {
      data,
      groupBy,
      valueKey,
      fn,
      pivotTransform,
      dropSubTotals,
      sortBy,
      hiddenCategories,
      rowTitlesOrder,
      colTitlesOrder,
      filterState,
      groupingKeys,
      tableName,
    } = this.props;
    const { userHide } = this.state;
    return this._tableData(
      data,
      filterState,
      groupBy,
      valueKey,
      fn,
      pivotTransform,
      dropSubTotals,
      hiddenCategories,
      userHide,
      sortBy,
      rowTitlesOrder,
      colTitlesOrder,
      groupingKeys,
      tableName
    );
  }

  _statusData = memoize((statusData, filterState, pivot) => {
    return statusData ? statusData(pivot, filterState) : null;
  });

  statusData = () => {
    const { pivot } = this.tableData();
    const { statusData, filterState } = this.props;
    return this._statusData(statusData, filterState, pivot);
  };

  toggleHide = (titles, [i, j], hide, show) => {
    const record = titles[i].slice(0, j + 1);
    const recordStr = arr2str(record);
    if (hide.has(recordStr)) {
      hide.delete(recordStr);
      show.add(recordStr);
      return true;
    }
    if (_.get([i - 1, j], titles) === record[j]) return false;
    if (j > titles[i].length - 1) return false;
    hide.add(recordStr);
    show.delete(recordStr);
    return true;
  };

  onClick = (e) => {
    if (!this.mousePos) return;
    const [c, r] = this.mousePos;
    const { userHide } = this.state;
    const { titles } = this.tableData();
    const len = this.props.groupBy.map((g) => g.length);
    let changed = false;
    if (c + 1 < len[0] && r >= len[1] && this.cellArea === "left") {
      changed = this.toggleHide(
        titles[0],
        [r - len[1], c],
        userHide.hide[0],
        userHide.show[0]
      );
    } else if (r + 1 < len[1] && c >= len[0] && this.cellArea === "left") {
      changed = this.toggleHide(
        titles[1],
        [c - len[0], r],
        userHide.hide[1],
        userHide.show[1]
      );
    } else if (r + 1 === len[1] && c >= len[0]) {
      // sorting
      if (this.cellArea !== "right") return;
      const newCol = arr2str(titles[1][c - len[0]]);
      const { col, order } = this.props.sortBy || { col: null, order: null };
      if (col === newCol && order === -1) {
        this.props.sortChange(null);
        return;
      }
      this.props.sortChange({
        col: newCol,
        order: col !== newCol ? 1 : order === 1 ? -1 : 1,
      });
    }
    if (changed) {
      window.history.replaceState(
        {
          ...window.history.state,
          userHide,
        },
        "Pivot Table"
      );
      this.setState({ userHide: { ...userHide } });
    }
  };

  // 2nd parameter [d, j, i] indexes to titles
  // d - dimension, 0 - left titles, 1 - top titles
  // j - index of title record, record is array of titles for given row, column e.g. ['Labs', 'IT']
  // i - points to specific string for given cell
  getTitleCell = (
    records,
    [d, i, j],
    maxLen,
    hidden,
    subTotalFirst,
    dropCollapsing,
    format
  ) => {
    const record = records[i];

    // Show the item only for the first row it appears in
    if (
      _.isEqual(record.slice(0, j + 1), (records[i - 1] || []).slice(0, j + 1))
    )
      return ["", {}];
    if (j > record.length) return ["", {}];

    if (j === 0 && record.length === 0) return ["Grand Total", {}];

    if (j === record.length) return [subTotalFirst ? "" : "Total", {}];

    const title = format(record[j]);
    if (j < maxLen - 1) {
      const isTotal = j === record.length - 1;
      const isCollapsed = isTotal && hidden.has(arr2str(record));
      const icon = isCollapsed ? "\uf067" : "\uf068";
      return [
        subTotalFirst && isTotal && !isCollapsed
          ? `${title} Total`
          : `${title}`,
        dropCollapsing.includes(j)
          ? {}
          : {
              left: {
                icon,
                backgroundColor: color.headerIconBg,
                font: iconFonts.small,
              },
            },
      ];
    }

    return [title, {}];
  };

  getCell = (column, row) => {
    let result = ["", {}]; // string value and style for GridCanvas
    let value = ""; // unformatted value
    const {
      groupingKeys,
      sourceFormat,
      valueKey,
      pivotFormat,
      headerFormat,
      filterState,
    } = this.props;
    const len = groupingKeys.map((g) => g.length);
    const dc = this.props.dropCollapsing || [[], []];
    const { titles, values, hide, highlightedTitles } = this.tableData();

    const getFormattedTitleCell = (
      records,
      [d, i, j],
      maxLen,
      hidden,
      subTotalFirst,
      dropCollapsing
    ) => {
      // formatting based on 'sourceFormat' e.g. 01 => Jan, 02 => Feb,...
      const formatTitles = (s) =>
        (sourceFormat[groupingKeys[d][j]] || ((x) => [x]))(s)[0];
      // formatting based on headerFormat e.g. renaming 'Grand Total' const for specific table
      const formatHeader =
        (headerFormat && headerFormat(titles, d, i, j)) || ((x) => x);

      const result = this.getTitleCell(
        records,
        [d, i, j],
        maxLen,
        hidden,
        subTotalFirst,
        dropCollapsing,
        formatTitles
      );
      return [formatHeader(result[0]), result[1]];
    };

    if (column < len[0] && row >= len[1]) {
      // left cols with calculated row attrs
      result = getFormattedTitleCell(
        titles[0],
        [0, row - len[1], column],
        len[0],
        hide[0],
        true,
        dc[0]
      );
      value = result[0];

      const isHighlighted = highlightedTitles
        ? Array.from(highlightedTitles).some((title) =>
            title.startsWith(arr2str(titles[0][row - len[1]]))
          )
        : false;

      result[1] = _.merge(
        _.omit(
          "backgroundColor",
          isHighlighted ? highlightedHeaderStyle : headerStyle
        ),
        result[1]
      );

      const rowTitleLength = titles[0][row - len[1]].length;
      // cell will be colored only if belongs to category (is located to the right of the +/- icon)
      if (rowTitleLength - 1 <= column) {
        const depth = len[0] - rowTitleLength;
        if (depth > 0) {
          const styles =
            totalStyles[Math.min(depth - 1, totalStyles.length - 1)];
          result[1] = _.merge(result[1], styles);
        }
      }

      // add hover styles for icon
      if (
        _.isEqual([column, row], this.mousePos) &&
        "left" in result[1] &&
        this.cellArea === "left"
      ) {
        result[1].left = _.merge(result[1].left, {
          backgroundColor: color.headerIconHoverBg,
        });
      }
    } else if (row < len[1] && column >= len[0]) {
      // top rows with calculated col attrs
      result = getFormattedTitleCell(
        titles[1],
        [1, column - len[0], row],
        len[1],
        hide[1],
        false,
        dc[1]
      );
      value = result[0];
      // mark sorting
      const { col, order } = this.props.sortBy || { col: null, order: null };
      result = [
        `${value}`,
        row + 1 !== len[1]
          ? _.merge(headerStyle, result[1])
          : _.merge(
              {
                right: {
                  icon:
                    arr2str(titles[1][column - len[0]]) === col
                      ? order === -1
                        ? "\uf0dd"
                        : "\uf0de"
                      : "\uf0dc",
                  backgroundColor: color.headerIconBg,
                  font: iconFonts.regular,
                },
              },
              headerStyle
            ),
      ];
      // add hover styles for icon
      _.isEqual([column, row], this.mousePos) &&
        ["left", "right"].forEach((val) => {
          if (val in result[1] && val === this.cellArea) {
            result[1][val] = _.merge(result[1][val], {
              backgroundColor: color.headerIconHoverBg,
            });
          }
        });
    } else if (column >= len[0] && row >= len[1]) {
      // aggregated values, if they exist
      value = _.get([row - len[1], column - len[0]], values);
      /* eslint-disable indent */
      result = pivotFormat
        ? pivotFormat(value, titles[1][column - len[0]], filterState)
        : sourceFormat[valueKey]
        ? sourceFormat[valueKey](value)
        : [value, {}];
      /* eslint-enable indent */
      // check if this is total if so assign background color to cell - some shade of grey
      const depth = Math.max(
        len[0] - titles[0][row - len[1]].length,
        len[1] - titles[1][column - len[0]].length
      );
      if (depth > 0) {
        const styles = totalStyles[Math.min(depth - 1, totalStyles.length - 1)];
        result[1] = _.merge(result[1], styles);
      }
      // if cell is in Grand Total column, font will be bold
      if (titles[1][column - len[0]].length === 0) {
        result[1] = _.merge(result[1], { bold: grandTotalBold });
      }
    }
    return [...result, [], value];
  };

  onDoubleClick = (e) => {
    const { mousePos } = this;
    this.openCellDetail(mousePos);
  };

  openCellDetail = (cellPos) => {
    const { groupBy } = this.props;
    if (!cellPos || [0, 1].some((d) => cellPos[d] < groupBy[d].length)) return;
    // Data stored in mousePos uses different order for stored coordinates
    // it stores them in [col, row] order in our data structures (groupBy, titles)
    // we use [row, col] order.
    // We use this difference in first map() to find out position clicked,
    // without considering rows and cols contaning titles
    const result = cellPos
      .map((cell, d) => cell - groupBy[d].length)
      .reverse()
      .map((cell, d) => this.tableData().titles[d][cell])
      .map((record, d) => record.map((title, index) => [d, index, title]));
    this.props.openCellDetail(_.flatten(result));
  };

  render() {
    const { values } = this.tableData();
    const statusData = this.statusData();
    const frozen = this.props.groupBy.map((t) => t.length);
    const valuesDim = [values.length, (values[0] || []).length]; // case where values is empty
    return (
      <PageWrapper>
        <GridCanvasWrapper
          getCell={this.getCell}
          onClick={this.onClick}
          onDoubleClick={this.onDoubleClick}
          openCellDetail={this.openCellDetail}
          colWidths={[
            ...Array(frozen[0])
              .fill(0)
              .map((v, i) => _.getOr(100, ["colWidths", 0, i], this.props)),
            ...Array(valuesDim[1]).fill(
              _.getOr(100, ["colWidths", 1], this.props)
            ),
          ]}
          rows={frozen[1] + valuesDim[0]}
          cols={frozen[0] + valuesDim[1]}
          frozen={frozen}
          onPaint={({ mousePos, cellArea }) => {
            this.mousePos = mousePos;
            this.cellArea = cellArea;
          }}
          statusData={statusData}
        />
      </PageWrapper>
    );
  }
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array),
  sortBy: PropTypes.shape({
    order: PropTypes.number,
    col: PropTypes.string,
  }),
  sortChange: PropTypes.func,
  groupBy: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.func)),
  groupingKeys: PropTypes.arrayOf(PropTypes.array),
  rates: PropTypes.object,
  openCellDetail: PropTypes.func,
  filterState: PropTypes.any,
  valueKey: PropTypes.number,
  pivotFormat: PropTypes.func,
  fn: PropTypes.func,
  pivotTransform: PropTypes.func,
  hiddenCategories: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  colWidths: PropTypes.array,
  statusData: PropTypes.func,
  dropSubTotals: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  sourceFormat: PropTypes.object,
  headerFormat: PropTypes.func,
  rowTitlesOrder: PropTypes.object,
};
