var isRecordAllowed = function isRecordAllowed(record, hierarchyIndexToRecordIndex, allowedHierarchy) {
  var hierarchyPointer = allowedHierarchy;
  for (var i = 0; hierarchyPointer; i++) {
    if (hierarchyPointer === true) return true;
    hierarchyPointer = hierarchyPointer[record[hierarchyIndexToRecordIndex[i]]];
  }
  return false;
};
module.exports = {
  isRecordAllowed: isRecordAllowed,
  rootTeam: "finance",
  infoName: "info"
};