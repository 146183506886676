import React from "react";
import ValidationAccordion from "./ValidationAccordion";
import { makeStyles, TableCell, TableRow, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(0.5, 2),
    textAlign: "right",
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));

const RuleViolationsTable = ({ lines, rule }) => {
  const styles = useStyles();

  const tableRows = lines
    .map((line, i) => {
      const [source, identifier, link] = line;
      return (
        <TableRow key={i}>
          <TableCell className={styles.cell}>{source}</TableCell>
          <TableCell className={styles.cell}>{identifier}</TableCell>
          <TableCell className={styles.cell}>
            <Link
              href={link}
              className={styles.link}
              target="_blank"
              rel="noopener, noreferrer"
            >
              link
            </Link>
          </TableCell>
        </TableRow>
      );
    })
    // without this getting react recursion error - it still works, but lags for a little bit
    // TODO: try pagination
    .slice(0, 200);

  return (
    <ValidationAccordion
      title={rule}
      headCols={["Source", "Line number", "Line link"]}
      colWidths={[20, 60, 20]}
      tableRows={tableRows}
      errorsCount={lines.length}
      visibleCount={tableRows.length}
      isTruncated={lines.length > tableRows.length}
    />
  );
};

export default RuleViolationsTable;
