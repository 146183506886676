import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import Selection from "./Selection";
import Search from "./Search";
import Scroll from "./Scroll";
import { GridCanvas } from "./GridCanvas";
import { AutoSizer } from "./AutoSizer";
import { Box, styled } from "@material-ui/core";
import { PivotMenuContext } from "./context/PivotMenuContext";
import { RefContext } from "./context/RefContext";
import { FilterDrawerContext } from "./context/FilterDrawerContext";

const Wrapper = styled(Box)({
  height: "100%",
});

const GridCanvasWrapper = (props) => {
  const {
    frozen,
    cols,
    rows,
    getCell,
    onClick,
    onDoubleClick,
    onPaint,
    openCellDetail,
    statusData,
  } = props;
  const { isOpen, isSearchOpen, setIsSearchOpen } =
    useContext(PivotMenuContext);
  const { setGridCanvasWrapperRef, gridCanvasWrapperRef } =
    useContext(RefContext);
  const { isFilterOpen } = useContext(FilterDrawerContext);
  const setRef = useCallback(
    (el) => setGridCanvasWrapperRef(el),
    [setGridCanvasWrapperRef]
  );

  return (
    <Scroll>
      {(scrollProps) => (
        <Selection
          {...{ frozen, cols, rows, getCell, openCellDetail }}
          onScrollToChange={scrollProps.onScrollToChange}
          disableKeys={isOpen}
          gridCanvasWrapperRef={gridCanvasWrapperRef}
        >
          {(selectionProps) => (
            <Search
              {...{ cols, rows, getCell }}
              selection={selectionProps.selection}
              onScrollToChange={scrollProps.onScrollToChange}
              statusData={statusData}
              disableKeys={isOpen}
              isSearchOpen={isSearchOpen}
              setIsSearchOpen={setIsSearchOpen}
            >
              {(searchProps) => (
                <AutoSizer isFilterOpen={isFilterOpen}>
                  {(size) => (
                    <Wrapper
                      {...{ onClick, onDoubleClick }}
                      tabIndex="0"
                      onCopy={selectionProps.onCopy}
                      onMouseDown={selectionProps.onMouseDown}
                      ref={setRef}
                    >
                      <GridCanvas
                        {...props}
                        size={size}
                        activeCell={selectionProps.activeCell}
                        selections={selectionProps.selection}
                        onPaint={(v) => {
                          selectionProps.onPaint(v);
                          onPaint({
                            ...v,
                            activeCell: selectionProps.activeCell,
                            selection: selectionProps.selection,
                          });
                        }}
                        getCell={searchProps.getCell}
                        scrollTo={scrollProps.scrollTo}
                        onScroll={scrollProps.onScroll}
                        offset={
                          selectionProps.scrollTo ? null : scrollProps.offset
                        }
                      />
                    </Wrapper>
                  )}
                </AutoSizer>
              )}
            </Search>
          )}
        </Selection>
      )}
    </Scroll>
  );
};
export default GridCanvasWrapper;

GridCanvasWrapper.propTypes = {
  getCell: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  colWidths: PropTypes.arrayOf(PropTypes.number).isRequired,
  rows: PropTypes.number.isRequired,
  cols: PropTypes.number.isRequired,
  frozen: PropTypes.arrayOf(PropTypes.number).isRequired,
  onPaint: PropTypes.func,
  openCellDetail: PropTypes.func,
};
