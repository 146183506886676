import { createTheme } from "@material-ui/core/styles";

export const colors = {
  primary: "#E72076",
  secondary: "#16A2DE",
  grey: "#DDDFE5",
  greyLight: "#F9F8F6",
  greyDark: "#63687D",
  greyButton: "#F0F1F5",
  greyBorder: "#DAD7D1",
  text: "#000000",
  white: "#FFFFFF",
  error: "#F13535",
};

export const topBarHeight = 40;
export const bottomBarHeight = 48;
export const filterWidth = 224;

export const zIndexes = {
  modal: 20,
  errorOverlay: 40,
  loaderOverlay: 35,
  commentTransactionOverlay: 32,
  shortcutsOverlay: 30,
  validationOverlay: 10,
  pivotMenu: 20,
  pivotMenuControl: 25,
};

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
    grey: {
      light: colors.greyLight,
      main: colors.grey,
      dark: colors.greyDark,
      button: colors.greyButton,
      border: colors.greyBorder,
    },
    text: {
      primary: colors.text,
      disabled: colors.grey,
    },
    background: {
      default: colors.white,
    },
    error: {
      main: colors.error,
      dark: colors.error,
    },
  },
  typography: {
    fontFamily: [
      '"IBM Plex Sans"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Roboto"',
      '"Ubuntu"',
      '"Helvetica Neue"',
      "sans-serif",
    ].join(","),
    body1: {
      lineHeight: 1.2,
    },
  },
  zIndex: {
    modal: zIndexes.modal,
  },
  spacing: 8,
});
