import _ from "lodash";

export const filter = _.curry((fn, [titles, ...rows]) => [
  titles,
  ...rows.filter(fn),
]);

export const filterByClusters = _.curry((cluster, fn, [titles, ...rows]) => {
  const clusters = new Set(rows.filter(fn).map(cluster));
  return [titles, ...rows.filter((r) => clusters.has(cluster(r)))];
});

export const flatMap = _.curry((fn, [titles, ...rows]) => [
  titles,
  ...rows.flatMap(fn),
]);

export const columns = _.curry((fn, [titles, ...rows]) => [
  fn(titles),
  ...rows,
]);

export const map = _.curry((fn, [titles, ...rows]) => [
  titles,
  ...rows.map(fn),
]);
