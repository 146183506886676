import { normalise } from "@pivottable/common/latinMap";
import { v4 as uuidv4 } from "uuid";

const dayMs = 24 * 60 * 60 * 1000;

function unemptyArray(possibleArray) {
  return Array.isArray(possibleArray) && possibleArray.length > 0;
}

function creatPairingForTransactionAndInvoice(
  transaction,
  invoice,
  matchingMethod
) {
  return {
    uuid: uuidv4(),
    transaction_uuid: transaction.uuid,
    invoice_id: invoice.id,
    invoice_entity: invoice.entity,
    currency: invoice.currency ?? "",
    amount: invoice.amount ?? "",
    account_from: `cash/${transaction.bank_account}`,
    account_to: invoice.incoming ? "payables" : "receivables",
    center: "",
    po: "",
    pid: "",
    non_tax_expense: null,
    matching_method: `INVOICE_MATCH_BY_${matchingMethod}`,
    matching_method_short: `${matchingMethod}`,
    invoice,
  };
}

function convertToDate(value) {
  if (typeof value === "string") return new Date(value);
  return value;
}

/*
function Heuristic(tag, matchCondition, candidateComparison) {
  this.tag = tag;
  this.matchCondition = matchCondition;
  this.candidateComparison = candidateComparison;
}

function dateMatchCondition(transaction, invoice) {
  if (!transaction.date || !invoice.issue_date || !invoice.due_date)
    return false;
  const ts = convertToDate(transaction.date).getTime();
  return (
    ts > convertToDate(invoice.issue_date).getTime() - 7 * dayMs &&
    ts < convertToDate(invoice.due_date).getTime() + 14 * dayMs
  );
}

function amountMatchCondition(transaction, invoice) {
  //    i.pay.toFixed(2) === transaction.amount.toFixed(2) && // IMHO i.pay is the incorrect value here. We need i.amount
  return (
    invoice.amount.toFixed(2) === transaction.amount.toFixed(2) && // IMHO i.pay is the incorrect value here. We need i.amount, it has the correct sign
    invoice.currency === transaction.currency
  );
}

function VSMatchCondition(transaction, invoice) {
  if (!transaction.refProcessed) return false;
  return invoice.variable_symbol === transaction.refProcessed;
}

function CPMatchCondition(transaction, invoice) {
  //Note equality of t.counterpart_name and i.counterparty is
  //TODO potentially extend matching to more than first parameter
  if (!invoice.counterparty || !transaction.normalizedCounterpartyName)
    return false;
  return (
    normalise(invoice.counterparty) === transaction.normalizedCounterpartyName
  );
}

// function CPWeakerMatchCondition(transaction, invoice) {
//   //Note equality of t.counterpart_name and i.counterparty is
//   //TODO potentially extend matching to more than first parameter
//   if (!invoice.counterpartyExtracted || !transaction.counterparty_name)
//     return false;
//   return normalise(transaction.counterparty_name).includes(
//     invoice.counterpartyExtracted[0]
//   );
// }

// function EMatchCondition(transaction, invoice) {
//   if (!invoice.entity || !transaction.entity) return false;
//   return invoice.entity === transaction.entity;
// }

// function AIDMatchCondition(transaction, invoice, aid2inv) {
//   //I struggle here with implementation here of passing aid2inv...
//   const aid = `${transaction.entity}#${transaction.message}`;
//   return transaction.type === "out" && aid2inv[aid];
// }

function REFMatchCondition(transaction, invoice) {
  if (invoice.incoming) return false; //invoice.idNumber is our internal identificator
  const haystack = `${transaction.reference}#${transaction.message}`;
  if (transaction.reference === null && transaction.message === null)
    return false;

  if (!invoice.idNumber) return false; //this really should be checked also elsewhere and throw a major error to validation...
  if (invoice.idNumber.length < 4) return false;
  return haystack.search(invoice.idNumber) !== -1;
}

function ROLLMatchCondition(transaction, invoice) {
  return (
    invoice.payroll &&
    normalise(invoice.counterparty) === transaction.normalizedCounterpartyName
  );
}

function REFcomparison(tr, a, b) {
  return b.idNumber.length - a.idNumber.length;
}

function onlyFirstComparison(tr, a, b) {
  return b.entity_id !== a.entity_id; //TODO make nicer; I want to get only the first element in the array...
}

function all(tr, a, b) {
  return 0;
}

const Theur = new Heuristic("T", dateMatchCondition, all);
const Aheur = new Heuristic("A", amountMatchCondition, all);
const VSheur = new Heuristic("VS", VSMatchCondition, all);
const CPheur = new Heuristic("CP", CPMatchCondition, all);
// const CPWeakerheur = new Heuristic("CP", CPWeakerMatchCondition, all);
// const Eheur = new Heuristic("E", EMatchCondition, all);
// const AIDheur = new Heuristic("AID", AIDMatchCondition, all);
const REFheur = new Heuristic("REF", REFMatchCondition, REFcomparison);
const ROLLheur = new Heuristic("ROLL", ROLLMatchCondition, onlyFirstComparison); //TODO after testing make the comparison to be all

function heuristics2matching(...heur) {
  const matchingName = heur.map((h) => h.tag).join("");
  const conditions = heur.map((h) => h.matchCondition);
  const comparisons = heur.map((h) => h.candidateComparison);

  const matchingTest = function (tran, invo) {
    const potentialCandidates = invo.filter(
      conditions.map((c) => c(tran, invo)).reduce((x, y) => x && y)
    );

    function comparisonsMerged(a, b) {
      return comparisons
        .map((c) => c(tran, a, b))
        .reduce((x, y) => (x !== 0 ? (x += y) : x)); //the order matters, first test takes precedence
    }

    let finalCandidates = potentialCandidates.sort(comparisonsMerged);

    finalCandidates = finalCandidates.filter(
      (a) => comparisonsMerged(a, finalCandidates[0]) === 0
    );
    return finalCandidates;
  };

  return [matchingTest, matchingName];
}
*/

export function createPairingsForTransactionWithInvoices(
  transaction,
  invoices,
  aid2inv
) {
  function createPairingsFromListOfInvoices(invoices, rule) {
    if (invoices.length === 0) return null;
    return invoices.map((i) =>
      creatPairingForTransactionAndInvoice(transaction, i, rule)
    );
  }

  // 1. Will not match any transaction that is fee
  if (transaction.type === "fee") return null;

  // 2. Match by aid AID // TODO I do not know properly how to pass aid2inv :-/
  const aid = `${transaction.entity}#${transaction.message}`;
  if (transaction.type === "out" && aid2inv[aid]) {
    return createPairingsFromListOfInvoices([aid2inv[aid]], "AID");
  }

  function dateMatch(invoice) {
    if (!transaction.date || !invoice.issue_date || !invoice.due_date)
      return false;
    const ts = convertToDate(transaction.date).getTime();
    return (
      ts > convertToDate(invoice.issue_date).getTime() - 7 * dayMs &&
      ts < convertToDate(invoice.due_date).getTime() + 14 * dayMs
    );
  }

  //these are already fixed so that they have a reasonable performance.
  // 3. Match by reference REF - numerical part of invoice uuid called idNumber against transaction message and reference
  //According to OG heuristic we should not trust reference for incoming invoices, I do not see why not, if we demote this from such highly placed test
  const haystack = `${transaction.reference}#${transaction.message}`;
  let refInvoices = invoices
    .filter(function (i) {
      if (transaction.reference === null && transaction.message === null)
        return false; //nothing to check against; TODO stop immediately, if this is detected
      if (!i.idNumber) return false; //this really should be checked also elsewhere and throw a major error to validation...
      // We can truly trust match by reference only for outgoing invoices.
      if (i.incoming) {
        //if (normalise(i.counterparty) !== normalizedTransactionCounterpartyName) // TODO unnecessarilly too strong! partial matches of strings of significant length would suffice; also, wtf is this condition here?!
        return false;
      }
      //  if (i.reference.length < 4) return false; //this is nonsensical, invoices do not have key reference
      if (i.idNumber.length < 4) return false;
      //return haystack.search(i.reference) !== -1; //this is nonsensical, invoices do not have key reference
      return haystack.search(i.idNumber) !== -1;
    })
    .sort(function (a, b) {
      //return b.reference.length - a.reference.length; //this is nonsensical, invoices do not have key reference
      return b.idNumber.length - a.idNumber.length;
    });

  if (refInvoices.length > 1) {
    // Keep only longest matches by reference.
    refInvoices = refInvoices.filter(function (i) {
      //return i.reference.length === refInvoices[0].length; //this is nonsensical, invoices do not have key reference
      return i.idNumber.length === refInvoices[0].idNumber.length;
    });
    return createPairingsFromListOfInvoices(refInvoices, "REF");
  }

  // 4. Match by payroll and counterparty ROLL
  const counterpartyMatch = invoices.filter(function (i) {
    // We don't really need to match transaction to specific receipt if we can
    // fill the counterparty and account anyway. That's something we are
    // capable of doing for payroll invoices.
    return (
      i.payroll &&
      normalise(i.counterparty) === transaction.normalizedCounterpartyName &&
      dateMatch(i) //ADDED to check the time, otherwise it currently makes a huge mess
    );
  });

  if (counterpartyMatch.length > 0) {
    // TODO why whe use only first counterparty match thing?
    return createPairingsFromListOfInvoices([counterpartyMatch[0]], "ROLL");
  }

  const timeAndAmountMatch = invoices.filter(function (i) {
    return (
      //i.pay.toFixed(2) === transaction.amount.toFixed(2) && // IMHO i.pay is the incorrect value here. We need i.amount
      i.amount.toFixed(2) === transaction.amount.toFixed(2) && // IMHO i.pay is the incorrect value here. We need i.amount, it has the correct sign
      i.currency === transaction.currency &&
      dateMatch(i)
    );
  });

  // 5. Match by time amount and counterparty TACP
  const timeAndAmountAndCounterPartyMatch = timeAndAmountMatch.filter(function (
    i
  ) {
    return normalise(i.counterparty) === transaction.normalizedCounterpartyName; // TODO unnecessarilly too strong! partial matches of strings of significant length would suffice
  });

  if (unemptyArray(timeAndAmountAndCounterPartyMatch))
    return createPairingsFromListOfInvoices(
      timeAndAmountAndCounterPartyMatch,
      "TACP"
    );

  // 6. Match by time amount and vs TAVS
  if (transaction.refProcessed) {
    const timeAndAmountAndVariableSymbolMatch = timeAndAmountMatch.filter(
      function (i) {
        //return i.vs === transaction.vs; // i.vs does not exist, we want to use processed reference
        return i.variable_symbol === transaction.refProcessed;
      }
    );
    if (unemptyArray(timeAndAmountAndVariableSymbolMatch))
      return createPairingsFromListOfInvoices(
        timeAndAmountAndVariableSymbolMatch,
        "TAVS"
      );
  }

  // 7. Match by time amount TA
  if (unemptyArray(timeAndAmountMatch))
    return createPairingsFromListOfInvoices(timeAndAmountMatch, "TA");
  return null;

  // TODO I can't make this function work
  // function matching2createPairingsFromListOfInvoices(heur2match, invoices) {
  //   if (unemptyArray(heur2match[0](transaction, invoices)))
  //   return createPairingsFromListOfInvoices(
  //     heur2match[0](transaction, invoices), heur2match[1]
  //   );
  // }

  // matching2createPairingsFromListOfInvoices(heuristics2matching(REFheur), invoices);
  // matching2createPairingsFromListOfInvoices(heuristics2matching(Theur, ROLLheur), invoices);
  // matching2createPairingsFromListOfInvoices(heuristics2matching(Theur, Aheur, CPheur), invoices);
  // matching2createPairingsFromListOfInvoices(heuristics2matching(Theur, Aheur, VSheur), invoices);
  // matching2createPairingsFromListOfInvoices(heuristics2matching(Theur, Aheur), invoices);
  /*
  //testing alternative REF matching
  if (unemptyArray(heuristics2matching(REFheur)[0](transaction, invoices)))
    return createPairingsFromListOfInvoices(
      heuristics2matching(REFheur)[0](transaction, invoices),
      "REF2"
    );

  //testing alternative ROLL matching
  if (
    unemptyArray(heuristics2matching(ROLLheur, Theur)[0](transaction, invoices))
  )
    return createPairingsFromListOfInvoices(
      heuristics2matching(ROLLheur, Theur)[0](transaction, invoices),
      "ROLL2"
    );

  //testing alternative TACPWeaker matching, not used before
  //if (unemptyArray(heuristics2matching(Theur, Aheur, CPWeakerheur)[0](transaction, invoices)))
  //return createPairingsFromListOfInvoices(
  //  heuristics2matching(Theur, Aheur, CPWeakerheur)[0](transaction, invoices), "WTACP2"
  //);

  //testing alternative TACP matching
  if (
    unemptyArray(
      heuristics2matching(Theur, Aheur, CPheur)[0](transaction, invoices)
    )
  )
    return createPairingsFromListOfInvoices(
      heuristics2matching(Theur, Aheur, CPheur)[0](transaction, invoices),
      "TACP2"
    );

  //testing alternative TAvs matching
  if (
    unemptyArray(
      heuristics2matching(Theur, Aheur, VSheur)[0](transaction, invoices)
    )
  )
    return createPairingsFromListOfInvoices(
      heuristics2matching(Theur, Aheur, VSheur)[0](transaction, invoices),
      "TAVS2"
    );

  //testing alternative TA matching
  if (unemptyArray(heuristics2matching(Theur, Aheur)[0](transaction, invoices)))
    return createPairingsFromListOfInvoices(
      heuristics2matching(Theur, Aheur)[0](transaction, invoices),
      "TA2"
    );

  return null;
  */
}
