import React from "react";
import _ from "lodash/fp";
import PropTypes from "prop-types";
import { arr2str, str2arr } from "../pivot.js";
import { stringFormat } from "../stringFormat.js";
import { color } from "./style";
import { bottomBarHeight, topBarHeight } from "../styles/defaultTheme";
import TableSearch from "./TableSearch.js";
import TopBar from "./TopBar.js";
import { Box, styled } from "@material-ui/core";
import { keyboardKey } from "../utils.js";
import ReactDOM from "react-dom";

const moves = {
  ArrowLeft: [-1, 0],
  ArrowRight: [1, 0],
  ArrowUp: [0, -1],
  ArrowDown: [0, 1],
};

const PageWrapper = styled(Box)({
  height: "100%",
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: `${topBarHeight}px 1fr ${bottomBarHeight}px`,
});

const numberFormat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
async function _search(getCell, search, rows, cols, shouldStop) {
  const results = [];
  if (!search) return results;
  const sq = stringFormat(search);
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      const record = getCell(j, i);
      if (stringFormat(record[0]).indexOf(sq) > -1) {
        const value = arr2str([j, i]);
        results.push(value);
      }
    }
    if (i % 1000 === 0 && (await shouldStop())) return null;
  }
  return results;
}

function searching(getCell, search, rows, cols) {
  let canceled = false;
  const shouldStop = async () => {
    await delay(0);
    return canceled;
  };

  return [
    _search(getCell, search, rows, cols, shouldStop),
    () => {
      canceled = true;
    },
  ];
}

export default class Search extends React.Component {
  state = {
    search: {
      query: null,
      index: null,
      result: null,
    },
  };
  inputRef = React.createRef();

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    // disable input interactions when PivotMenu is opened
    if (this.props.disableKeys) return;

    const { search } = this.state;
    const { isSearchOpen, setIsSearchOpen } = this.props;

    const key = keyboardKey(e);

    if (key.isF3 || (key.isCtrlOrMeta && key.isF)) {
      e.preventDefault();
      this.setState({ search: { ...search, query: "" } }, () =>
        this.inputRef.current.focus()
      );
      if (this.inputRef.current) this.inputRef.current.select();
      setIsSearchOpen(true);
    } else if (key.isEscape) {
      isSearchOpen && e.stopImmediatePropagation();
      this.setState({ search: { ...search, query: null } });
      setIsSearchOpen(false);
    } else if (key.isEnter && !key.isCtrlOrMeta) {
      if (isSearchOpen) e.stopImmediatePropagation();
      this.showNextResult(key.isShift ? -1 : 1);
    } else if ((key.isCtrlOrMeta && key.isA) || e.key in moves) {
      const isSearchOpenAndFocused =
        isSearchOpen &&
        document.activeElement === ReactDOM.findDOMNode(this.inputRef.current);
      isSearchOpenAndFocused && e.stopImmediatePropagation();
    }
  };

  searchRecords = () => {
    const { getCell, rows, cols } = this.props;
    const {
      search,
      search: { query },
    } = this.state;
    this.setState({ search: { ...search, result: null } });
    this.search && this.search[1]();
    this.search = searching(getCell, query, rows, cols);
    return this.search[0].then((res) => {
      if (res !== null) {
        this.setState({ search: { ...search, result: [res, new Set(res)] } });
      }
      return res;
    });
  };

  getCell = (column, row) => {
    const { query, result, index } = this.state.search;
    const record = this.props.getCell(column, row);
    const str = arr2str([column, row]);
    if (query && result !== null) {
      if (result[1].has(str)) {
        record[1] = _.set(
          "backgroundColor",
          result[0][index] === str ? color.matchSpecific : color.matchGeneral,
          record[1]
        );
      }
    }
    record.pop();
    return record;
  };

  showNextResult = (step) => {
    const {
      search,
      search: { index, result },
    } = this.state;
    if (result === null) return;
    const nextIndex = (index + step + result[0].length) % result[0].length;
    this.setState({
      search: {
        ...search,
        index: nextIndex,
      },
    });
    if (result[0].length) {
      this.props.onScrollToChange(str2arr(result[0][nextIndex]).map(Number));
    }
  };

  onSearchChange = (query) => {
    this.setState({ search: { ...this.state.search, query, index: 0 } }, () =>
      this.searchRecords().then(() => this.showNextResult(0))
    );
  };

  searchCount = () => {
    const { index, result, query } = this.state.search;
    if (query && !result) return "...";
    const len = result === null ? 0 : result[0].length;
    return len === 0 ? "0/0" : `${index + 1}/${len}`;
  };

  status = () => {
    const { getCell } = this.props;
    const { selection } = this.props;
    if (selection.length === 1 && selection[0][2] * selection[0][3] === 1) {
      return [1, getCell(selection[0][0], selection[0][1])[3]];
    }
    // if multiple numbers cells are selected
    // write sum of them to statusbar
    let result = 0;
    const visited = new Set();
    for (let k = 0; k < selection.length; k++) {
      const [x, y, xl, yl] = selection[k];
      for (let i = x; i < x + xl; i++) {
        for (let j = y; j < y + yl; j++) {
          const key = [i, j].join("_");
          if (visited.has(key)) continue;
          visited.add(key);
          const value = getCell(i, j)[3];
          // empty cells are exception
          // sum continues with empty cells ignored
          if (getCell(i, j)[0] === "") continue;
          if (result === null || typeof value !== "number" || isNaN(value)) {
            result = null;
            continue;
          }
          result = value + result;
        }
      }
    }
    return [visited.size, result];
  };

  render() {
    const [count, message] = this.status();
    const specialStatus = count > 1 && message !== null;
    const format = specialStatus ? numberFormat.format : (x) => `${x}`;
    const { statusData, setIsSearchOpen } = this.props;
    return (
      <PageWrapper>
        <TopBar
          sum={message}
          sumFormatter={format}
          sumSpecialStatus={specialStatus}
          statusData={statusData}
          count={count}
          searchComponent={
            <>
              {this.state.search.query !== null && (
                <TableSearch
                  inputRef={this.inputRef}
                  onChange={(e) => this.onSearchChange(e.target.value)}
                  searchCount={this.searchCount()}
                  onPrevious={() => this.showNextResult(-1)}
                  onNext={() => this.showNextResult(1)}
                  onClose={() => {
                    this.setState({
                      search: { ...this.state.search, query: null },
                    });
                    setIsSearchOpen(false);
                  }}
                />
              )}
            </>
          }
        />
        {this.props.children({
          getCell: this.getCell,
        })}
      </PageWrapper>
    );
  }
}

Search.propTypes = {
  getCell: PropTypes.func.isRequired,
  rows: PropTypes.number.isRequired,
  cols: PropTypes.number.isRequired,
  selection: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  onScrollToChange: PropTypes.func.isRequired,
};
