import React, { useContext } from "react";
import _ from "lodash/fp";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { push } from "../components/navigation";
import memoize from "memoize-one";
import Table from "../components/Table";
import DataTable from "../components/DataTable";
import { pageTypes } from "../definitions";
import $ from "@pivottable/common/gl";
import FilterDrawer from "../components/FilterDrawer";
import RefetchDataPanel from "../components/RefetchDataPanel";
import PivotMenu from "../components/PivotMenu";
import { Box, Portal, styled } from "@material-ui/core";
import { RefContext } from "../components/context/RefContext";
import BottomBar from "../components/BottomBar";
import { PreFetchFullScreenError } from "../components/FullScreenError";
import { CellDetailContextProvider } from "../components/context/CellDetailContext";
import { FilterDrawerContextProvider } from "../components/context/FilterDrawerContext";
import { AppContext } from "../components/DataLoader";
import { getAllowedTables } from "./getAllowedTables";

const PageWrapper = styled(Box)({
  height: "100%",
});

const filterQuery = (params) =>
  `?${new URLSearchParams({ filterState: JSON.stringify(params) }).toString()}`;

const PortalledPivotMenu = ({ pivotLinks }) => {
  const { pivotRef } = useContext(RefContext);

  return (
    <Portal container={pivotRef}>
      <PivotMenu {...{ pivotLinks }} />
    </Portal>
  );
};

// input array of arrays with length 3 converted to object
// so that first 2 elements of array serve as keys to third
// Example:
// Input: [[0, 0, 'item00'], [2, 1, 'item21'], [0, 3, 'item03']]
// Output: {'0': {'0': 'item00', '3': 'item03'}, '2': {'1': 'item21'}}
const makeCellDetailMap = (arrs) => {
  const obj = _.groupBy((d) => `${d[0]}`, arrs);
  Object.keys(obj).forEach((key) => {
    obj[key] = _.fromPairs(obj[key].map((values) => values.slice(1)));
  });
  return obj;
};

class TablePageContent extends React.Component {
  getCurrentDefinition = () => {
    return this.props.definitions.find(
      (d) => d.urlName === this.props.tableUrlName
    );
  };

  tableData = memoize(
    (data, filterState, budget, departments, saData, currentDefinition) => {
      const sourceTransforms = [
        (currentDefinition.sourceTransform || (() => []))(
          filterState,
          budget,
          departments,
          saData
        ),
      ]
        .flat()
        .filter((f) => f != null);

      return _.flow(sourceTransforms)(data);
    }
  );

  detailData = memoize(
    (data, sourceOf, groupBy, customCellDetailComparisonFunctions = []) => {
      if (!sourceOf) return data;
      const customCellDetailComparisonFunctionsMap = makeCellDetailMap(
        customCellDetailComparisonFunctions
      );
      const sourceOfMap = makeCellDetailMap(sourceOf);
      const values = data.slice(1).filter((row) => {
        return groupBy.every((arr, dimension) =>
          arr.every((fn, index) => {
            const customCompare = _.get(
              [dimension, index],
              customCellDetailComparisonFunctionsMap
            );
            const item = _.get([dimension, index], sourceOfMap);
            if (item === undefined && customCompare === undefined) return true;
            return (customCompare || ((a, b) => a === b))(fn(row), item, row);
          })
        );
      });
      return [data[0], ...values];
    }
  );

  groupByFn = memoize((groupBy = []) =>
    groupBy.map(_.map((gb) => (_.isFunction(gb) ? gb : (r) => r[gb])))
  );

  filterChange = (params) => {
    push(this.props.history, { search: filterQuery(params) });
  };

  openCellDetail = (query) => {
    const { location, loadedData } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete("sortBy");
    const currentDefinition = this.getCurrentDefinition();
    if (currentDefinition.pageType === pageTypes.PIVOT_TABLE) {
      params.append("sortBy", JSON.stringify({ col: $.date, order: "asc" }));
    }
    params.append("sourceOf", JSON.stringify(query));
    const child = window.open(`?${params.toString()}`, "_blank");
    try {
      child.pivotData = loadedData;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Passing data to child window failed", e);
    }
  };

  sortChange = (value) => {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete("sortBy");
    params.append("sortBy", JSON.stringify(value));
    history.replace({ search: `?${params.toString()}` });
  };

  render() {
    const { search } = this.props.location;
    const queryParams = new URLSearchParams(search);
    const {
      gl,
      tableUrlName,
      budget,
      departments,
      definitions,
      timestamp,
      allowedHierarchy,
      saData,
      sourceLinks,
      hasCard,
      isInFinanceTeam,
    } = this.props;
    const currentDefinition = this.getCurrentDefinition();
    const pivotParams = currentDefinition;
    if (pivotParams.name) window.document.title = pivotParams.name;
    if (!gl) return <div />;
    if (!queryParams.has("filterState") && pivotParams.defaultFilter) {
      return (
        <Redirect
          to={`/pivot/${tableUrlName}${filterQuery(
            pivotParams.defaultFilter(gl)
          )}`}
        />
      );
    }
    const filterState = JSON.parse(queryParams.get("filterState")) || undefined;
    const sourceOf = JSON.parse(queryParams.get("sourceOf"));
    const sortBy = JSON.parse(queryParams.get("sortBy"));
    const groupBy = this.groupByFn(pivotParams.groupBy);
    const groupingKeys = pivotParams.groupBy;
    const tableData = this.tableData(
      gl,
      filterState,
      budget,
      departments,
      saData,
      currentDefinition
    );
    const detailData = this.detailData(
      tableData,
      sourceOf,
      groupBy,
      pivotParams.customCellDetailComparisonFunctions
    );
    let pivotLinks = definitions.map(({ name, urlName }, i) => ({
      id: i,
      label: name,
      isActive: tableUrlName === urlName,
      to: `/pivot/${urlName}?${
        tableUrlName === name && filterState
          ? new URLSearchParams({ filterState: queryParams.get("filterState") })
          : ""
      }`,
      onKeyDown: (e) => e.preventDefault(),
      urlName,
    }));
    pivotLinks = getAllowedTables(
      pivotLinks,
      allowedHierarchy,
      hasCard,
      isInFinanceTeam
    );
    if (!pivotLinks.some(({ isActive }) => isActive))
      return <PreFetchFullScreenError message="Access denied" showHomeLink />;

    const isCellDetail =
      pivotParams.pageType === pageTypes.DATA_TABLE || sourceOf;
    return (
      <PageWrapper>
        <CellDetailContextProvider isCellDetail={Boolean(isCellDetail)}>
          <FilterDrawerContextProvider defaultOpen={Boolean(!isCellDetail)}>
            {isCellDetail ? (
              <DataTable
                sourceLink={pivotParams.sourceLink}
                sourceFormat={pivotParams.sourceFormat}
                colDetailWidths={pivotParams.colDetailWidths}
                data={detailData}
                tableName={pivotParams.name}
                sortBy={sortBy}
                sortChange={this.sortChange}
                key={JSON.stringify([
                  tableUrlName,
                  queryParams.get("sourceOf"),
                ])}
                sourceLinks={sourceLinks}
              />
            ) : (
              <Table
                valueKey={pivotParams.valueKey}
                pivotFormat={pivotParams.pivotFormat}
                fn={pivotParams.fn}
                pivotTransform={pivotParams.pivotTransform}
                hiddenCategories={pivotParams.hiddenCategories}
                colWidths={pivotParams.colWidths}
                statusData={pivotParams.statusData}
                dropSubTotals={pivotParams.dropSubTotals}
                dropCollapsing={pivotParams.dropCollapsing}
                groupBy={groupBy}
                groupingKeys={groupingKeys}
                data={tableData.slice(1)}
                openCellDetail={this.openCellDetail}
                sortBy={sortBy}
                sortChange={this.sortChange}
                filterState={filterState}
                key={tableUrlName}
                tableName={pivotParams.name}
                sourceFormat={pivotParams.sourceFormat}
                headerFormat={pivotParams.headerFormat}
                rowTitlesOrder={pivotParams.rowTitlesOrder}
                colTitlesOrder={pivotParams.colTitlesOrder}
              />
            )}
            <PortalledPivotMenu {...{ pivotLinks }} />
            <FilterDrawer
              filterComponent={
                pivotParams.component && (
                  <pivotParams.component
                    onChange={this.filterChange}
                    filterState={filterState}
                    isDisabled={isCellDetail}
                  />
                )
              }
              refetchComponent={<RefetchDataPanel timestamp={timestamp} />}
            />
            {filterState && (
              <BottomBar
                tableUrlName={tableUrlName}
                filterState={filterState}
                onFilterChange={this.filterChange}
                pivotName={pivotParams.name}
              />
            )}
          </FilterDrawerContextProvider>
        </CellDetailContextProvider>
      </PageWrapper>
    );
  }
}

const TablePage = (props) => {
  // data that will be passed to cell detail if user opens any
  const loadedData = useContext(AppContext);
  const {
    gl,
    budget,
    departments,
    timestamp,
    saData,
    sourceLinks,
    hasCard,
    isInFinanceTeam,
    allowedHierarchy,
  } = loadedData;
  return (
    <TablePageContent
      {...props}
      gl={gl}
      budget={budget}
      departments={departments}
      timestamp={timestamp}
      saData={saData}
      sourceLinks={sourceLinks}
      hasCard={hasCard}
      isInFinanceTeam={isInFinanceTeam}
      allowedHierarchy={allowedHierarchy}
      loadedData={loadedData}
    />
  );
};

export default TablePage;

const props = {
  data: PropTypes.arrayOf(PropTypes.array),
  budget: PropTypes.arrayOf(PropTypes.array),
  departments: PropTypes.arrayOf(PropTypes.array),
  rates: PropTypes.object,
  tableUrlName: PropTypes.string,
  saData: PropTypes.arrayOf(PropTypes.array),
  loadDataFromParent: PropTypes.func,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  definitions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      urlName: PropTypes.string.isRequired,
      sourceTransform: PropTypes.func,
      defaultFilter: PropTypes.func,
      groupBy: PropTypes.arrayOf(PropTypes.array),
      pageType: PropTypes.string,
      colDetailWidths: PropTypes.arrayOf(PropTypes.number),
      valueKey: PropTypes.number,
      pivotFormat: PropTypes.func,
      fn: PropTypes.func,
      pivotTransform: PropTypes.func,
      hiddenCategories: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      colWidths: PropTypes.array,
      statusData: PropTypes.func,
      dropSubTotals: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      dropCollapsing: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      sourceFormat: PropTypes.object,
      headerFormat: PropTypes.func,
      rowTitlesOrder: PropTypes.object,
      colTitlesOrder: PropTypes.object,
      customCellDetailComparisonFunctions: PropTypes.array,
    })
  ).isRequired,
  timestamp: PropTypes.number,
};

TablePage.propTypes = props;
