import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { AppContext } from "./DataLoader";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.grey.button,
    padding: theme.spacing(1, 1.5),
    display: "block",
    width: "100%",
    textTransform: "none",
  },
  heading: {
    fontWeight: "bold",
  },
}));

const useTimestampStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(1),
    textAlign: "left",
  },
  heading: {
    fontWeight: "bold",
  },
}));

const FormattedTimestamp = ({ timestamp }) => {
  const date = new Date(timestamp);
  const [month, day, year] = date.toDateString().split(" ").slice(1);
  const styles = useTimestampStyles();

  return (
    <div className={styles.wrapper}>
      <Typography variant="body2" className={styles.heading}>
        Last reloaded at:
      </Typography>
      <Typography variant="body2">
        {`${day}-${month}-${year}`}, {date.toTimeString().split(" ")[0]}
      </Typography>
    </div>
  );
};

const RefetchDataPanel = ({ buttonText, timestamp }) => {
  const styles = useStyles();
  const { forceRefetch } = useContext(AppContext);

  return (
    <div>
      <Button
        onClick={() => forceRefetch(false, false)}
        className={styles.button}
      >
        <Typography variant="body2">Reload data</Typography>
      </Button>
      <Button
        onClick={() => forceRefetch(false, true)}
        className={styles.button}
      >
        <Typography variant="body2">Update Invoices + Reload data</Typography>
      </Button>
      <FormattedTimestamp timestamp={timestamp} />
    </div>
  );
};

FormattedTimestamp.propTypes = {
  timestamp: PropTypes.number,
};

RefetchDataPanel.propTypes = {
  timestamp: PropTypes.number,
};

export default RefetchDataPanel;
