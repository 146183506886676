import React from "react";
import PropTypes from "prop-types";

export function SubmitError(props) {
  return (
    <div>
      During submit there has been error
      <pre>{JSON.stringify(props.state.submitError, null, 2)}</pre>
      <button onClick={props.onGoToChangeDetails}>
        Go back to change details
      </button>
      <button onClick={props.onTryAgain}>Try sending request again</button>
      <button onClick={props.onDismisGoToBegining}>
        Discard ALL and go back to picking transaction
      </button>
    </div>
  );
}

SubmitError.propTypes = {
  state: PropTypes.shape({}),
  onGoToChangeDetails: PropTypes.func,
  onDismisGoToBegining: PropTypes.func,
  onTryAgain: PropTypes.func,
};
