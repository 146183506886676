var _toConsumableArray = require("/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");
// P&L accounts
var PL = new Set(["expenses/onetime", "expenses/regular", "expenses/payroll", "expenses/equity", "expenses/overheads", "incomes", "incomes/equity", "incomes/overheads", "incomes/internal", "incomes/packages", "expenses/packages"]);

// Extended P&L accounts
var PLX = new Set([].concat(_toConsumableArray(PL), ["forex"]));

// P&L accounts + null for non-tax transactions
var PLNonTax = new Set([].concat(_toConsumableArray(PL), ["null"]));
var BALSmall = new Set(["liabilities", "liabilities/future", "liabilities/invoiced", "liabilities/wincent", "claims", "claims/invoiced", "payables", "receivables"]);
var NonTaxExpensesAccounts = new Set(["expenses/onetime", "expenses/regular", "null"]);
var PIDAccounts = new Set(["null", "cash/transit"]);
module.exports = {
  PL: PL,
  PLX: PLX,
  PLNonTax: PLNonTax,
  BALSmall: BALSmall,
  NonTaxExpensesAccounts: NonTaxExpensesAccounts,
  PIDAccounts: PIDAccounts
};