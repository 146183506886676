import _ from "lodash";
import { arr2str } from "../pivot";

export function filter({ titles, values }, fn) {
  const dim = _.max(titles[0].map((a) => a.length));
  const present = new Set();
  [titles[0] = [], values] = _.unzip(
    _.zip(titles[0], values)
      .filter(([rt, v]) => {
        // Skip subtotal rows
        if (rt.length < dim) return true;
        const valid = fn([rt, v]);
        if (valid) {
          for (let i = 0; i < dim; i++) {
            present.add(arr2str(rt.slice(0, i)));
          }
        }
        return valid;
      })
      .filter(([rt, v]) => {
        // Skip regular rows
        if (rt.length === dim) return true;
        const key = arr2str(rt);
        // If there was at least one row of the group remaining, keep subtotal
        return present.has(key);
      })
  );
  return { titles, values };
}
