import React, { useCallback, useContext } from "react";
import { AppContext } from "../components/DataLoader";
import { CellDetailContextProvider } from "../components/context/CellDetailContext";
import { PreFetchFullScreenError } from "../components/FullScreenError";
import PivotMenu from "../components/PivotMenu";
import { tableUrlNames } from "../definitions";
import {
  PairingDataContext,
  PairngDataDataLoader,
} from "../components/context/PairingDataContext";
import { usePivotLinks } from "./usePivotLinks";
import { useMergeTransactionsInvoicesWithPairings } from "../pairingComponents/useMergeTransactionsInvoicesWithPairings";

import { MainStateComponent } from "../pairingComponents/MainStateComponent";
import { PairWithMatchingRules } from "../pairingComponents/PairWithMatchingRules";
import { PairWithMatchingInvoices } from "../pairingComponents/PairWithMatchingInvoices";

const MergePairingsIntoTransactionsAndInvoices = (props) => {
  const [transactions, invoices] = useMergeTransactionsInvoicesWithPairings(
    props.transactions,
    props.invoices,
    props.transactionInvoicePairing,
    props.convertToEur,
    props.matchingRules,
    props.dailyForex,
    props.monthlyForex
  );
  return (
    <props.component
      {...props}
      {...{
        transactions,
        invoices,
      }}
    />
  );
};

function TransactionInvoicePairingPageWithData(props) {
  const { timestamp, rates } = useContext(AppContext);
  const convertToEur = useCallback(
    (amount, currency) => {
      if (typeof amount !== "number") return undefined;
      const exchangeRate = rates[currency?.toUpperCase()];
      if (typeof exchangeRate !== "number") return undefined;
      return Math.round((amount / exchangeRate) * 100) / 100;
    },
    [rates]
  );

  const { pairingData } = useContext(PairingDataContext);

  const pivotLinks = usePivotLinks(props.definitions, props.tableUrlName);

  if (!pivotLinks.some(({ isActive }) => isActive))
    return <PreFetchFullScreenError message="Access denied" showHomeLink />;

  const component =
    props.tableUrlName === tableUrlNames.pairingTransationWithMatchingRules
      ? PairWithMatchingRules
      : props.tableUrlName ===
        tableUrlNames.pairingTransationWithMatchingInvoices
      ? PairWithMatchingInvoices
      : MainStateComponent;

  return (
    <CellDetailContextProvider isCellDetail={false}>
      <div
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <div style={{ flexGrow: 0 }}>
          <PivotMenu {...{ pivotLinks }} />
        </div>
        <div style={{ flexGrow: 1 }}>
          <MergePairingsIntoTransactionsAndInvoices
            {...pairingData}
            {...{
              component,
              timestamp,
              convertToEur,
              tableUrlName: props.tableUrlName,
              dailyForex: props.dailyForex,
              monthlyForex: props.monthlyForex,
            }}
          />
        </div>
      </div>
    </CellDetailContextProvider>
  );
}

export const TransactionInvoicePairingPage = (props) => {
  const { gl, dailyForex, monthlyForex } = useContext(AppContext);
  return (
    <PairngDataDataLoader data={gl}>
      <TransactionInvoicePairingPageWithData
        {...props}
        dailyForex={dailyForex}
        monthlyForex={monthlyForex}
      />
    </PairngDataDataLoader>
  );
};
