import _ from "lodash";
import { format as formatDate } from "date-fns";
import { color } from "../components/style";

const format = (fn) => (value) => {
  const fontColor = value < 0 ? color.negative : color.positive;
  value = value === null ? "" : fn(value);
  return [value, { color: fontColor, align: "right" }];
};

const I = (options) => new Intl.NumberFormat("en-US", options).format;

// -€1,234,573.14
export const eur = format(I({ style: "currency", currency: "EUR" }));

export const eurOptional = (x) => (x == null ? "" : eur(x));

// -€1,234,573
// have to keep minimumFractionDigits, otherwise crashes on Safari
export const eurWithoutDecimal = format(
  I({
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
);

// -1,234,573.14
export const decimal = format(
  I({ minimumFractionDigits: 2, maximumFractionDigits: 2 })
);

export const decimalOptional = (x) => (x == null ? "" : decimal(x));

// 39%
export const percent = format(I({ style: "percent" }));

// -577.5K
export const short = format(
  _.flow([
    (v) => v / 1000,
    I({ style: "decimal", minimumFractionDigits: 1, maximumFractionDigits: 1 }),
    (v) => `${v} K`,
  ])
);

// 123 hr
export const hour = format(
  _.flow([I({ maximumFractionDigits: 0 }), (v) => `${v} hr`])
);

// special month columns names which need to be formatted
export const monthToColName = {
  // column 13 is added as total column which sums values of columns 01-12
  Total: "13",
};

// Jan, Feb, ...
export const month = (m) => {
  if (_.range(1, 13).includes(+m)) {
    return [
      [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ][parseInt(m, 10) - 1] || m,
      {},
    ];
  }
  // !!! hack case (month is actually not month but can be arbitrary string, e.g. "budget")
  return [{ [monthToColName.Total]: " " }[m] || m, {}];
};

//Formatings for matching

export const paymentMethod = (p) =>
  p === "wiretransfer"
    ? "\u{1F3E6}"
    : p === "card"
    ? "\u{1F4B3}"
    : p === "cash"
    ? "\u{1FA99}"
    : p === "advance"
    ? "\u{231B}"
    : p === "" || p == null
    ? ""
    : p;

export const matchingMethod = (m) => {
  if (!m) return "";

  const regexInvoiceMatching = /INVOICE_MATCH_BY_(.*)/;

  const matchingMethodShort = m.startsWith("MATCHING_RULE_")
    ? "MR"
    : m.startsWith("MANUAL")
    ? "MAN"
    : m.startsWith("LEDGER_TRANSFORM")
    ? "FEE"
    : regexInvoiceMatching.test(m)
    ? m.match(regexInvoiceMatching)[1]
    : "OTHER";

  return matchingMethodShort;
};
export const date = (d) => [d ? formatDate(d, "D-MMM-YYYY") : "", {}];
export const dateTime = (d) => [d ? formatDate(d, "D-MMM-YYYY  H:mm") : "", {}];
export const today = () => [formatDate(new Date(), "YYYY-MM-DD"), {}];
