import { useContext } from "react";
import { AppContext } from "../components/DataLoader";
import { getAllowedTables } from "./getAllowedTables";

// this is used in pairings pages not used in table pages as pairing pages are not functional components
export function usePivotLinks(definitions, tableUrlName) {
  const { hasCard, allowedHierarchy, isInFinanceTeam } = useContext(AppContext);

  let pivotLinks = definitions.map(({ name, urlName }, i) => ({
    id: i,
    label: name,
    isActive: tableUrlName === urlName,
    to: `/pivot/${urlName}`,
    onKeyDown: (e) => e.preventDefault(),
    urlName,
  }));
  pivotLinks = getAllowedTables(
    pivotLinks,
    allowedHierarchy,
    hasCard,
    isInFinanceTeam
  );
  return pivotLinks;
}
