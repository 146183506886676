import _ from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { format, getDaysInMonth } from "date-fns";
import PropTypes from "prop-types";
import { PL } from "@pivottable/common/accounts";
import $ from "@pivottable/common/gl";
import { $sa } from "@pivottable/common/sa";
import {
  DatepickerButton,
  StyledDatepicker,
  StyledSelect,
  StyledCheckbox,
  StyledInputDebounced,
} from "../components/FormComponents";
import { AppContext } from "../components/DataLoader";
import $c from "@pivottable/common/literals";

const getDateOptions = ({
  currentYear,
  currentMonth,
  years = false,
  showDay = false,
  showFirstDay = true,
}) => {
  return _.reverse(
    _.flatten(
      _.range(2014, currentYear + 1).map((y) =>
        [
          ..._.range(0, y === currentYear ? currentMonth + 1 : 12).map((m) => ({
            label: showDay
              ? `${format(new Date(y, m), "MMM")} ${
                  showFirstDay ? 1 : getDaysInMonth(new Date(y, m))
                }, ${format(new Date(y, m), "YYYY")}`
              : `${format(new Date(y, m), "MMM YYYY")}`,
            value: `${format(new Date(y, m), "YYYY-MM")}`,
          })),
        ].concat(
          years
            ? [
                {
                  // value needs to be string
                  label: `${y}`,
                  value: `${y}`,
                },
              ]
            : []
        )
      )
    )
  );
};

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

const Account = ({ onChange, filterState, expensesOnly = false }) => {
  let options = [...PL].map((a) => ({ label: a, value: a }));
  if (expensesOnly) {
    options = options.filter(({ label }) => label.startsWith("expenses"));
  }
  const { accounts = [] } = filterState || {};
  const values = options.filter(({ value }) => accounts.includes(value));

  const onAccountChange = (selected) => {
    if (!selected || selected.length === 0) selected = [];
    onChange({ ...filterState, accounts: selected.map(({ value }) => value) });
  };

  return (
    <StyledSelect
      label="Account:"
      isMulti
      options={options}
      onChange={onAccountChange}
      placeholder="All"
      value={values}
      closeMenuOnSelect={false}
    />
  );
};

const Entity = ({ onChange, filterState, filteredEntities = null }) => {
  const { legalEntities } = useContext(AppContext);
  const entityOptions = (filteredEntities || legalEntities).map((e) => ({
    label: e,
    value: e,
  }));
  const { entities = [] } = filterState || {};
  const entityValues = entityOptions.filter(({ value }) =>
    entities.includes(value)
  );

  const onEntityChange = (selected) => {
    selected = selected || [];
    onChange({ ...filterState, entities: selected.map(({ value }) => value) });
  };

  return (
    <StyledSelect
      label="Entity:"
      isMulti
      options={entityOptions}
      onChange={onEntityChange}
      placeholder="All"
      value={entityValues}
      closeMenuOnSelect={false}
    />
  );
};

const Period = ({ onChange, filterState }) => {
  const dateOptions = [
    { label: "YTD", value: "YTD" },
    ...getDateOptions({ currentYear, currentMonth, years: true }),
  ];
  const { months = [] } = filterState || {};
  const selectedMonths = dateOptions.filter(({ value }) =>
    months.includes(value)
  );

  const onMonthChange = (selected) => {
    if (!selected || selected.length === 0) selected = [];
    onChange({ ...filterState, months: selected.map(({ value }) => value) });
  };

  return (
    <StyledSelect
      label="Period:"
      isMulti
      options={dateOptions}
      onChange={onMonthChange}
      value={selectedMonths}
      closeMenuOnSelect={false}
    />
  );
};

const CostUnitAndDepartment = ({ onChange, filterState }) => {
  const { units = [], departments = [] } = filterState || {};
  const { gl } = useContext(AppContext);
  // unitToDepartmentsMap maps unit to list of its departments
  // departments have following properties:
  // label: (string) for react-select component
  // value: (string) for react-select component
  // objectValue: (object) is exact description of dep. and its unit,
  //   this value is used in filterState instead of usual 'value' prop
  const unitToDepartmentsMap = useMemo(
    () =>
      _.flow(
        _.partialRight(_.filter, (row) => PL.has(row[$.from])),
        _.partialRight(_.map, (row) => [row[$.unit], row[$.department]]),
        _.partialRight(_.groupBy, ([unit]) => unit),
        _.partialRight(_.mapValues, (group) =>
          _.flow(
            _.partialRight(_.uniqBy, ([, department]) => department),
            _.partialRight(_.filter, ([, department]) => department),
            _.partialRight(_.map, ([unit, department]) => ({
              label: `${unit} / ${department}`,
              value: `${unit} / ${department}`,
              objectValue: { unit, department },
            }))
          )(group)
        )
      )(gl.slice(1)),
    [gl]
  );
  const unitOptions = _.keys(unitToDepartmentsMap).map((unit) => ({
    value: unit,
    label: unit,
  }));
  const selectedUnits = unitOptions.filter(({ value }) =>
    units.includes(value)
  );
  const departmentOptions = selectedUnits
    .map((selectedUnit) => unitToDepartmentsMap[selectedUnit.value])
    .flat();
  const selectedDepartments = departmentOptions.filter(
    ({ objectValue: { unit, department } }) =>
      departments.some(
        ({ unit: filterUnit, department: filterDepartment }) =>
          filterUnit === unit && filterDepartment === department
      )
  );

  const onUnitChange = (selected) => {
    if (!selected) selected = [];
    const unitValues = selected.map(({ value }) => value);
    const selectedUnitsSet = new Set(unitValues);
    onChange({
      ...filterState,
      units: unitValues,
      departments:
        filterState.departments &&
        filterState.departments.filter(({ unit }) =>
          selectedUnitsSet.has(unit)
        ),
    });
  };
  const onDepartmentChange = (selected) => {
    if (!selected) selected = [];
    onChange({
      ...filterState,
      departments: selected.map(({ objectValue }) => objectValue),
    });
  };
  return (
    <>
      <StyledSelect
        label="Unit:"
        isMulti
        placeholder="All"
        options={unitOptions}
        onChange={onUnitChange}
        value={selectedUnits}
        closeMenuOnSelect={false}
      />
      {selectedUnits.length > 0 && (
        <StyledSelect
          label="Department:"
          isMulti
          placeholder="All"
          options={departmentOptions}
          onChange={onDepartmentChange}
          value={selectedDepartments}
          closeMenuOnSelect={false}
        />
      )}
    </>
  );
};

const GLAccount = ({ onChange, filterState }) => {
  const defaultOptions = [
    {
      label: "Not Empty",
      value: "notEmpty",
    },
    {
      label: "Empty",
      value: "empty",
    },
  ];
  const [input, setInput] = useState("");
  const [options, setOptions] = useState(defaultOptions);

  const { glAccount = [] } = filterState || {};

  const onGlAccountChange = (newValues) => {
    const emptyIndex = newValues.findIndex((o) => o.value === "empty");
    if (newValues.length && emptyIndex !== -1) {
      newValues =
        emptyIndex === newValues.length - 1
          ? [newValues[newValues.length - 1]]
          : newValues.filter((o) => o.value !== "empty");
    }

    onChange({
      ...filterState,
      glAccount: newValues,
    });
  };

  const onInputChange = (newInput) => {
    const extraOptions = newInput ? [{ label: newInput, value: newInput }] : [];
    setOptions([...defaultOptions, ...extraOptions]);
    setInput(newInput);
  };

  return (
    <StyledSelect
      label="GL Account:"
      placeholder="All"
      isMulti
      options={options}
      onChange={onGlAccountChange}
      value={glAccount}
      inputValue={input}
      onInputChange={onInputChange}
    />
  );
};

const ReportingEntity = ({ onChange, filterState }) => {
  const { reportingEntities = [] } = filterState || {};
  const { gl } = useContext(AppContext);
  const reportingEntitiesOptions = useMemo(
    () =>
      _.flow(
        _.partialRight(_.map, (row) => row[$.reportingEntity]),
        _.partialRight(_.filter, (re) => re),
        _.partialRight(_.uniq),
        _.partialRight(_.map, (re) => ({ label: re, value: re }))
      )(gl.slice(1)),
    [gl]
  );
  const selectedReportingEntities = reportingEntitiesOptions.filter(
    ({ value }) => reportingEntities.includes(value)
  );
  const onReportingEntityChange = (selected) => {
    if (!selected) selected = [];
    onChange({
      ...filterState,
      reportingEntities: selected.map(({ value }) => value),
    });
  };
  return (
    <StyledSelect
      label="Reporting Entity:"
      isMulti
      placeholder="All"
      options={reportingEntitiesOptions}
      onChange={onReportingEntityChange}
      value={selectedReportingEntities}
      closeMenuOnSelect={false}
    />
  );
};

const LegalEntity = ({ onChange, filterState }) => {
  const { legalEntities = [] } = filterState || {};
  const { gl } = useContext(AppContext);
  const legalEntitiesOptions = useMemo(
    () =>
      _.flow(
        _.partialRight(_.map, (row) => row[$.entity]),
        _.partialRight(_.filter, (re) => re),
        _.partialRight(_.uniq),
        _.partialRight(_.map, (re) => ({ label: re, value: re }))
      )(gl.slice(1)),
    [gl]
  );
  const selectedLegalEntities = legalEntitiesOptions.filter(({ value }) =>
    legalEntities.includes(value)
  );
  const onLegalEntityChange = (selected) => {
    if (!selected) selected = [];
    onChange({
      ...filterState,
      legalEntities: selected.map(({ value }) => value),
    });
  };
  return (
    <StyledSelect
      label="Legal Entity:"
      isMulti
      placeholder="All"
      options={legalEntitiesOptions}
      onChange={onLegalEntityChange}
      value={selectedLegalEntities}
      closeMenuOnSelect={false}
    />
  );
};

const FromDate = ({ onChange, filterState, dateOptions }) => {
  const { from = null } = filterState || {};
  const fromValue = dateOptions.find(({ value }) => value === from);
  const onFromChange = (selected) => {
    onChange({ ...filterState, from: selected.value });
  };

  return (
    <StyledSelect
      label="From:"
      options={dateOptions}
      onChange={onFromChange}
      value={fromValue}
    />
  );
};

const ToDate = ({ onChange, filterState, dateOptions }) => {
  const { to = null } = filterState || {};
  const toValue = dateOptions.find(({ value }) => value === to);
  const onToChange = (selected) => {
    onChange({ ...filterState, to: selected.value });
  };

  return (
    <StyledSelect
      label="To:"
      options={dateOptions}
      onChange={onToChange}
      value={toValue}
    />
  );
};

export const NonTaxTransactionsFilter = ({ onChange, filterState }) => (
  <React.Fragment>
    <Account {...{ onChange, filterState }} />
    <Period {...{ onChange, filterState }} />
    <CostUnitAndDepartment {...{ onChange, filterState }} />
    <Entity {...{ onChange, filterState }} />
    <ReportingEntity {...{ onChange, filterState }} />
  </React.Fragment>
);

export const PLByMonthFilter = ({ onChange, filterState }) => (
  <React.Fragment>
    <Account {...{ onChange, filterState }} />
    <Period {...{ onChange, filterState }} />
    <CostUnitAndDepartment {...{ onChange, filterState }} />
    <Entity {...{ onChange, filterState }} />
    <ReportingEntity {...{ onChange, filterState }} />
    <GLAccount {...{ onChange, filterState }} />
  </React.Fragment>
);

PLByMonthFilter.propTypes = {
  filterState: PropTypes.shape({
    accounts: PropTypes.arrayOf(PropTypes.string),
    months: PropTypes.arrayOf(PropTypes.string),
    entities: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
};

export const BalanceSheetFilter = ({ onChange, filterState }) => (
  <React.Fragment>
    <ToDateFilter {...{ onChange, filterState }} />
    <ReportingEntity {...{ onChange, filterState }} />
    <LegalEntity {...{ onChange, filterState }} />
  </React.Fragment>
);

export const PLByMonthSplitFilter = ({ onChange, filterState }) => (
  <React.Fragment>
    <Account {...{ onChange, filterState }} />
    <Period {...{ onChange, filterState }} />
    <CostUnitAndDepartment {...{ onChange, filterState }} />
    <Entity {...{ onChange, filterState }} />
    <ReportingEntity {...{ onChange, filterState }} />
    <GLAccount {...{ onChange, filterState }} />
  </React.Fragment>
);

PLByMonthSplitFilter.propTypes = {
  filterState: PropTypes.shape({
    accounts: PropTypes.arrayOf(PropTypes.string),
    months: PropTypes.arrayOf(PropTypes.string),
    entities: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
};

export const PLFilter = ({ onChange, filterState }) => {
  const dateOptionsFrom = getDateOptions({
    currentYear,
    currentMonth,
    years: false,
    showDay: true,
    showFirstDay: true,
  });
  const dateOptionsTo = getDateOptions({
    currentYear,
    currentMonth,
    years: false,
    showDay: true,
    showFirstDay: false,
  });

  return (
    <React.Fragment>
      <FromDate {...{ onChange, filterState }} dateOptions={dateOptionsFrom} />
      <ToDate {...{ onChange, filterState }} dateOptions={dateOptionsTo} />
      <CostUnitAndDepartment {...{ onChange, filterState }} />
      <Entity {...{ onChange, filterState }} />
      <ReportingEntity {...{ onChange, filterState }} />
      <GLAccount {...{ onChange, filterState }} />
    </React.Fragment>
  );
};

PLFilter.propTypes = {
  filterState: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    units: PropTypes.arrayOf(PropTypes.string),
    entities: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
};

export const BudgetFilter = ({ onChange, filterState }) => {
  const options = _.reverse(
    _.range(2014, currentYear + 1).map((y) => ({
      label: `${y}`,
      value: `${y}`,
    }))
  );

  const viewOptions = [
    {
      label: "monthly",
      value: "monthly",
    },
    {
      label: "yearly",
      value: "yearly",
    },
  ];

  const onYearChange = (selected) => {
    selected = selected || { value: undefined };
    onChange({ ...filterState, year: selected.value });
  };
  const onViewChange = (selected) => {
    onChange({ ...filterState, view: selected.value });
  };

  const { year = null, view = null } = filterState || {};
  const value = options.find(({ value }) => value === year);
  const viewValue = viewOptions.find(({ value }) => value === view);

  return (
    <React.Fragment>
      <StyledSelect
        label="Year:"
        options={options}
        onChange={onYearChange}
        value={value}
      />
      <StyledSelect
        label="View:"
        options={viewOptions}
        onChange={onViewChange}
        value={viewValue}
      />
      <Account {...{ onChange, filterState }} expensesOnly />
      <CostUnitAndDepartment {...{ onChange, filterState }} />
    </React.Fragment>
  );
};

BudgetFilter.propTypes = {
  filterState: PropTypes.shape({
    year: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

export const PayrollFilter = ({ onChange, filterState }) => {
  const dateOptions = _.reverse(
    _.flatten(
      _.range(2014, currentYear + 1).map((y) => [
        ..._.range(0, y === currentYear ? currentMonth + 1 : 12).map((m) => ({
          label: `${format(new Date(y, m), "MMM YYYY")}`,
          value: `${format(new Date(y, m), "YYYY-MM")}`,
        })),
      ])
    )
  );

  const typeOptions = [
    { label: "All", value: "" },
    ...[$c.payroll, $c.overheads, $c.equity, $c.hours].map((a) => ({
      label: a,
      value: a,
    })),
  ];

  const onTypeChange = (selected) => {
    onChange(
      selected === null
        ? _.omit(filterState, "type")
        : { ...filterState, type: selected.value }
    );
  };
  const { type = "" } = filterState || {};
  const typeValue = typeOptions.find(({ value }) => value === type);
  return (
    <React.Fragment>
      <FromDate {...{ onChange, filterState }} dateOptions={dateOptions} />
      <ToDate {...{ onChange, filterState }} dateOptions={dateOptions} />
      <StyledSelect
        label="Type:"
        options={typeOptions}
        onChange={onTypeChange}
        value={typeValue}
      />
    </React.Fragment>
  );
};

PayrollFilter.propTypes = {
  filterState: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

export const DatePicker = ({ label, onChange, value }) => {
  const onDateChange = (date) => {
    onChange(date && format(date, "YYYY-MM-DD"));
  };

  const onSetToday = () => {
    onChange(format(new Date(), "YYYY-MM-DD"));
  };

  return (
    <StyledDatepicker
      label={label}
      selected={value && new Date(value)}
      onChange={onDateChange}
      Button={<DatepickerButton label="Today" onClick={onSetToday} />}
    />
  );
};

export const ToDateFilter = ({ onChange, filterState }) => {
  const { to } = filterState || {};
  const onDateChange = (date) => {
    onChange({ ...filterState, to: date });
  };
  return <DatePicker label="To" onChange={onDateChange} value={to} />;
};

ToDateFilter.propTypes = {
  filterState: PropTypes.shape({
    to: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

const FromDateFilter = ({ onChange, filterState }) => {
  const { from } = filterState || {};
  const onDateChange = (date) => {
    onChange({ ...filterState, from: date });
  };
  return <DatePicker label="From" onChange={onDateChange} value={from} />;
};

export const CounterpartyBalancesFilter = ({ onChange, filterState }) => (
  <React.Fragment>
    <FromDateFilter {...{ onChange, filterState }} />
    <ToDateFilter {...{ onChange, filterState }} />
  </React.Fragment>
);

export const EmployeeOverheadsFilter = ({ onChange, filterState }) => {
  const dateOptions = _.reverse(
    _.flatten(
      _.range(2014, currentYear + 1).map((y) => [
        ..._.range(0, y === currentYear ? currentMonth + 1 : 12).map((m) => ({
          label: `${format(new Date(y, m), "MMM YYYY")}`,
          value: `${format(new Date(y, m), "YYYY-MM")}`,
        })),
      ])
    )
  );

  return (
    <React.Fragment>
      <FromDate {...{ onChange, filterState }} dateOptions={dateOptions} />
      <ToDate {...{ onChange, filterState }} dateOptions={dateOptions} />
    </React.Fragment>
  );
};

EmployeeOverheadsFilter.propTypes = {
  filterState: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

export const CardholderViewFilter = ({ onChange, filterState }) => (
  <React.Fragment>
    <Period {...{ onChange, filterState }} />
  </React.Fragment>
);

CardholderViewFilter.propTypes = {
  filterState: PropTypes.shape({
    months: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
};

export const StatutoryAccountingFilter = ({ onChange, filterState }) => {
  const { saData } = useContext(AppContext);

  const periodOptions = [
    { label: "YTD", value: "YTD" },
    ..._.reverse(
      _.range(2014, currentYear + 1).map((y) => ({
        label: `${y}`,
        value: `${y}`,
      }))
    ),
  ];
  const sourceOptions = [
    ...new Set(saData.slice(1).map((row) => row[$sa.source])),
  ].map((item) => ({ value: item, label: item }));

  const filteredEntities = _.uniq(
    saData.slice(1).map((row) => row[$sa.legalEntity])
  );

  const onPeriodChange = (selected) => {
    selected = selected || { value: undefined };
    onChange({ ...filterState, period: selected.value });
  };

  const onSourceChange = (selected) => {
    selected = selected || [];
    onChange({ ...filterState, sources: selected.map(({ value }) => value) });
  };
  const { period = null, sources = [] } = filterState || {};
  const selectedPeriod = periodOptions.find(({ value }) => value === period);
  const sourceValues = sourceOptions.filter(({ value }) =>
    sources.includes(value)
  );

  return (
    <React.Fragment>
      <Entity {...{ onChange, filterState, filteredEntities }} />
      <StyledSelect
        label="Period:"
        options={periodOptions}
        onChange={onPeriodChange}
        value={selectedPeriod}
        isClearable
      />
      <StyledSelect
        label="Source:"
        isMulti
        options={sourceOptions}
        onChange={onSourceChange}
        placeholder="All"
        value={sourceValues}
        closeMenuOnSelect={false}
      />
    </React.Fragment>
  );
};

StatutoryAccountingFilter.propTypes = {
  filterState: PropTypes.shape({
    entities: PropTypes.arrayOf(PropTypes.string),
    period: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
};

export const TranasctionInvoicePairingFilter = ({
  onChange,
  filterState,
  showCardFilter,
}) => {
  const onChangeUpaired = useCallback(() => {
    onChange((filterState) => ({
      ...filterState,
      unpaired: !filterState.unpaired,
    }));
  }, [onChange]);
  const onChangeWhitelisted = useCallback(() => {
    onChange((filterState) => ({
      ...filterState,
      whitelisted: !filterState.whitelisted,
    }));
  }, [onChange]);
  const onChangeAmountFrom = useCallback(
    (amountFrom) => onChange((filterState) => ({ ...filterState, amountFrom })),
    [onChange]
  );
  const onChangeAmountTo = useCallback(
    (amountTo) => onChange((filterState) => ({ ...filterState, amountTo })),
    [onChange]
  );
  const onChangeCard = useCallback(
    (card) => onChange((filterState) => ({ ...filterState, card })),
    [onChange]
  );

  const dateOptionsFrom = getDateOptions({
    currentYear,
    currentMonth,
    years: false,
    showDay: true,
    showFirstDay: true,
  });
  const dateOptionsTo = getDateOptions({
    currentYear,
    currentMonth,
    years: false,
    showDay: true,
    showFirstDay: false,
  });

  const { cards } = useContext(AppContext);
  const cardsOptions = useMemo(() => {
    const cardsWihoutHeader = [
      ["", "No cardholder"],
      ...cards.slice(1), // reamove header row
      ["unknown", ""],
    ];
    return cardsWihoutHeader.map((card) => ({
      value: card[0],
      label: `${card[0]} - ${card[1]}`,
    }));
  }, [cards]);

  return (
    <React.Fragment>
      <StyledInputDebounced
        label="amount from eur"
        value={filterState?.amountFrom ?? ""}
        onChange={onChangeAmountFrom}
      />
      <StyledInputDebounced
        label="amount to eur"
        value={filterState?.amountTo ?? ""}
        onChange={onChangeAmountTo}
      />
      {showCardFilter && (
        <StyledSelect
          label="Card:"
          options={cardsOptions}
          onChange={onChangeCard}
          value={filterState?.card}
        />
      )}
      <Entity {...{ onChange, filterState }} />
      <StyledCheckbox
        {...{ onChange: onChangeUpaired, checked: !!filterState?.unpaired }}
        label="Unpaired"
      />
      <StyledCheckbox
        {...{
          onChange: onChangeWhitelisted,
          checked: !!filterState?.whitelisted,
        }}
        label="Whitelisted"
      />
      <FromDate {...{ onChange, filterState }} dateOptions={dateOptionsFrom} />
      <ToDate {...{ onChange, filterState }} dateOptions={dateOptionsTo} />
    </React.Fragment>
  );
};

TranasctionInvoicePairingFilter.propTypes = {
  filterState: PropTypes.shape({
    unpaired: PropTypes.bool,
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  showCardFilter: PropTypes.bool,
};

export const ReVsLeClaimsFilter = ({ onChange, filterState }) => (
  <React.Fragment>
    <Account {...{ onChange, filterState }} />
    <Period {...{ onChange, filterState }} />
    <Entity {...{ onChange, filterState }} />
  </React.Fragment>
);

export const PairWithMatchingInvoicesFilter = ({ onChange, filterState }) => {
  const onChangeCard = useCallback(
    (card) => onChange((filterState) => ({ ...filterState, card })),
    [onChange]
  );

  const { cards } = useContext(AppContext);
  const cardsOptions = useMemo(() => {
    const cardsWithoutHeader = [
      ["", "No cardholder"],
      ...cards.slice(1), // remove header row
      ["unknown", ""],
    ];
    return cardsWithoutHeader.map((card) => ({
      value: card[0],
      label: `${card[0]} - ${card[1]}`,
    }));
  }, [cards]);

  return (
    <StyledSelect
      label="Card:"
      isClearable
      options={cardsOptions}
      onChange={onChangeCard}
      value={filterState?.card}
    />
  );
};

PairWithMatchingInvoicesFilter.propTypes = {
  filterState: PropTypes.shape({
    card: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};
