import { useContext, useEffect } from "react";
import * as dateFns from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { PairingDataContext } from "../components/context/PairingDataContext";

export const STEP = {
  FIRST_TABLE: "FIRST_TABLE",
  SECOND_TABLE: "SECOND_TABLE",
  FILL_DETAILS: "FILL_DETAILS",
  SUBMITING: "SUBMITING",
  // no ok state after successfull submit you go back to state FIRST_TABLE
  SUBMIT_ERROR: "SUBMIT_ERROR",
};

export const ACTION_TYPE = {
  PICK_TRANSACTION: "PICK_TRANSACTION",
  SKIP_TRANSACTION: "SKIP_TRANSACTION",

  PICK_INVOICE: "PICK_INVOICE",
  SKIP_INVOICE: "SKIP_INVOICE",

  FILL_DETAILS: "FILL_DETAILS",

  GO_TO_BEGINNING: "GO_TO_BEGINNING",

  SUBMIT: "SUBMIT",
  SUBMIT_OK: "SUBMIT_OK",
  SUBMIT_ERROR: "SUBMIT_ERROR",

  DISMIS_ERROR_GO_TO_FILL_DETAIL: "DISMIS_ERROR_GO_TO_FILL_DETAIL",
  TRY_SENDING_REQUEST_AGAIN: "TRY_SENDING_REQUEST_AGAIN",
};

export const getInitialState = (firstTableToShow = "transaction") => ({
  firstTableToShow,
  step: STEP.FIRST_TABLE,
  details: {},
  detailsKeysEnabled: new Set(),
  submitRequest: null,
  submitError: null,
  invoice: null,
  transaction: null,
  initialInvoicesFilter: { unpaired: true },
  initialTransactionsFilter: { whitelisted: true, unpaired: true },
});

function generateDetailsFromState(state, sugestedPairing = undefined) {
  // we have invoice and  also transaction
  if (state.invoice && state.transaction) {
    return {
      details: {
        currency: state.invoice.currency ?? "", //disabled
        // TODO should be unpaired amount not amount on invoice
        amount: state.invoice.amountUnpaired ?? "", // enabled
        account_from: state.transaction
          ? `cash/${state.transaction.bank_account}`
          : "", //conditionaly enabled
        account_to: state.invoice.incoming ? "payables" : "receivables", //disabled
        center: "", // disabled
        po: "", // disabled
        pid: "", // disabled
        non_tax_expense: null, //disabled
      },
      detailsKeysEnabled: new Set([
        "amount",
        ...(state.transaction ? [] : ["account_from"]),
      ]),
    };
  }

  //we have invoice but we do not have a transaction
  if (state.invoice && !state.transaction) {
    return {
      details: {
        currency: state.invoice.currency ?? "", //disabled
        // TODO should be unpaired amount not amount on invoice
        amount: state.invoice.amountUnpaired ?? "", // enabled
        account_from: state.transaction
          ? `cash/${state.transaction.bank_account}`
          : "", //conditionaly enabled
        account_to: state.invoice.incoming ? "payables" : "receivables", //disabled
        center: "", // disabled
        po: "", // disabled
        pid: "", // disabled
        non_tax_expense: null, //disabled
      },
      detailsKeysEnabled: new Set([
        "amount",
        ...(state.transaction ? [] : ["account_from"]),
        "pid",
      ]),
    };
  }

  // we don't have invoice but we have transacton
  if (state.transaction) {
    return {
      details: {
        currency: state.transaction.currency ?? "",
        amount: state.transaction.amount ?? "",
        account_from: `cash/${state.transaction.bank_account}` ?? "",
        account_to: sugestedPairing?.account_to ?? "",
        center: sugestedPairing?.center ?? "",
        po: sugestedPairing?.po ?? "",
        pid: sugestedPairing?.pid ?? "",
        non_tax_expense: sugestedPairing?.non_tax_expense ?? false,
      },
      detailsKeysEnabled: new Set([
        "currency",
        "amount",
        "account_to",
        "center",
        "po",
        "pid",
        "non_tax_expense",
      ]),
    };
  }
  // this should not happen that nor invoice nor transaction is selected
  const stateUpdate = {
    details: {
      currency: "",
      amount: "",
      account_from: "",
      account_to: "",
      center: "",
      po: "",
      pid: "",
    },
    detailsKeysEnabled: new Set([]),
  };
  return stateUpdate;
}

export function reducer(state, action) {
  // TODO keeping it here so it is possible to debug errors in PROD
  // after prod is stable for at least few months (written at 11.8.2021) we can delete this
  // eslint-disable-next-line no-console
  console.log("CALLED REDUCER with:", { state, action });
  switch (action.type) {
    case ACTION_TYPE.PICK_TRANSACTION: {
      const pickedTransaction = action.transaction;
      const generatedPairing = action.generatedPairing;
      const entityIdToLegalEntity = action.entityIdToLegalEntity;

      if (pickedTransaction.hasUnresolvedComments) {
        // this is not super safe to do alert in reducer as it is side effect but it is good for now
        // eslint-disable-next-line no-alert
        window.alert(
          "Picking transaction that has unresolved comments is forbiden press ctrl+k to add/resolve comment"
        );
        return state;
      }
      let nextStep =
        state.step === STEP.FIRST_TABLE ? STEP.SECOND_TABLE : STEP.FILL_DETAILS;
      if (generatedPairing && state.step === STEP.FIRST_TABLE) {
        // we are on first step but we matched this transaction with matching rules so no need to pick invoice
        // so we go directly to fill details
        nextStep = STEP.FILL_DETAILS;
      }
      const amount90 = Math.floor(pickedTransaction?.amountUnpairedEur * 0.9);
      const amount110 = Math.round(
        pickedTransaction?.amountUnpairedEur * 1.1 + 0.5
      );
      const initialInvoicesFilter = {
        unpaired: true,
        amountFrom: String(amount90 < amount110 ? amount90 : amount110),
        amountTo: String(amount90 > amount110 ? amount90 : amount110),
        entities: pickedTransaction?.entity
          ? [entityIdToLegalEntity[pickedTransaction?.entity]]
          : [],
        from: dateFns.format(
          dateFns.subMonths(new Date(pickedTransaction.settlement_date), 1),
          "YYYY-MM"
        ), // for picked invoice it goes by due_date
        to: dateFns.format(
          dateFns.addMonths(new Date(pickedTransaction.settlement_date), 1),
          "YYYY-MM"
        ), // for picked invoice it goes by due_date
      };
      const newState = {
        ...state,
        step: nextStep,
        transaction: { ...pickedTransaction }, // we explicitly spread here so we dont get nested pairings structure into state
        initialInvoicesFilter,
      };
      return {
        ...newState,
        ...generateDetailsFromState(newState, generatedPairing),
      };
    }

    case ACTION_TYPE.SKIP_TRANSACTION: {
      const nextStep =
        state.step === STEP.FIRST_TABLE ? STEP.SECOND_TABLE : STEP.FILL_DETAILS;
      return {
        ...state,
        step: nextStep,
        transcation: undefined,
        ...generateDetailsFromState(state),
      };
    }

    case ACTION_TYPE.PICK_INVOICE: {
      const nextStep =
        state.step === STEP.FIRST_TABLE ? STEP.SECOND_TABLE : STEP.FILL_DETAILS;

      const pickedInvoice = action.invoice;
      const entityIdToLegalEntity = action.entityIdToLegalEntity;

      const amount90 = Math.floor(pickedInvoice?.amountUnpairedEur * 0.9);
      const amount110 = Math.round(
        pickedInvoice?.amountUnpairedEur * 1.1 + 0.5
      );
      // 0.5 is here because of Math.round behaviour.
      // We want if amountUnpairedEur is 0.01 to round amounTo to 1
      const initialTransactionsFilter = {
        whitelisted: true,
        unpaired: true,
        amountFrom: String(amount90 < amount110 ? amount90 : amount110),
        amountTo: String(amount90 > amount110 ? amount90 : amount110),

        entities: pickedInvoice?.entity
          ? [entityIdToLegalEntity[pickedInvoice?.entity]]
          : [],
        from: dateFns.format(
          dateFns.subDays(
            new Date(pickedInvoice.due_date ?? pickedInvoice.issue_date),
            14
          ),
          "YYYY-MM-DD"
        ), // for picked invoice it goes by due_date
        to: dateFns.format(
          dateFns.addDays(
            new Date(pickedInvoice.due_date ?? pickedInvoice.issue_date),
            14
          ),
          "YYYY-MM-DD"
        ), // for picked invoice it goes by due_date
      };

      const newState = {
        ...state,
        step: nextStep,
        initialTransactionsFilter,
        invoice: { ...pickedInvoice }, // we explicitly spread here so we dont get nested pairings structure into state
      };
      return { ...newState, ...generateDetailsFromState(newState) };
    }

    case ACTION_TYPE.SKIP_INVOICE: {
      const nextStep =
        state.step === STEP.FIRST_TABLE ? STEP.SECOND_TABLE : STEP.FILL_DETAILS;
      const newState = { ...state, step: nextStep, invoice: null };

      if (newState.transaction == null && newState.invoice == null) {
        newState.step = STEP.FIRST_TABLE;
      }
      return { ...newState, ...generateDetailsFromState(newState) };
    }

    case ACTION_TYPE.FILL_DETAILS: {
      const { fieldName, fieldValue } = action;
      // if editing field is not enabled just ignore update
      if (!state.detailsKeysEnabled?.has(fieldName)) return state;
      return {
        ...state,
        details: { ...state.details, [fieldName]: fieldValue },
      };
    }

    case ACTION_TYPE.GO_TO_BEGINNING: {
      return getInitialState(state.firstTableToShow);
    }

    case ACTION_TYPE.SUBMIT: {
      return {
        ...state,
        step: STEP.SUBMITING,
        submitRequest: {
          uuid: uuidv4(),
          transaction_uuid: state.transaction?.uuid,
          invoice_id: state.invoice?.id,
          invoice_entity: state.invoice?.entity,
          currency: state.details?.currency,
          amount: state.details?.amount,
          account_from: state.details?.account_from,
          account_to: state.details?.account_to,
          center: state.details?.center,
          po: state.details?.po,
          pid: state.details?.pid,
          non_tax_expense: state.details?.non_tax_expense,
          matching_method:
            state.firstTableToShow === "transaction"
              ? "MANUAL_PAIR_TRANSACTION"
              : "MANUAL_PAIR_INVOICE",
        },
      };
    }

    case ACTION_TYPE.SUBMIT_OK: {
      return getInitialState(state.firstTableToShow);
    }

    case ACTION_TYPE.SUBMIT_ERROR: {
      return {
        ...state,
        step: STEP.SUBMIT_ERROR,
        submitError: action.submitError,
      };
    }

    case ACTION_TYPE.DISMIS_ERROR_GO_TO_FILL_DETAIL: {
      return { ...state, step: STEP.FILL_DETAILS, submitRequest: null };
    }

    case ACTION_TYPE.TRY_SENDING_REQUEST_AGAIN: {
      return { ...state, submitRequest: { ...state.submitRequest } };
    }

    default:
      throw new Error(
        `Unknown combination of state and action? ${state} ${action}`
      );
  }
}

async function postPairingToServer(pairing, signal) {
  const res = await fetch("/client-update/pairTransactionInvoice", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pairing),
    signal,
  });
  if (res.status !== 200) {
    const body = await res.text();
    throw new Error(`Status: ${res.status} Response body:${body}`);
  }
  const body = await res.json();
  return body;
}

export async function deletePairingFromServer(pairing) {
  const res = await fetch("/client-update/deletePairingTransactionInvoice", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ uuid: pairing.uuid }),
  });
  if (res.status !== 200) {
    const body = await res.text();
    throw new Error(`Status: ${res.status} Response body:${body}`);
  }
  const body = await res.json();
  return body;
}

export function useExecuteSubmit(state, dispatch) {
  const { addNewPairingsToAlreadyFetchedData } = useContext(PairingDataContext);
  useEffect(() => {
    if (state.submitRequest == null) return undefined;
    const controller = new AbortController();
    postPairingToServer(state.submitRequest, controller.signal)
      .then((res) => {
        if (res.ok) {
          if (!Array.isArray(res.pairings) || res.pairings.length === 0) {
            dispatch({
              type: ACTION_TYPE.SUBMIT_ERROR,
              submitError: "We have no array of saved pairings",
            });
          } else {
            addNewPairingsToAlreadyFetchedData(res.pairings);
            dispatch({ type: ACTION_TYPE.SUBMIT_OK });
          }
        } else {
          dispatch({ type: ACTION_TYPE.SUBMIT_ERROR, submitError: res.errors });
        }
      })
      .catch((e) => {
        dispatch({ type: ACTION_TYPE.SUBMIT_ERROR, submitError: e.message });
      });
    return () => {
      controller.abort();
    };
  }, [state.submitRequest, dispatch, addNewPairingsToAlreadyFetchedData]);
  return; // deliberatly returning nothing
}
