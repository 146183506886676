import React, {
  useMemo,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import _ from "lodash/fp";
import { FilterDrawerContextProvider } from "../components/context/FilterDrawerContext";
import { PairWithMatchingInvoicesFilter } from "../definitions/components";
import FilterDrawer from "../components/FilterDrawer";
import RefetchDataPanel from "../components/RefetchDataPanel";
import BottomBar from "../components/BottomBar";
import ObjectTable from "../components/ObjectTable";

import * as format from "../definitions/format";
import { color } from "../components/style";
import { PairingDataContext } from "../components/context/PairingDataContext";
import { createPairingsForTransactionWithInvoices } from "./matchingInvoices";
import { useMergeTransactionPairingCommentsIntoTransactions } from "./useMergeTransactionsInvoicesWithPairings";
import { CommentTransaction } from "./CommentTransaction";
//import { formatRFC3339 } from "date-fns";

const MAIN_TRANSACTION_COLUMN_DEFINITION = [
  {
    key: "hasUnresolvedComments",
    minWidth: 30,
    format: (v) => (v ? "\u274C" : ""),
  },
  {
    key: "hasCommentsCount",
    minWidth: 40,
    format: (v) => (v > 0 ? `${v}\u{1F4AC}` : ""),
  },
  {
    key: "hasUnresolvedCommentsAsignedToMe",
    minWidth: 30,
    format: (v) => (v ? "\u{2753}" : ""),
  },
  { key: "date", format: format.date },
  { key: "settlement_date", format: format.date },
  { key: "entity" },
  { key: "bank_account" },
  { key: "type", minWidth: 40 },
  { key: "amount", format: format.decimal },
  { key: "currency", minWidth: 50 },
  { key: "amountUnpairedEur", format: format.eur },
  { key: "amountEur", format: format.eur },
  { key: "amount_original", format: format.decimal },
  { key: "currency_original", minWidth: 50 },
  { key: "card" },
  { key: "counterparty_name" },
  { key: "message" },
  //{ key: "reference" },
  //{ key: "counterparty_merged" },
  { key: "refProcessed" }, //FIX REGEX HERE
  { key: "additional_info", minWidth: 50 },
  { key: "counterparty_account", minWidth: 50 },
  { key: "counterparty_bank", minWidth: 50 },
  { key: "uuid", minWidth: 50, format: (v) => `${v}` },
  // { key: "account_type" },
  // { key: "counter_party_type" },
  // { key: "center_type" },
];

export const NESTED_PAIRING_COLUMN_DEFINITION = [
  {
    key: "error",
    format: (e) =>
      e == null || e === "" ? "" : [`${e}`, { backgroundColor: color.red }],
  },
  { key: "matching_method_short" },
  { key: "invoice.url", minWidth: 28 },

  { key: "invoice.issue_date", format: format.date, text: "issue_date" },
  { key: "invoice.due_date", format: format.date, text: "due_date" },
  {
    key: "invoice.entity_id",
    text: "invoice id",
    minWidth: 110,
  },
  //{ key: "uuid" },
  { key: "account_to" },
  /*{
    key: "invoice.incoming",
    text: "incoming",
    format: (i) => (i ? "in" : "out"),
  },*/
  {
    key: "invoice.payment",
    text: "payment method",
    format: format.paymentMethod,
  },
  { key: "invoice.amount", format: format.decimal, text: "total" },
  { key: "invoice.currency", text: "currency" },
  {
    key: "invoice.amountUnpairedEur",
    text: "amountUnpairedEur",
    format: format.eur,
  },
  { key: "invoice.amountEur", format: format.eur, text: "amountEur" },
  {},
  {
    key: "invoice.payroll",
    text: "payroll",
    format: (p) => (p ? "\u{1F4B8}" : ""),
  },
  { key: "invoice.po", text: "po" },
  { key: "invoice.counterparty", text: "counterparty" },
  { key: "invoice.variable_symbol", text: "vs" },
  {},
  { key: "invoice.gl_account", text: "gl account" },
  { key: "invoice.accounted_id", text: "accounted id" },
  { key: "invoice.entity_id", text: "id" },
];

function ShowPairedTransactions(props) {
  const [sortBy, sortChange] = useState(null);

  const [filterState, filterChange] = useState(props.initialFilter);
  const filteredTransactions = useMemo(
    () =>
      props.transactions.filter((transaction) => {
        const matchCard =
          filterState?.card?.value == null ||
          filterState?.card?.value === "" ||
          (transaction?.card ?? "").includes(filterState?.card?.value);

        return matchCard;
      }),
    [props.transactions, filterState]
  );

  const [commentTransaction, setCommentTransaction] = useState(null);
  const filteredTransactionsWithComments =
    useMergeTransactionPairingCommentsIntoTransactions(
      filteredTransactions,
      props.transactionPairingComments
    );

  const { deletePairingUuid, deleteTranscationId, getSelectedObjectsRef } =
    props;
  const onCtrlD = useCallback(
    ({ nestedObject, mainObject }) => {
      const { mainObjects = [], nestedObjects = [] } =
        getSelectedObjectsRef?.current?.() ?? {};

      mainObjects.forEach((t) => deleteTranscationId(mainObject.uuid));
      nestedObjects.forEach((p) => deletePairingUuid(p.uuid));
    },
    [getSelectedObjectsRef, deletePairingUuid, deleteTranscationId]
  );

  return (
    <FilterDrawerContextProvider defaultOpen={Boolean(false)}>
      <CommentTransaction
        commentTransaction={commentTransaction}
        setCommentTransaction={setCommentTransaction}
      />
      <ObjectTable
        dataObjectList={filteredTransactionsWithComments}
        sortBy={sortBy}
        sortChange={sortChange}
        mainColumnDefinition={MAIN_TRANSACTION_COLUMN_DEFINITION}
        nestedColumnDefinition={NESTED_PAIRING_COLUMN_DEFINITION}
        nestedProperty={"proposed_pairings"}
        onCtrlP={() => {
          /*do nothing*/
        }}
        onCtrlI={() => {
          /*do nothing*/
        }}
        onCtrlD={onCtrlD}
        onCtrlK={({ nestedObject, mainObject }) =>
          mainObject && setCommentTransaction(mainObject)
        }
        getSelectedObjectsRef={getSelectedObjectsRef}
      />
      <FilterDrawer
        filterComponent={
          <PairWithMatchingInvoicesFilter
            onChange={filterChange}
            filterState={filterState}
            isDisabled={false}
          />
        }
        refetchComponent={
          <RefetchDataPanel
            buttonText="Reload data from server"
            timestamp={props.timestamp}
          />
        }
      />
      {filterState && (
        <BottomBar filterState={filterState} onFilterChange={filterChange} />
      )}
    </FilterDrawerContextProvider>
  );
}

// return map of pairing ids to errors or null for no error
function validatieTransactionPairings(transactions) {
  const errorPairings = {};
  const usedInvoices = new Set();
  transactions.forEach((transaction) => {
    transaction.proposed_pairings.forEach((pairing) => {
      if (transaction.proposed_pairings.length > 1) {
        errorPairings[pairing.uuid] =
          "more than one invoice per transaction is not allowed";
      }
      if (usedInvoices.has(pairing.invoice)) {
        errorPairings[pairing.uuid] =
          "this invoice has already been used (you can automaticly pair 2 different transaction with same invocie)";
      }
      usedInvoices.add(pairing.invoice);
    });
  });
  return Object.keys(errorPairings).length === 0 ? null : errorPairings;
}

export const PairWithMatchingInvoices = (props) => {
  const { addNewPairingsToAlreadyFetchedData } = useContext(PairingDataContext);
  const getSelectedObjectsRef = useRef(null);

  const unpairedTransactions = useMemo(() => {
    // TODO here we show only 500 of unpaired transactions
    return _.take(
      500,
      props.transactions
        .filter((t) => t.whitelisted)
        .filter((t) => t.pairings.length === 0)
        .sort((a, b) => Math.abs(b.amountEur) - Math.abs(a.amountEur))
    );
  }, [props.transactions]);
  const unpairedInvoices = useMemo(() => {
    return props.invoices.filter((i) => i.pairings.length === 0);
  }, [props.invoices]);

  const accountedIdToInvoice = useMemo(() => {
    const aid2inv = {};
    for (let i = 0; i < unpairedInvoices.length; i++) {
      const invoice = unpairedInvoices[i];
      if (invoice.accounted_id) {
        aid2inv[`${invoice.entity}#${invoice.accounted_id}`] = invoice;
      }
    }
    return aid2inv;
  }, [unpairedInvoices]);

  const matchedTransactions = useMemo(() => {
    unpairedTransactions.forEach((transaction, i) => {
      // eslint-disable-next-line no-console
      i % 100 === 0 && console.log("Pairing transaction id ", i);
      const pairings = createPairingsForTransactionWithInvoices(
        transaction,
        unpairedInvoices,
        accountedIdToInvoice
      );
      transaction.proposed_pairings = pairings ?? [];
    });
    return unpairedTransactions.filter((t) => t.proposed_pairings.length > 0);
  }, [unpairedTransactions, unpairedInvoices, accountedIdToInvoice]);

  const [deletedTransactionsIds, setDeletedTransactionsIds] = useState([]);
  // map from transaction.uuid to array of deleted invoice.entity_id
  const [deletedPairingsUuids, setDeletedPairingsUuids] = useState([]);

  const deleteTranscationId = useCallback(
    (transactionId) => {
      setDeletedTransactionsIds((deletedTransactions) => [
        ...deletedTransactions,
        transactionId,
      ]);
    },
    [setDeletedTransactionsIds]
  );
  const deletePairingUuid = useCallback(
    (pairingUuid) => {
      setDeletedPairingsUuids((deletedPairings) => [
        ...deletedPairings,
        pairingUuid,
      ]);
    },
    [setDeletedPairingsUuids]
  );

  // REMOVE USER DELETED INVOICES AND TRANSACTIONS
  const matchedTransactionWithoutDeleted = useMemo(() => {
    // delete pairings that have been removed
    matchedTransactions.forEach((t) => {
      t.proposed_pairings = t.proposed_pairings.filter(
        (pairing) => deletedPairingsUuids.indexOf(pairing.uuid) === -1
      );
    });
    // filter transactions that has been deleted and also transactions that has zero pairings
    return _.take(
      20, //TODO delete after most transaction has been paired
      matchedTransactions.filter(
        (t) =>
          deletedTransactionsIds.indexOf(t.uuid) === -1 &&
          t.proposed_pairings.length > 0
      )
    );
  }, [matchedTransactions, deletedTransactionsIds, deletedPairingsUuids]);

  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [errorPairings, setErrorPairings] = useState(null);

  const onSubmit = useCallback(
    (allTransactions) => {
      const { mainObjects: selectedMainObjects = [] } =
        getSelectedObjectsRef?.current?.() ?? {};
      const selectedTransactionsUuid = new Set(
        selectedMainObjects.map((t) => t.uuid)
      );
      const transactions = allTransactions.filter((t) =>
        selectedTransactionsUuid.has(t.uuid)
      );
      const transactionWithoutUnresolvedComments = transactions.filter(
        (t) => t.hasUnresolvedComments === false
      );
      if (transactionWithoutUnresolvedComments.length === 0) {
        setSubmitError("No picked transcations to submit");
        return;
      }

      const errorParings = validatieTransactionPairings(
        transactionWithoutUnresolvedComments
      );
      if (errorParings != null) {
        setSubmitError("Fail to submit because of client side validation");
        setErrorPairings(errorParings);
        return;
      }
      try {
        setIsSubmitInProgress(true);
        setSubmitError(null);
        setErrorPairings(null);
        const pairings = transactionWithoutUnresolvedComments
          .map((t) => t.proposed_pairings[0])
          .map((p) => {
            // eslint-disable-next-line no-unused-vars
            const { invoice, ...restOfPairing } = p;
            return restOfPairing;
          });

        fetch("/client-update/pairTransactionInvoice", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pairings),
        })
          .then(async (response) => {
            const body = await response.json();
            if (response.status === 200 && body.ok === true && body.pairings) {
              // success response
              addNewPairingsToAlreadyFetchedData(body.pairings);
            } else if (body.errors) {
              setSubmitError(body.error);
              setErrorPairings(body.errors);
            } else {
              setSubmitError(
                body.error ?? `something went wrong? ${response.status}`
              );
            }
          })
          .catch((e) => {
            setSubmitError(`Error submiting request ${e}`);
          })
          .finally(() => setIsSubmitInProgress(false));
      } catch (e) {
        setSubmitError(e?.message);
        setIsSubmitInProgress(false);
      }
    },
    [
      setIsSubmitInProgress,
      setSubmitError,
      setErrorPairings,
      addNewPairingsToAlreadyFetchedData,
    ]
  );

  const matchedTransactionWithoutDeletedWithErrors = useMemo(() => {
    matchedTransactionWithoutDeleted.forEach((transaction) => {
      if (!errorPairings) return;
      transaction.proposed_pairings.forEach((pairing) => {
        if (errorPairings[pairing.uuid] != null) {
          pairing.error = errorPairings[pairing.uuid];
        }
      });
    });
    return matchedTransactionWithoutDeleted;
  }, [matchedTransactionWithoutDeleted, errorPairings]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ flexGrow: 0 }}>
        <button
          disabled={isSubmitInProgress}
          onClick={() => onSubmit(matchedTransactionWithoutDeletedWithErrors)}
        >
          Submit selected transactions
        </button>
        {submitError ? `Error: ${submitError}` : null}
      </div>
      <div style={{ flexGrow: 1 }}>
        <ShowPairedTransactions
          {...props}
          transactions={matchedTransactionWithoutDeletedWithErrors}
          deleteTranscationId={deleteTranscationId}
          deletePairingUuid={deletePairingUuid}
          getSelectedObjectsRef={getSelectedObjectsRef}
        />
      </div>
    </div>
  );
};
