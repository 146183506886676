import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { setupAuthentication } from "./auth";

const rootElement = document.getElementById("root");
setupAuthentication();
const renderApp = () => {
  ReactDOM.render(<App />, rootElement);
};
renderApp();
