import React from "react";
import _ from "lodash/fp";
import memoize from "memoize-one";
import PropTypes from "prop-types";
import { headerStyle, color, iconFonts } from "./style";
import GridCanvasWrapper from "./GridCanvasWrapper";

export default class DataTable extends React.Component {
  mousePos = null;

  _tableData = memoize(
    (
      data,
      sortCol,
      sortOrder,
      sourceLink,
      colDetailWidths = [],
      sourceLinks
    ) => {
      let values = data.slice(1);
      if (sortCol !== null) {
        values = _.orderBy([sortCol], [sortOrder], values);
      }

      const links = values.map((r) =>
        sourceLink ? sourceLink(r, sourceLinks) : null
      );

      // some columns can be hidden(by being skipped) if their width in colDetailWidths is 0
      const [columnIndexToDataIndex, columnWidths] = _.unzip(
        data[0].reduce((acc, _column, index) => {
          if (colDetailWidths[index] === 0) return acc;
          const width =
            colDetailWidths[index] === undefined ? 100 : colDetailWidths[index];
          acc.push([index, width]);
          return acc;
        }, [])
      );
      return {
        links,
        data: [data[0], ...values],
        columnIndexToDataIndex,
        columnWidths,
      };
    }
  );

  tableData = () => {
    const { data, sortBy, sourceLink, colDetailWidths, sourceLinks } =
      this.props;
    const { col, order } = sortBy || { col: null, order: null };
    return this._tableData(
      data,
      col,
      order,
      sourceLink,
      colDetailWidths,
      sourceLinks
    );
  };

  getCell = (column, row) => {
    const { col, order } = this.props.sortBy || { col: null, order: null };
    const { sourceFormat } = this.props;
    let result = null;
    const { data, links, columnIndexToDataIndex } = this.tableData();
    const dataColumn = columnIndexToDataIndex[column];
    const value = data[row][dataColumn];
    if (row === 0) {
      result = [
        `${value}`,
        _.set(
          "right",
          {
            icon:
              dataColumn === col
                ? order === "desc"
                  ? "\uf0dd"
                  : "\uf0de"
                : "\uf0dc",
            backgroundColor: _.isEqual(
              [[column, row], "right"],
              [this.mousePos, this.cellArea]
            )
              ? color.headerIconHoverBg
              : color.headerIconBg,
            font: iconFonts.regular,
          },
          headerStyle
        ),
      ];
    } else {
      const format =
        sourceFormat && dataColumn in sourceFormat
          ? sourceFormat[dataColumn]
          : (v) => [v == null ? "" : `${v}`, {}];
      result = format(value);
      if (column === 0 && links && links[row - 1] && links[row - 1].link) {
        result[1] = _.merge(
          {
            right: {
              icon: links[row - 1].icon,
              backgroundColor: _.isEqual(
                [[column, row], "right"],
                [this.mousePos, this.cellArea]
              )
                ? color.iconBg
                : color.iconHoverBg,
              font: iconFonts.regular,
            },
          },
          result[1]
        );
      }
    }
    return [...result, [], value];
  };

  onClick = (e) => {
    if (!this.mousePos) return;
    const { links, columnIndexToDataIndex } = this.tableData();
    const [c, r] = this.mousePos;
    if (r === 0 && this.cellArea === "right") {
      const dataColumn = columnIndexToDataIndex[c];
      const { col, order } = this.props.sortBy || { col: null, order: null };
      if (col === dataColumn && order === "desc") {
        this.props.sortChange(null);
        return;
      }
      this.props.sortChange({
        col: dataColumn,
        order: col !== dataColumn ? "asc" : order === "asc" ? "desc" : "asc",
      });
    } else if (
      links &&
      links[r - 1] &&
      links[r - 1].link &&
      c === 0 &&
      this.cellArea === "right"
    ) {
      const address = links[r - 1].link;
      window.open(address, "_blank");
    }
  };

  render() {
    const { data, columnWidths } = this.tableData();
    return (
      <GridCanvasWrapper
        onClick={this.onClick}
        getCell={this.getCell}
        colWidths={columnWidths}
        rows={data.length}
        cols={columnWidths.length}
        frozen={[0, 1]}
        onPaint={({ mousePos, cellArea }) => {
          this.mousePos = mousePos;
          this.cellArea = cellArea;
        }}
      />
    );
  }
}

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array),
  sortBy: PropTypes.shape({
    col: PropTypes.number,
    order: PropTypes.string,
  }),
  sortChange: PropTypes.func,
  sourceFormat: PropTypes.object,
  colDetailWidths: PropTypes.arrayOf(PropTypes.number),
};
