import React, { Component } from "react";

const FilterDrawerContext = React.createContext();

class FilterDrawerContextProvider extends Component {
  state = {
    isFilterOpen: this.props.defaultOpen,
  };

  toggleFilter = () => {
    this.setState((prevState) => ({
      isFilterOpen: !prevState.isFilterOpen,
    }));
  };

  setFilterOpen = (open) => {
    this.setState({
      isFilterOpen: open,
    });
  };

  render() {
    return (
      <FilterDrawerContext.Provider
        value={{
          isFilterOpen: this.state.isFilterOpen,
          toggleFilter: this.toggleFilter,
          setFilterOpen: this.setFilterOpen,
        }}
      >
        {this.props.children}
      </FilterDrawerContext.Provider>
    );
  }
}

export { FilterDrawerContext, FilterDrawerContextProvider };
