import React, { useMemo, useState } from "react";
import ObjectTable from "../components/ObjectTable";
import { FilterDrawerContextProvider } from "../components/context/FilterDrawerContext";
import { TranasctionInvoicePairingFilter } from "../definitions/components";
import FilterDrawer from "../components/FilterDrawer";
import RefetchDataPanel from "../components/RefetchDataPanel";
import BottomBar from "../components/BottomBar";
import * as format from "../definitions/format";
import { parseNumberOrNull } from "../utils";

// key, text reprezentation, min width
export const MAIN_INVOICE_COLUMN_DEFINITION = [
  { key: "url", minWidth: 28 },
  {
    key: "entity_id",
    text: "invoice id",
    minWidth: 150,
  },
  //{ key: "idNumber", minWidth: 150 }, //just for testing purposes
  /*{
    key: "incoming",
    format: (i) => (i ? "in" : "out"),
  },*/
  { key: "issue_date", format: format.date },
  { key: "due_date", format: format.date },
  { key: "delivery_date", format: format.date },
  {
    key: "payment",
    minWidth: 40,
    text: "payment method",
    format: format.paymentMethod,
  },
  {
    key: "payroll",
    format: (p) => (p ? "\u{1F4B8}" : ""),
    minWidth: 40,
  },
  { key: "amountUnpairedEur", format: format.eur },
  { key: "amountEur", format: format.eur },
  { key: "amount", text: "total", format: format.decimal },
  { key: "currency", minWidth: 40 },
  // { key: "pairing.amount" },
  // { key: "pairing.currency" },
  //{ key: "vat" },
  { key: "po" },
  { key: "counterparty", minWidth: 200 },
  { key: "account_to" },
  { key: "last_matched_at", format: format.date },
  { key: "last_matched_by" },
  //{ key: "counterpartyExtracted", minWidth: 200 }, //just for testing purposes
  // {key: "accounted_id"}, // deliberatly not shown
  // {key: "gl_account"},  //deliberatly not shown
];

const NESTED_PAIRING_COLUMN_DEFINITION = [
  {},
  { key: "transaction.entity", text: "entity" },
  { key: "transaction.bank_account", text: "bank_account" },
  { key: "transaction.date", format: format.date },
  { key: "transaction.settlement_date", format: format.date },
  { key: "transaction.type", text: "type" },
  {},
  {
    key: "transaction.amountUnpairedEur",
    text: "amountUnpairedEur",
    format: format.eurOptional,
  },
  {
    key: "transaction.amountEur",
    text: "amountEur",
    format: format.eurOptional,
  },
  { key: "transaction.amount", text: "amount", format: format.decimalOptional },
  { key: "transaction.currency", text: "currency" },
  {
    key: "transaction.amount_original",
    text: "amount_original",
    format: format.decimalOptional,
  },
  { key: "transaction.currency_original", text: "currency_original" },
  { key: "transaction.card", text: "card" },
  { key: "transaction.counterparty_name", text: "counterparty_name" },
  { key: "transaction.reference", text: "reference" },
  { key: "transaction.message", text: "message" },
  { key: "transaction.additional_info", text: "additional_info" },
  { key: "transaction.uuid", text: "uuid" },
  { key: "amount", format: format.decimalOptional },
  { key: "currency" },
  { key: "non_tax_expense" },
  { key: "center" },
  { key: "pid" },
];

export function PickInvoice(props) {
  const [sortBy, sortChange] = useState(null);
  const [filterState, filterChange] = useState(props.initialFilter);

  const filteredInvoices = useMemo(() => {
    const filteredInvoices = props.invoices.filter((invoice) => {
      const matchBeforeTo =
        !filterState?.to || invoice.due_date < filterState?.to;

      const matchAfterFrom =
        !filterState?.from || invoice.due_date >= filterState?.from;

      const matchUnpared =
        !filterState?.unpaired || invoice.amountUnpairedEur !== 0;

      const amountFromInt = parseNumberOrNull(filterState?.amountFrom);
      const matchAmountFrom =
        amountFromInt == null || invoice.amountUnpairedEur >= amountFromInt;

      const amountToInt = parseNumberOrNull(filterState?.amountTo);
      const matchAmountTo =
        amountToInt == null || invoice.amountUnpairedEur <= amountToInt;

      const matchEntity =
        !filterState?.entities ||
        filterState.entities == null ||
        filterState.entities.length === 0 ||
        filterState.entities.some(
          (legalEntity) => props.legalEntityToId[legalEntity] === invoice.entity
        );

      return (
        matchBeforeTo &&
        matchAfterFrom &&
        matchUnpared &&
        matchAmountFrom &&
        matchAmountTo &&
        matchEntity
      );
    });
    return filteredInvoices;
  }, [props.invoices, filterState, props.legalEntityToId]);

  return (
    <FilterDrawerContextProvider defaultOpen={Boolean(false)}>
      <ObjectTable
        dataObjectList={filteredInvoices}
        sortBy={sortBy}
        sortChange={sortChange}
        mainColumnDefinition={MAIN_INVOICE_COLUMN_DEFINITION}
        nestedColumnDefinition={NESTED_PAIRING_COLUMN_DEFINITION}
        sourceLink={null}
        sourceLinks={null}
        nestedProperty={"pairings"}
        onCtrlP={props.onPick}
        onCtrlI={props.onSkip}
        onCtrlD={({ nestedObject, mainObject }) =>
          nestedObject && props.onDeletePairing(nestedObject)
        }
      />
      <FilterDrawer
        filterComponent={
          <TranasctionInvoicePairingFilter
            onChange={filterChange}
            filterState={filterState}
            isDisabled={false}
            showCardFilter={false}
          />
        }
        refetchComponent={
          <RefetchDataPanel
            buttonText="Reload data from server"
            timestamp={props.timestamp}
          />
        }
      />
      {filterState && (
        <BottomBar filterState={filterState} onFilterChange={filterChange} />
      )}
    </FilterDrawerContextProvider>
  );
}
