import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import { LoadingContext } from "./context/LoadingContext";
import { mirrorTransactions } from "@pivottable/common/utils";
import JSONStream from "JSONStream";
import request from "request";
import axios from "axios";

export const AppContext = React.createContext();

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const pollData = async (relativeUrl) => {
  const url = new URL(relativeUrl, window.location.origin);

  let resData = null;
  let unauthorized = false;
  const load = () => {
    const stream = request.get({ url }).pipe(JSONStream.parse());
    stream.on("data", (data) => {
      unauthorized = false;
      resData = data;
    });
    stream.on("error", (err) => {
      // eslint-disable-next-line no-console
      console.error("Data stream error", err);
      unauthorized = true;
    });
    return null;
  };

  await load();
  // eslint-disable-next-line no-unmodified-loop-condition
  while (resData == null) {
    if (unauthorized) {
      await axios.get(url);
    }
    // eslint-disable-next-line no-console
    console.log("waiting for data");
    await sleep(500);
  }
  return resData;
};

const getServerData = (forceRefetch = false, invoiceFetch = false) => {
  // 0 ms since 1st January 1970, same as (new Date(0)).getTime()
  // we want any data, no matter how old (better than waiting)
  const params = new URLSearchParams({
    clientTimestamp: forceRefetch ? Date.now() : 0,
    invoiceFetch: invoiceFetch ? 1 : 0,
  });
  return pollData(`/client/data?${params.toString()}`);
};

const remapServerData = (serverData, validationAfterReload) => {
  const result = serverData;
  const { gl = [], validation, entities, error, rates, ...rest } = result || {};
  if (error) return { error };
  return {
    gl: gl.concat(mirrorTransactions(gl.slice(1))),
    validation,
    rates,
    showValidation: validation && validationAfterReload,
    legalEntities: [...entities.sort((a, b) => a.localeCompare(b)), "unknown"],
    ...rest,
  };
};

export const DataLoader = ({ children }) => {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [loadedData, setLoadedData] = useState(null);
  const fetchData = useCallback(
    async (
      forceRefetch = false,
      validationAfterReload = true,
      invoiceFetch = false
    ) => {
      try {
        startLoading();
        const serverData = await getServerData(forceRefetch, invoiceFetch);
        const data = remapServerData(serverData, validationAfterReload);
        // we export loaded data so user can interact with it in console
        // this is not security issue because you can expose same data with
        // curl if you are authentificated
        window.loadedData = data;
        batchedUpdates(() => {
          setLoadedData(data);
          stopLoading();
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Data fetch failed", e);
        stopLoading();
      }
    },
    [startLoading, setLoadedData, stopLoading]
  );

  const forceRefetch = (validationAfterReload = true, invoiceFetch = false) =>
    fetchData(true, validationAfterReload, invoiceFetch);

  const hideValidation = () =>
    setLoadedData({ ...loadedData, showValidation: false });
  const showValidationFunc = () =>
    setLoadedData({ ...loadedData, showValidation: true });
  const fetchDataRef = useRef(fetchData);
  useEffect(() => {
    if (!window.pivotData) {
      fetchDataRef.current();
    } else {
      const data = window.pivotData;
      // eslint-disable-next-line react/no-did-mount-set-state
      setLoadedData({
        ...data,
      });
      window.pivotData = null;
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...loadedData,
        forceRefetch,
        hideValidation,
        showValidationFunc,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
