import React from "react";
import _ from "lodash";
import { makeStyles, TableCell, TableRow, Link } from "@material-ui/core";
import ValidationAccordion from "./ValidationAccordion";

const maxVisibleCount = 200;

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(0.5, 2),
    textAlign: "right",
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));

const DuplicatesTable = ({
  title,
  headCols,
  duplicates,
  getLink,
  defaultExpanded = true,
}) => {
  const styles = useStyles();

  const errorsCount = _.reduce(
    duplicates,
    (count, source) => count + source.length,
    0
  );

  const tableRows = _.reduce(
    duplicates,
    (resultRows, sourceDuplicates, sourceName) => {
      if (sourceDuplicates.length === 0) return resultRows;
      resultRows = resultRows.concat(
        sourceDuplicates.map((duplicatesRow) => {
          const [[identifier, linkData, duplicate], ...rest] = duplicatesRow;
          return (
            <React.Fragment key={duplicate}>
              <TableRow>
                <TableCell className={styles.cell} rowSpan={rest.length + 1}>
                  {sourceName}
                </TableCell>
                <TableCell className={styles.cell} rowSpan={rest.length + 1}>
                  {duplicate}
                </TableCell>
                <TableCell className={styles.cell}>{identifier}</TableCell>
                <TableCell className={styles.cell}>
                  <Link
                    href={getLink(sourceName, linkData)}
                    className={styles.link}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    link
                  </Link>
                </TableCell>
              </TableRow>
              {rest.map(([identifierN, linkDataN], i) => (
                <TableRow key={i}>
                  <TableCell className={styles.cell}>{identifierN}</TableCell>
                  <TableCell className={styles.cell}>
                    <Link
                      href={getLink(sourceName, linkDataN)}
                      className={styles.link}
                      target="_blank"
                      rel="noopener, noreferrer"
                    >
                      link
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          );
        })
      );
      return resultRows;
    },
    []
  );

  return (
    <ValidationAccordion
      tableRows={tableRows}
      errorsCount={errorsCount}
      visibleCount={Math.min(maxVisibleCount, errorsCount)}
      isTruncated={errorsCount > maxVisibleCount}
      colWidths={[20, 40, 20, 20]}
      {...{ defaultExpanded, title, headCols }}
    />
  );
};

const duplicateTableDefinitions = {
  uuid: {
    title: "Duplicate UUIDs",
    headCols: ["Source", "UUID", "Line number", "Line link"],
    defaultExpanded: false,
  },
  accountingId: {
    title: "Duplicate AccountingIds",
    headCols: ["Source", "AccountingId", "UUID Base", "Invoice link"],
    defaultExpanded: false,
  },
  centerKey: {
    title: "Duplicate Center Keys",
    headCols: ["Source", "Key", "Line number", "Line link"],
    defaultExpanded: false,
  },
};

const DuplicatesTables = ({ duplicates, getLink }) =>
  _.map(duplicates, (dupes, key) => {
    const props = duplicateTableDefinitions[key];
    const hasDuplicates =
      _.reduce(dupes, (acc, duplicates) => acc + duplicates.length, 0) > 0;
    if (!props || !hasDuplicates) return null;

    return (
      <DuplicatesTable
        key={key}
        {...props}
        duplicates={dupes}
        getLink={getLink}
      />
    );
  });

export default DuplicatesTables;
