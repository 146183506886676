import React, { useMemo, useState } from "react";
import ObjectTable from "../components/ObjectTable";
import { FilterDrawerContextProvider } from "../components/context/FilterDrawerContext";
import { TranasctionInvoicePairingFilter } from "../definitions/components";
import FilterDrawer from "../components/FilterDrawer";
import RefetchDataPanel from "../components/RefetchDataPanel";
import BottomBar from "../components/BottomBar";
import { parseNumberOrNull } from "../utils";
import * as format from "../definitions/format";
import { CommentTransaction } from "./CommentTransaction";
import { useMergeTransactionPairingCommentsIntoTransactions } from "./useMergeTransactionsInvoicesWithPairings";

export const MAIN_TRANSACTION_COLUMN_DEFINITION = [
  {
    key: "hasUnresolvedComments",
    minWidth: 30,
    format: (v) => (v ? "\u274C" : ""),
  },
  {
    key: "hasCommentsCount",
    minWidth: 40,
    format: (v) => (v > 0 ? `${v}\u{1F4AC}` : ""),
  },
  {
    key: "hasUnresolvedCommentsAsignedToMe",
    minWidth: 30,
    format: (v) => (v ? "\u{2753}" : ""),
  },
  {
    key: "is_matched_by_matching_rules",
    minWidth: 30,
    format: (v) => (v ? "\u2705" : ""),
  },
  { key: "date", format: format.date },
  { key: "settlement_date", format: format.date },
  { key: "entity", minWidth: 50 },
  { key: "bank_account" },
  { key: "type", minWidth: 50 },
  { key: "amountUnpairedEur", format: format.eur },
  { key: "amountEur", format: format.eur },
  { key: "amount", format: format.decimal },
  { key: "currency", minWidth: 50 },
  { key: "amount_original", format: format.decimal },
  { key: "currency_original", minWidth: 50 },
  { key: "card" },
  { key: "counterparty_name" },
  { key: "message" },
  //{ key: "reference" },//just for testing
  { key: "refProcessed" },
  { key: "additional_info" },
  { key: "counterparty_bank", minWidth: 50 },
  { key: "counterparty_account", minWidth: 50 },
  { key: "last_matched_at", format: format.date },
  { key: "last_matched_by" },
  { key: "uuid", minWidth: 150, format: (v) => `${v}` },
  //{ key: "normalizedCounterpartyName" },//just for testing
  //{ key: "account_type" },//not used
  //{ key: "counter_party_type" },//not used
  //{ key: "center_type" },//not used
];

const NESTED_PAIRING_COLUMN_DEFINITION = [
  { key: "invoice.url", format: (p) => (p === "" ? "" : p) },
  {
    key: "invoice.payroll",
    text: "payroll",
    format: (p) => (p ? "\u{1F4B8}" : ""),
  },
  {},
  {
    key: "matching_method",
    format: format.matchingMethod,
  },
  { key: "invoice.issue_date", text: "issue_date", format: format.date },
  { key: "invoice.due_date", text: "due_date", format: format.date },
  {
    key: "invoice.entity_id",
    text: "id",
  },
  { key: "po" },
  {
    key: "invoice.payment",
    text: "payment method",
    format: format.paymentMethod,
  },
  {
    key: "invoice.amountUnpairedEur",
    text: "amountUnpairedEur",
    format: format.eurOptional,
  },
  { key: "invoice.amountEur", text: "amountEur", format: format.eurOptional },
  { key: "invoice.amount", format: format.decimalOptional, text: "total" },
  { key: "invoice.currency", text: "currency" },

  { key: "amount", format: format.decimalOptional },
  { key: "currency" },

  { key: "invoice.po", text: "po" },
  { key: "invoice.counterparty", text: "counterparty" },
  { key: "invoice.variable_symbol", text: "vs" },
  { key: "invoice.accounted_id", text: "accounted id" },
  { key: "invoice.gl_account", text: "gl account" },
  { key: "account_to" },
  { key: "non_tax_expense" },
  { key: "center" },
  { key: "pid" },
  {
    key: "invoice.entity_id",
    text: "id",
  },
];

export function PickTransaction(props) {
  const [sortBy, sortChange] = useState(null);
  const [filterState, filterChange] = useState(props.initialFilter);

  const filteredTransactions = useMemo(() => {
    const filteredTransactions = props.transactions.filter((transaction) => {
      const matchBeforeTo =
        !filterState?.to || transaction.settlement_date < filterState?.to;

      const matchAfterFrom =
        !filterState?.from || transaction.settlement_date >= filterState?.from;

      const matchUnpared =
        !filterState?.unpaired || transaction.pairings.length === 0;

      const matchWhitelisted =
        !filterState?.whitelisted || transaction.whitelisted;

      const amountFromInt = parseNumberOrNull(filterState?.amountFrom);
      const matchAmountFrom =
        amountFromInt == null || transaction.amountUnpairedEur >= amountFromInt;

      const amountToInt = parseNumberOrNull(filterState?.amountTo);
      const matchAmountTo =
        amountToInt == null || transaction.amountUnpairedEur <= amountToInt;

      const matchCard =
        filterState?.card?.value == null ||
        filterState?.card?.value === "" ||
        (transaction?.card ?? "").includes(filterState?.card?.value);

      const matchEntity =
        !filterState?.entities ||
        filterState.entities == null ||
        filterState.entities.length === 0 ||
        filterState.entities.some(
          (legalEntity) =>
            props.legalEntityToId[legalEntity] === transaction.entity
        );

      return (
        matchWhitelisted &&
        matchBeforeTo &&
        matchAfterFrom &&
        matchUnpared &&
        matchAmountFrom &&
        matchAmountTo &&
        matchEntity &&
        matchCard
      );
    });
    return filteredTransactions;
  }, [props.transactions, filterState, props.legalEntityToId]);

  const [commentTransaction, setCommentTransaction] = useState(null);
  const filteredTransactionsWithComments =
    useMergeTransactionPairingCommentsIntoTransactions(
      filteredTransactions,
      props.transactionPairingComments
    );

  return (
    <FilterDrawerContextProvider defaultOpen={Boolean(false)}>
      <CommentTransaction
        commentTransaction={commentTransaction}
        setCommentTransaction={setCommentTransaction}
      />
      <ObjectTable
        dataObjectList={filteredTransactionsWithComments}
        sortBy={sortBy}
        sortChange={sortChange}
        mainColumnDefinition={MAIN_TRANSACTION_COLUMN_DEFINITION}
        nestedColumnDefinition={NESTED_PAIRING_COLUMN_DEFINITION}
        sourceLink={null}
        sourceLinks={null}
        nestedProperty={"pairings"}
        onCtrlP={props.onPick}
        onCtrlI={props.onSkip}
        onCtrlD={({ nestedObject, mainObject }) =>
          nestedObject && props.onDeletePairing(nestedObject)
        }
        onCtrlK={({ nestedObject, mainObject }) =>
          mainObject && setCommentTransaction(mainObject)
        }
      />
      <FilterDrawer
        filterComponent={
          <TranasctionInvoicePairingFilter
            onChange={filterChange}
            filterState={filterState}
            isDisabled={false}
            showCardFilter
          />
        }
        refetchComponent={
          <RefetchDataPanel
            buttonText="Reload data from server"
            timestamp={props.timestamp}
          />
        }
      />
      {filterState && (
        <BottomBar filterState={filterState} onFilterChange={filterChange} />
      )}
    </FilterDrawerContextProvider>
  );
}
