import React from "react";
import _ from "lodash/fp";
import memoize from "memoize-one";
import PropTypes from "prop-types";
import { headerStyle, color, iconFonts, nestedHeaderStyle } from "./style";
import GridCanvasWrapper from "./GridCanvasWrapper";
import { chainIcon, keyboardKey } from "../utils";

export default class ObjectTable extends React.Component {
  mousePos = null;
  cellArea = null;
  activeCell = null;

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  calculateColumnWidths = (mainColumnDefinition, nestedColumnDefinition) => {
    const columnLength = Math.max(
      mainColumnDefinition.length,
      nestedColumnDefinition.length
    );
    return Array(columnLength)
      .fill(0)
      .map((_, i) => {
        const minWidth1 = mainColumnDefinition[i]?.minWidth;
        const minWidth2 = nestedColumnDefinition[i]?.minWidth;
        // take bigger value of two optionally provided. If none is provided return 100
        return minWidth1 != null && minWidth2 != null
          ? Math.max(minWidth1, minWidth2)
          : minWidth1 ?? minWidth2 ?? 100;
      });
  };

  _tableData = memoize(
    (
      dataObjectList,
      sortCol,
      sortOrder,
      mainColumnDefinition = [],
      nestedColumnDefinition = [],
      nestedProperty
    ) => {
      const columnWidths = this.calculateColumnWidths(
        mainColumnDefinition,
        nestedColumnDefinition
      );

      const rowIndexToObjectIndex = [-1, -1]; //header index and nested header index do not point to anywhere
      const rowIndexToSubObjectIndex = [-1, -1]; //header index and nested header index do not point to anywhere
      let currentIndex = 2;

      const sortedObjectList =
        sortCol == null
          ? dataObjectList
          : _.orderBy([sortCol], [sortOrder], dataObjectList);

      sortedObjectList.forEach((dataObject, indexInObjectList) => {
        rowIndexToObjectIndex[currentIndex] = indexInObjectList;
        rowIndexToSubObjectIndex[currentIndex] = -1;
        currentIndex++;
        if (nestedProperty && Array.isArray(dataObject[nestedProperty])) {
          dataObject[nestedProperty].forEach(
            (_nestedObject, indexInNestedObjectList) => {
              rowIndexToObjectIndex[currentIndex] = indexInObjectList;
              rowIndexToSubObjectIndex[currentIndex] = indexInNestedObjectList;
              currentIndex++;
            }
          );
        }
      });

      return {
        dataObjectList: sortedObjectList,
        nestedProperty,
        rowIndexToObjectIndex,
        rowIndexToSubObjectIndex,
        mainColumnDefinition,
        nestedColumnDefinition,
        columnWidths,
        rowCount: currentIndex,
      };
    }
  );

  tableData = () => {
    const {
      dataObjectList,
      sortBy,
      mainColumnDefinition,
      nestedColumnDefinition,
      nestedProperty,
    } = this.props;
    const { col, order } = sortBy || { col: null, order: null };
    return this._tableData(
      dataObjectList,
      col,
      order,
      mainColumnDefinition,
      nestedColumnDefinition,
      nestedProperty
    );
  };

  getCell = (column, row) => {
    const { col, order } = this.props.sortBy || { col: null, order: null };
    let result = null; // [formatedValue, {...styles}, []],  actualValue]
    const {
      dataObjectList,
      nestedProperty,
      rowIndexToObjectIndex,
      rowIndexToSubObjectIndex,
      mainColumnDefinition,
      nestedColumnDefinition,
      /*links */
    } = this.tableData();
    if (row === 0) {
      result = [
        `${
          mainColumnDefinition[column]?.text ??
          mainColumnDefinition[column]?.key ??
          ""
        }`,
        _.set(
          "right",
          {
            icon:
              mainColumnDefinition[column]?.key === col
                ? order === "desc"
                  ? "\uf0dd"
                  : "\uf0de"
                : "\uf0dc",
            backgroundColor: _.isEqual(
              [[column, row], "right"],
              [this.mousePos, this.cellArea]
            )
              ? color.headerIconHoverBg
              : color.headerIconBg,
            font: iconFonts.regular,
          },
          headerStyle
        ),
        [],
        mainColumnDefinition[column]?.key,
      ];
      return result;
    } else if (row === 1) {
      result = [
        `${
          nestedColumnDefinition[column]?.text ??
          nestedColumnDefinition[column]?.key ??
          ""
        }`,
        nestedHeaderStyle,
        [],
        nestedColumnDefinition[column]?.key,
      ];
      return result;
    } else {
      // this can contain main object or nested object
      const rowObject =
        rowIndexToSubObjectIndex[row] === -1
          ? dataObjectList[rowIndexToObjectIndex[row]]
          : dataObjectList[rowIndexToObjectIndex[row]][nestedProperty][
              rowIndexToSubObjectIndex[row]
            ];
      let value;

      const defaultFormater = (v) => [v == null ? "" : `${v}`, {}];
      let columnKey;

      if (rowIndexToSubObjectIndex[row] === -1) {
        columnKey = mainColumnDefinition[column]?.key;

        const format = mainColumnDefinition[column]?.format ?? defaultFormater;
        value = _.get(columnKey, rowObject);
        let intermediateResult = format(value);
        // if we don't provide styling in format function just add it there
        if (!Array.isArray(intermediateResult))
          intermediateResult = [intermediateResult, {}];
        result = intermediateResult;
      } else {
        columnKey = nestedColumnDefinition[column]?.key;

        const formatNested =
          nestedColumnDefinition[column]?.format ?? defaultFormater;
        value = _.get(nestedColumnDefinition[column]?.key, rowObject);
        let intermediateResult = formatNested(value);
        // if we don't provide styling in format function just add it there
        if (!Array.isArray(intermediateResult))
          intermediateResult = [intermediateResult, {}];
        result = intermediateResult;
        // add color to nested rows if it has not been setted by formater
        if (!result[1].backgroundColor) {
          result[1] = { ...result[1], backgroundColor: color.nestedRows };
        }
      }

      if (
        columnKey === "url" ||
        (typeof columnKey === "string" && columnKey.endsWith("url"))
      ) {
        result[1] = _.merge(
          {
            right: {
              icon: chainIcon,
              backgroundColor: _.isEqual(
                [[column, row], "right"],
                [this.mousePos, this.cellArea]
              )
                ? color.iconBg
                : color.iconHoverBg,
              font: iconFonts.regular,
            },
          },
          result[1]
        );
      }
      result = [...result, [], value];
      return result;
    }
  };

  onClick = (e) => {
    if (!this.mousePos) return;
    const { mainColumnDefinition } = this.tableData();
    const [c, r] = this.mousePos;
    if (r === 0 && this.cellArea === "right") {
      const dataColumn = mainColumnDefinition[c]?.key;
      const { col, order } = this.props.sortBy || { col: null, order: null };
      if (col === dataColumn && order === "desc") {
        this.props.sortChange(null);
        return;
      }
      this.props.sortChange({
        col: dataColumn,
        order: col !== dataColumn ? "asc" : order === "asc" ? "desc" : "asc",
      });
    } else {
      const [value] = this.getCell(c, r);
      if (
        typeof value === "string" &&
        (value?.startsWith("http://") || value?.startsWith("https://")) &&
        this.cellArea === "right"
      ) {
        window.open(value, "_blank");
      }
    }
  };

  onDoubleClick = (e) => {
    if (this.activeCell == null) return;
    if (!this.props.onCtrlP) return;
    e.preventDefault();
    const row = this.activeCell[1];
    const { dataObjectList, rowIndexToObjectIndex } = this.tableData();
    if (rowIndexToObjectIndex[row] !== -1) {
      this.props.onCtrlP(dataObjectList[rowIndexToObjectIndex[row]]);
    }
    return;
  };

  onKeyDown = (e) => {
    const key = keyboardKey(e);
    if (key.isCtrlOrMeta && key.isP && this.props.onCtrlP) {
      if (this.activeCell == null) return;
      e.preventDefault();
      const row = this.activeCell[1];
      const { dataObjectList, rowIndexToObjectIndex } = this.tableData();
      if (rowIndexToObjectIndex[row] !== -1) {
        this.props.onCtrlP(dataObjectList[rowIndexToObjectIndex[row]]);
      }
      return;
    }
    if (key.isCtrlOrMeta && key.isI && this.props.onCtrlI) {
      e.preventDefault();
      this.props.onCtrlI();
      return;
    }
    if (this.activeCell !== null) {
      const row = this.activeCell[1];
      const {
        dataObjectList,
        rowIndexToObjectIndex,
        rowIndexToSubObjectIndex,
      } = this.tableData();
      if (rowIndexToObjectIndex[row] !== -1) {
        const mainObject = dataObjectList[rowIndexToObjectIndex[row]];
        const nestedObject =
          rowIndexToSubObjectIndex[row] !== -1
            ? mainObject[this.props.nestedProperty][
                rowIndexToSubObjectIndex[row]
              ]
            : null;

        if (key.isCtrlOrMeta && key.isD && this.props.onCtrlD) {
          this.props.onCtrlD({ nestedObject, mainObject });
          e.preventDefault();
        }
        if (key.isCtrlOrMeta && key.isK && this.props.onCtrlK) {
          this.props.onCtrlK({ nestedObject, mainObject });
          e.preventDefault();
        }
      }
    }
    return;
  };

  getSelectedObjects = () => {
    const { dataObjectList, rowIndexToObjectIndex, rowIndexToSubObjectIndex } =
      this.tableData();
    if (this.selection == null) return null;
    const selectedMainObjects = new Set();
    const selectedNestedObjects = new Set();
    const selectedMainObjectsIncludingParentsOfNetesd = new Set();

    this.selection.forEach(([x, y, xlength, ylength]) => {
      for (let i = 0; i < ylength; i++) {
        const rowIndex = y + i;
        if (rowIndexToObjectIndex[rowIndex] !== -1) {
          const mainObject = dataObjectList[rowIndexToObjectIndex[rowIndex]];
          if (rowIndexToSubObjectIndex[rowIndex] === -1) {
            // we are on main object
            selectedMainObjects.add(mainObject);
            selectedMainObjectsIncludingParentsOfNetesd.add(mainObject);
          } else {
            // we are on nested object
            selectedMainObjectsIncludingParentsOfNetesd.add(mainObject);
            const nestedObject =
              mainObject[this.props.nestedProperty][
                rowIndexToSubObjectIndex[rowIndex]
              ];
            selectedNestedObjects.add(nestedObject);
          }
        }
      }
    });
    return {
      mainObjects: [...selectedMainObjects],
      nestedObjects: [...selectedNestedObjects],
      mainObjectsIncludingParentsOfNetesd: [
        ...selectedMainObjectsIncludingParentsOfNetesd,
      ],
    };
  };

  render() {
    if (this.props.getSelectedObjectsRef) {
      this.props.getSelectedObjectsRef.current = this.getSelectedObjects;
    }
    const { rowCount, columnWidths } = this.tableData();
    return (
      <GridCanvasWrapper
        onClick={this.onClick}
        onDoubleClick={this.onDoubleClick}
        getCell={this.getCell}
        colWidths={columnWidths}
        rows={rowCount}
        cols={columnWidths.length}
        frozen={[0, 2]}
        onPaint={({ mousePos, cellArea, activeCell, selection }) => {
          this.mousePos = mousePos;
          this.cellArea = cellArea;
          this.activeCell = activeCell;
          this.selection = selection;
        }}
      />
    );
  }
}

ObjectTable.propTypes = {
  dataObjectList: PropTypes.arrayOf(PropTypes.object),
  nestedProperty: PropTypes.string,
  sortBy: PropTypes.shape({
    col: PropTypes.string,
    order: PropTypes.string,
  }),
  onCtrlP: PropTypes.func,
  onCtrlI: PropTypes.func,
  onCtrlD: PropTypes.func,
  onCtrlK: PropTypes.func,
  sortChange: PropTypes.func,
  mainColumnDefinition: PropTypes.array,
  nestedColumnDefinition: PropTypes.array,
};
