import React, { Component } from "react";

const DrawerContext = React.createContext();

class DrawerContextProvider extends Component {
  state = {
    isOpen: false,
  };

  toggleDrawer = () => {
    this.setState((prevState) => {
      return {
        isOpen: !prevState.isOpen,
      };
    });
  };

  openDrawer = () => {
    this.setState({
      isOpen: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    return (
      <DrawerContext.Provider
        value={{
          isOpen: this.state.isOpen,
          toggleDrawer: this.toggleDrawer,
          openDrawer: this.openDrawer,
          closeDrawer: this.closeDrawer,
        }}
      >
        {this.props.children}
      </DrawerContext.Provider>
    );
  }
}

export { DrawerContext, DrawerContextProvider };
