import $ from "@pivottable/common/gl";
import { $sa } from "@pivottable/common/sa";
import $utils from "@pivottable/common/utils";
import $c from "@pivottable/common/literals";
import { getSheetLink } from "./components/validation/utils";

export const chainIcon = "\uf0c1";
const errorSignIcon = "\uf071";
const beeLinkIcon = "\uf35d";

const linkToBeeTableRow = (rowIdentifier, sheetName, sourceLinks) => {
  if (!rowIdentifier || !sheetName || !sourceLinks) return null;
  return {
    icon: beeLinkIcon,
    link: getSheetLink(sourceLinks[sheetName], rowIdentifier),
  };
};

const isBeeRow = (row) => row[$.identifier] && row[$.identifier] !== "";

export const sourceLink = (row, sourceLinks = null) => {
  if (isBeeRow(row) && sourceLinks) {
    // if row is loaded from Bee, return link to correct row in Bee table
    return linkToBeeTableRow(row[$.identifier], row[$.sheetName], sourceLinks);
  }
  // for other cases prepare link to simplematics based on source and uuid (if exists)
  const simplematicsLink = $utils.simplematicsSourceLink(row, $);
  return {
    icon: simplematicsLink ? chainIcon : null,
    link: simplematicsLink,
  };
};

export const sourceLinkSA = (row) => {
  const simplematicsLink = row[$sa.internalSourceLink];
  if (!simplematicsLink) return null;
  return simplematicsLink === $c.simplematicsError
    ? {
        icon: errorSignIcon,
        link: "/pivot/validation",
      }
    : {
        icon: chainIcon,
        link: simplematicsLink,
      };
};

export const keyboardKey = (e) => ({
  isCtrlOrMeta: e.ctrlKey || e.metaKey,
  isShift: e.shiftKey,
  isAlt: e.altKey,
  isEscape: e.key === "Escape",
  isEnter: e.key === "Enter",
  isPageUp: e.key === "PageUp",
  isPageDown: e.key === "PageDown",
  isSlash: e.key === "/",
  isF1: e.key === "F1",
  isF3: e.key === "F3",
  isA: e.key === "A" || e.key === "a",
  isF: e.key === "F" || e.key === "f",
  isM: e.key === "M" || e.key === "m",
  isR: e.key === "R" || e.key === "r",
  isP: e.key === "P" || e.key === "p", // pick from table
  isI: e.key === "I" || e.key === "i", // ignore picking from table
  isD: e.key === "D" || e.key === "d", // delete pairing
  isK: e.key === "K" || e.key === "k", // comment transaction
  isSpace: e.key === " ",
});

export function parseNumberOrNull(value) {
  if (value == null) return null;
  if (value === "") return null;
  const number = Number(value);
  if (Number.isNaN(number)) return null;
  return number;
}
