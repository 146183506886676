import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Menu, MenuItem, TextField } from "@material-ui/core";
import { Link, push } from "./navigation";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { PivotMenuContext } from "./context/PivotMenuContext";
import { useHistory } from "react-router-dom";
import { topBarHeight, zIndexes } from "../styles/defaultTheme";
import { keyboardKey } from "../utils";
import { CellDetailContext } from "./context/CellDetailContext";

const noSelectedId = -1;

const useMenuStyles = makeStyles((theme) => ({
  menuControl: {
    position: "relative",
    padding: theme.spacing(1),
    display: "flex",
    borderRight: "1px solid",
    borderRightColor: theme.palette.grey.main,
    backgroundColor: theme.palette.background.default,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey.main,
    zIndex: zIndexes.pivotMenuControl,
    width: "300px",
    height: `${topBarHeight}px`,
    "&:hover": {
      cursor: "pointer",
    },
    userSelect: "none",
  },
  menuControlOpen: {
    borderRight: "none",
    "& $caret": {
      transform: "rotate(180deg)",
    },
  },
  menuControlDisabled: {
    pointerEvents: "none",
    "&:hover": {
      cursor: "initial",
    },
    "& $caret": {
      visibility: "hidden",
      opacity: 0,
    },
    "& $input": {
      "& .MuiInputBase-root": {
        "& .MuiInputBase-input": {
          "&.Mui-disabled": {
            color: theme.palette.grey.dark,
            // prevents default grey text in input on Safari when input is disabled
            "-webkit-text-fill-color": theme.palette.grey.dark,
          },
        },
      },
    },
  },
  menuControlText: {
    flex: 1,
  },
  caretWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  menu: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    zIndex: zIndexes.pivotMenu,
  },
  menuPopover: {
    top: `${topBarHeight}px`,
    borderRadius: 0,
    width: "300px",
    left: 0,
    maxHeight: "initial",
    height: "100%",
  },
  menuList: {
    padding: theme.spacing(1.5, 0),
  },
  logo: {
    width: "24px",
    height: "24px",
    marginRight: theme.spacing(1.5),
  },
  caret: {
    width: "20px",
    height: "20px",
  },
  menuItem: {
    transition: "none",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5, 2),
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    "&.Mui-focusVisible": {
      backgroundColor: theme.palette.background.default,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      },
    },
  },
  menuItemActive: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    "&.Mui-focusVisible": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      },
    },
  },
  input: {
    flex: 1,
    height: "24px",
    "& .MuiInputBase-root": {
      height: "100%",
      "&.Mui-disabled": {
        color: theme.palette.text.primary,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      height: "24px",
      padding: 0,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
      "&.Mui-disabled": {
        color: theme.palette.text.primary,
        // prevents default grey text in input on Safari when input is disabled
        "-webkit-text-fill-color": theme.palette.text.primary,
      },
    },
  },
  disabledInput: {
    pointerEvents: "none",
  },
}));

const moves = {
  ArrowUp: -1,
  ArrowDown: 1,
};

const compareFilteredPivots = (filterValue) => (a, b) => {
  const aLabel = a.label.toLowerCase();
  const bLabel = b.label.toLowerCase();
  const filterVal = filterValue.toLowerCase();
  // change order of filtered pivots, that pivots which start with filter value will be on the top
  if (aLabel.startsWith(filterVal) && !bLabel.startsWith(filterVal)) {
    return -1;
  } else if (!aLabel.startsWith(filterVal) && bLabel.startsWith(filterVal)) {
    return 1;
  }
  // if both pivots start on filter value or both don't start on it, their order won't change
  return 0;
};

const PivotMenu = ({ pivotLinks }) => {
  const styles = useMenuStyles();
  const { isOpen, setIsOpen, isSearchOpen } = useContext(PivotMenuContext);
  const [openerRef, setOpenerRef] = useState(null);
  const history = useHistory();
  const { isCellDetail } = useContext(CellDetailContext);

  const currentPivot =
    pivotLinks && pivotLinks.find((pivot) => pivot.isActive === true);

  const [inputRef, setInputRef] = useState(null);
  const [selectedId, setSelectedId] = useState(currentPivot.id);
  const [filterValue, setFilterValue] = useState(null);

  const filteredPivotLinks = !filterValue
    ? pivotLinks
    : pivotLinks
        .filter((pivot) =>
          pivot.label.toLowerCase().includes(filterValue.toLowerCase())
        )
        .sort(compareFilteredPivots(filterValue));

  // after every filter change, selected id is set to -1 => selected id will be set to:
  // id of first result (if filter value isn't empty)
  // id of current pivot (if filter value is empty)
  // if filter results are empty, nothing will be selected
  if (selectedId === noSelectedId && filteredPivotLinks.length > 0) {
    if (filterValue) setSelectedId(filteredPivotLinks[0].id);
    else setSelectedId(currentPivot.id);
  }

  const closeMenu = () => {
    setSelectedId(currentPivot.id);
    setFilterValue(null);
    setIsOpen(false);
  };

  const toggleMenu = () => {
    !isCellDetail && (isOpen ? closeMenu() : setIsOpen(true));
  };

  const onChange = (event) => {
    setFilterValue(event.target.value);
    setSelectedId(noSelectedId);
  };

  const onMenuOpened = () => {
    inputRef.focus();
  };

  const onKeyDown = (e) => {
    const key = keyboardKey(e);

    if (key.isCtrlOrMeta && key.isM && !isSearchOpen && !isCellDetail) {
      e.preventDefault();
      toggleMenu();
    } else if (isOpen && e.key in moves) {
      e.preventDefault();
      const selectedIdx = filteredPivotLinks.findIndex(
        (pivot) => pivot.id === selectedId
      );
      const newSelectedIdx = selectedIdx + moves[e.key];
      if (
        newSelectedIdx >= 0 &&
        newSelectedIdx <= filteredPivotLinks.length - 1
      ) {
        setSelectedId(filteredPivotLinks[newSelectedIdx].id);
      }
    } else if (isOpen && key.isEnter) {
      e.preventDefault();
      const redirectTo = filteredPivotLinks.find((p) => p.id === selectedId);
      if (redirectTo) {
        push(history, redirectTo.to);
        closeMenu();
      }
    } else if (isOpen && key.isEscape) {
      e.stopImmediatePropagation();
      e.preventDefault();
      closeMenu();
    }
  };

  const onClick = (event) => {
    closeMenu();
  };

  useEffect(() => {
    // enable handling keyboard events in the capture phase
    // make sure that PivotMenu gets first to events
    window.addEventListener("keydown", onKeyDown, true);

    return () => {
      window.removeEventListener("keydown", onKeyDown, true);
    };
  });

  return (
    <>
      <div
        className={clsx(
          styles.menuControl,
          isOpen && styles.menuControlOpen,
          isCellDetail && styles.menuControlDisabled
        )}
        ref={setOpenerRef}
        onClick={toggleMenu}
      >
        <img src="/assets/logo.svg" alt="" className={styles.logo} />
        <TextField
          inputRef={setInputRef}
          placeholder={currentPivot?.label}
          classes={{
            root: clsx(styles.input, !isOpen && styles.disabledInput),
          }}
          variant="outlined"
          disabled={!isOpen}
          value={isOpen ? filterValue || "" : currentPivot?.label}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
        />
        <div className={styles.caretWrapper}>
          <KeyboardArrowDownIcon className={styles.caret} />
        </div>
      </div>
      <Menu
        anchorEl={openerRef}
        keepMounted
        disableEnforceFocus
        autoFocus={false}
        open={isOpen}
        onClose={closeMenu}
        classes={{
          list: styles.menuList,
        }}
        PopoverClasses={{
          root: styles.menu,
          paper: styles.menuPopover,
        }}
        anchorPosition={{
          left: 0,
          top: topBarHeight,
        }}
        anchorReference="none"
        TransitionProps={{
          enter: false,
          exit: false,
          onEntered: onMenuOpened,
        }}
      >
        {filteredPivotLinks &&
          filteredPivotLinks.map((pivot, i) => (
            <MenuItem
              key={i}
              component={Link}
              to={pivot.to}
              onClick={onClick}
              onKeyDown={pivot.onKeyDown}
              className={clsx(
                styles.menuItem,
                pivot.id === selectedId && styles.menuItemActive
              )}
            >
              {pivot.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

PivotMenu.propTypes = {
  pivotLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      isActive: PropTypes.bool,
      to: PropTypes.string,
      onKeyDown: PropTypes.func,
    })
  ),
};

export default PivotMenu;
