import React, { useContext } from "react";
import _ from "lodash";
import clsx from "clsx";

import RuleViolationsTable from "./RuleViolationsTable";
import { getSheetLink, appendToObject } from "./utils";

import { Button, makeStyles, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import DuplicatesTables from "./DuplicatesTables";
import { zIndexes } from "../../styles/defaultTheme";
import { AppContext } from "../DataLoader";
import { useHistory, useLocation } from "react-router-dom";
import { push } from "../navigation";
import { tableUrlNames } from "../../definitions";
import InvoicesErrorsTable from "./InvoicesErrorsTables";
import { simplematicsLinkFromUuidAndSource } from "@pivottable/common/utils";

const getLinkFactory = (sourceLinks) => (source, linkData) => {
  if (source === "invoices") {
    const [uuid, sourceColumnValue] = linkData;
    return simplematicsLinkFromUuidAndSource(uuid, sourceColumnValue);
  }
  const linkWithoutLineData = sourceLinks[source] || "";
  return getSheetLink(linkWithoutLineData, linkData);
};

const getLines = (ruleViolations, source, getLink) =>
  _.reduce(
    ruleViolations,
    (result, linesData, rule) => {
      const ruleLines = linesData.map(([identifier, linkData]) => {
        return [source, identifier, getLink(source, linkData)];
      });
      result[rule] = ruleLines;
      return result;
    },
    {}
  );

const sortMergedLines = (mergedLines) =>
  Object.entries(mergedLines).sort(([key1], [key2]) =>
    key1.localeCompare(key2)
  );

const getErrorsAndWarnings = (sources, getLink) => {
  const mergedErrors = {};
  const mergedWarnings = {};
  _.forEach(sources, ({ errors, warnings }, source) => {
    const errorLines = getLines(errors, source, getLink);
    _.forEach(errorLines, (lines, rule) => {
      appendToObject(mergedErrors, rule, lines);
    });

    const warningLines = getLines(warnings, source, getLink);
    _.forEach(warningLines, (lines, rule) => {
      appendToObject(mergedWarnings, rule, lines);
    });
  });
  return {
    errorLines: sortMergedLines(mergedErrors),
    warningLines: sortMergedLines(mergedWarnings),
  };
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100%",
    zIndex: zIndexes.validationOverlay,
    backgroundColor: theme.palette.background.default,
  },
  innerWrapper: {
    height: "100%",
    // 'vw' accounts for the scrollbar width but '%' doesn't
    // so we're always offsetting the content by the difference
    marginLeft: "calc(100vw - 100%)",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1280px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(8, 5),
  },
  icon: {
    fontSize: "3.5rem",
    marginBottom: theme.spacing(1),
  },
  head: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "700px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  headTitle: {
    margin: 0,
  },
  sectionTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  headText: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    maxWidth: "500px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  headButtons: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(3),
    "& button + button": {
      marginLeft: theme.spacing(2),
    },
  },
  headButton: {
    backgroundColor: theme.palette.grey.main,
    padding: theme.spacing(1, 2),
  },
  primaryButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  content: {
    width: "100%",
  },
}));

const ValidationResultPage = () => {
  const { hideValidation, sourceLinks, validation, forceRefetch } =
    useContext(AppContext);
  const { duplicates, ...nonDuplicationProblems } = validation;
  const getLink = getLinkFactory(sourceLinks);
  const ruleLinesObj = getErrorsAndWarnings(nonDuplicationProblems, getLink);
  const { errorLines, warningLines } = ruleLinesObj;
  const errorTables = _.map(errorLines, ([rule, lines]) => (
    <RuleViolationsTable key={rule} lines={lines} rule={rule} />
  ));
  const warningTables = _.map(warningLines, ([rule, lines]) => (
    <RuleViolationsTable key={rule} lines={lines} rule={rule} />
  ));

  const styles = useStyles();
  const history = useHistory();
  const currentLocation = useLocation();

  const moveToPivot = () => {
    hideValidation();
    const { search } = currentLocation;
    const searchParams = new URLSearchParams(search);
    if (searchParams.has("next")) {
      push(history, searchParams.get("next"));
      return;
    }
    push(history, `/pivot${tableUrlNames.PLByMonth}`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.head}>
            <WarningIcon color="primary" className={styles.icon} />
            <Typography
              variant="h4"
              component="h1"
              className={styles.headTitle}
            >
              There are errors and/or warnings
            </Typography>
            <Typography className={styles.headText}>
              Fix the below described errors and/or warnings, save the
              corresponding sheets and reload data from server.
            </Typography>
            <div className={styles.headButtons}>
              <Button
                id="go-to-pivot-btn"
                onClick={moveToPivot}
                className={styles.headButton}
              >
                go to pivot
              </Button>
              <Button
                onClick={() => forceRefetch(true, false)}
                className={clsx(styles.headButton, styles.primaryButton)}
              >
                Reload data
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            <Typography
              variant="h5"
              component="h2"
              className={styles.sectionTitle}
            >
              Errors
            </Typography>
            <DuplicatesTables duplicates={duplicates} getLink={getLink} />
            {errorTables}
            <Typography
              variant="h5"
              component="h2"
              className={styles.sectionTitle}
            >
              Simplematics errors
            </Typography>
            <InvoicesErrorsTable />
            <Typography
              variant="h5"
              component="h2"
              className={styles.sectionTitle}
            >
              Warnings
            </Typography>
            {warningTables}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidationResultPage;
