import React, { forwardRef } from "react";
import { Link as RLink } from "react-router-dom";
import PropTypes from "prop-types";

export const Link = forwardRef((props, ref) => {
  const onClick = (event) => {
    const e = new Event("pagechange");
    window.dispatchEvent(e);
    props.onClick && props.onClick(event);
  };

  return <RLink ref={ref} {...props} onClick={onClick} />;
});

Link.propTypes = {
  to: PropTypes.string,
  style: PropTypes.object,
};

export const push = (history, path, state) => {
  const e = new Event("pagechange");
  window.dispatchEvent(e);
  history.push(path, state);
};
