import React from "react";
import _ from "lodash/fp";

export default class Scroll extends React.Component {
  state = {
    offset: _.getOr(null, ["state", "offset"], window.history),
    scrollTo: null,
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.saveScroll);
    window.addEventListener("pagechange", this.saveScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.saveScroll);
    window.removeEventListener("pagechange", this.saveScroll);
  }

  onScrollToChange = (scrollTo) => {
    this.setState({ scrollTo });
  };

  saveScroll = () => {
    window.history.replaceState(
      {
        ...window.history.state,
        offset: this.offset,
      },
      "Pivot Table"
    );
  };

  onScroll = (e) => {
    // save scroll position for use after revisiting page
    this.offset = [e.target.scrollLeft, e.target.scrollTop];
    // component is being scrolled, reset outdated values
    this.setState({ offset: null, scrollTo: null });
  };

  render() {
    return this.props.children({
      ...this.state,
      onScroll: this.onScroll,
      onScrollToChange: this.onScrollToChange,
    });
  }
}
