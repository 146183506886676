import "@fortawesome/fontawesome-free/css/solid.css";
import { colors } from "../../styles/defaultTheme";

export const cellHeight = 24;

export const style = {
  color: {
    grid: "#D0D9FA",
    frozenSeparator: "#7982A0",
    dashedGrid: "#D0D9FA",
    gridBg: colors.white,
    gridBorder: colors.white,
    defaultBg: colors.white,
    defaultText: "#252B41",
    selectionBorder: colors.white,
    selectionOverlay: "rgba(231, 32, 118, 0.2)",
    selectionOutline: colors.primary,
  },
  font: {
    normal: '13px "Archivo", Arial, Helvetica, sans-serif',
    mono: '13px "Archivo", "Courier New", Courier, monospace',
    icon: '14px "Font Awesome 5 Free"',
  },
  cellHeight,
  txtPadding: Math.round(0.2925 * cellHeight),
  // line widths
  width: {
    grid: 1,
    separator: 2,
    gridBorder: 0,
    frozen: 0,
    outline: 2,
    padding: 0,
    mark: 7,
  },
};
