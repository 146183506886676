import React from "react";

export const getSheetLink = (linkWithoutLineNumber, lineNumber) =>
  linkWithoutLineNumber
    ? linkWithoutLineNumber.replaceAll("{{lineNumber}}", lineNumber)
    : null;

export const Link = ({ link }) => (link ? <a href={link}>link</a> : "");

export const appendToObject = (obj, key, values) => {
  if (!obj[key]) obj[key] = [];
  obj[key] = obj[key].concat(values);
};
