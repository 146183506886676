import $ from "@pivottable/common/gl";
import $i from "@pivottable/common/glIdentity";
import $card from "@pivottable/common/cards";
import { isRecordAllowed } from "@pivottable/common/access";
import {
  BSStructure,
  BSSubtypeToType,
  Subtypes,
} from "../balanceSheetStructure";
import _ from "lodash";
import { format as formatDate, isAfter, getDaysInMonth } from "date-fns";
import memoize from "memoize-one";
import * as source from "./source";

import * as accountTypes from "@pivottable/common/accounts";
import bc from "@pivottable/common/budget";
import $c from "@pivottable/common/literals";
import { $sa } from "@pivottable/common/sa";
import { arr2str } from "../pivot";
import { departmentsMapping } from "@pivottable/common/departments";
import { findAvgRatesForMonth } from "@pivottable/common/forexUtils";

const currenciesCols = ["EUR", "CZK", "HUF", "GBP", "USD", "ADA"];

const dateBetween = (date, from = "1900", to = "2100") => {
  return date >= from && date <= to;
};

const budgetType2Names = {
  "flexible payroll": "payroll",
  payroll: "payroll",
  overheads: "overheads",
  "flexible overheads": "overheads",
};

// [set, value]
// Keep those rows, where state.[set] contains value, unless set is empty. If
// set is empty, keep all rows.
const matchSets = (rules) => {
  return rules
    .map(([set, key]) => {
      set = new Set(set);
      if (set.size === 0) return null; // set is empty, keep all records
      return source.filter((r) => set.has(key(r)));
    })
    .filter((f) => f != null);
};

const filterNonTaxTransactions = ([titles, ...rows]) => {
  // Uuids of the form {$uuid}#tax
  const nonTaxUuids = new Set(
    _.flatten(
      rows
        .map((row) => row[$.uuid].match(/^(.*)#tax$/))
        .filter((match) => match != null)
        .map((match) => [match[1], `${match[1]}#base`])
    )
  );

  const nonTaxTransactions = rows.filter((row) => nonTaxUuids.has(row[$.uuid]));

  return [titles, ...nonTaxTransactions];
};

const skipTitles = (records, filterFn) =>
  [records[0]].concat(records.slice(1).filter(filterFn));

export const filterWithTeamPriviledges = (gl, allowedHierarchy) => {
  const hierarchyIndexToGlIndex = [
    $.domain,
    $.unit,
    $.department,
    $.category,
    $.center,
  ];
  const filteredGl = skipTitles(gl, (r) =>
    isRecordAllowed(r, hierarchyIndexToGlIndex, allowedHierarchy)
  );
  return filteredGl;
};

export const filterWithCardPriviledges = (
  gl,
  allowedHierarchy,
  cards = [],
  jiraId = null
) => {
  if (allowedHierarchy === true) return gl;
  const skipTitles = (records, filterFn) =>
    [records[0]].concat(records.slice(1).filter(filterFn));
  const usersCards = new Set(
    cards.reduce((acc, card) => {
      if (card[$card.owner] === jiraId) acc.push(card[$card.id]);
      return acc;
    }, [])
  );
  const filteredGl = skipTitles(
    gl,
    (r) =>
      _.startsWith(r[$.description], "card#") &&
      usersCards.has(r[$.description].split("#")[1])
  );
  return filteredGl;
};

const getUnitsSet = memoize((units) => new Set(units));

const getUnitToDepartmentsMap = memoize((departments) =>
  _.flow(
    _.partialRight(_.groupBy, ({ unit }) => unit),
    _.partialRight(_.mapValues, (val) =>
      _.groupBy(val, ({ department }) => department)
    )
  )(departments)
);

const isRowInSelectedUnitsDepartments = (
  units,
  departments,
  rowUnit,
  rowDepartment
) => {
  const unitsSet = getUnitsSet(units);
  const unitToDepartmentsMap = getUnitToDepartmentsMap(departments);
  if (unitsSet.size && !unitsSet.has(rowUnit)) return false;
  if (
    unitToDepartmentsMap[rowUnit] &&
    !_.get(unitToDepartmentsMap, [rowUnit, rowDepartment])
  )
    return false;
  return true;
};

export const transformCounterpartiesBalancesSource =
  (monthlyForex) =>
  ({ from, to } = {}) => {
    const rates = findAvgRatesForMonth(
      monthlyForex,
      (to || formatDate(new Date())).substring(0, 7)
    );
    return [
      from && source.filter((r) => r[$.date] >= from),
      to && source.filter((r) => r[$.date] <= to),
      source.filter((r) => accountTypes.BALSmall.has(r[$.to])),
      source.columns((titles) => [...titles, "aggregation"]),
      source.map((r) => [
        ...r,
        [r[$.amtCur], rates[r[$.cur]] ? r[$.amtCur] / rates[r[$.cur]] : 0],
      ]),
    ];
  };

export const transformBalanceSource =
  (monthlyForex) =>
  ({ to, reportingEntities, legalEntities } = {}) =>
  (data) => {
    const reportingEntitiesSet = new Set(reportingEntities);
    const legalEntitiesSet = new Set(legalEntities);
    const [titles, ...records] = data;
    const res = [
      [
        ...titles,
        "subCurrency",
        "accountType",
        "accountSubtype",
        "aggregation",
      ],
    ];
    const rates = findAvgRatesForMonth(
      monthlyForex,
      (to || formatDate(new Date())).substring(0, 7)
    );
    records.forEach((r) => {
      if (to && to < r[$.date]) return;
      if (
        reportingEntitiesSet.size &&
        !reportingEntitiesSet.has(r[$.reportingEntity])
      )
        return;
      if (legalEntitiesSet.size && !legalEntitiesSet.has(r[$.entity])) return;
      const accountSubtype = (function () {
        for (const subtype in BSStructure) {
          if (
            BSStructure[subtype].accounts.includes(r[$.to]) ||
            (BSStructure[subtype].prefix &&
              r[$.to].startsWith(BSStructure[subtype].prefix))
          ) {
            return subtype;
          }
        }
        return Subtypes.nullables;
      })();
      const relativePrice = rates[r[$.cur]] ? r[$.amtCur] / rates[r[$.cur]] : 0;
      const agg = [r[$.amtCur], relativePrice];
      const accountType = BSSubtypeToType[accountSubtype];
      if (currenciesCols.includes(r[$.cur])) {
        res.push([...r, "", accountType, accountSubtype, agg]);
      } else {
        res.push(
          _.set(
            [...r, r[$.cur], accountType, accountSubtype, agg],
            $.cur,
            "Other"
          )
        );
      }
    });
    return res;
  };

export const transformPLSource =
  (allowedHierarchy) =>
  ({
    from,
    to = from,
    months = [],
    accounts,
    units,
    departments,
    entities,
    reportingEntities,
    glAccount,
  } = {}) => {
    const selectedMonths = months.includes("YTD")
      ? new Set([
          ...months,
          ..._.range(0, new Date().getMonth() + 1).map(
            (m) =>
              `${formatDate(new Date(new Date().getFullYear(), m), "YYYY-MM")}`
          ),
        ])
      : new Set(months);
    return [
      (data) => filterWithTeamPriviledges(data, allowedHierarchy),
      source.filter((r) => accountTypes.PL.has(r[$.from])),
      ...matchSets([
        [accounts, (r) => r[$.from]],
        [entities, (r) => r[$.entity]],
        [reportingEntities, (r) => r[$.reportingEntity]],
      ]),
      source.filter(
        (r) =>
          selectedMonths.size === 0 ||
          selectedMonths.has(`${r[$.year]}-${r[$.month]}`) ||
          selectedMonths.has(`${r[$.year]}`)
      ),
      source.filter((r) => dateBetween(r[$.date], from, to && `${to}-31`)),
      source.filter((r) =>
        isRowInSelectedUnitsDepartments(
          units,
          departments,
          r[$.unit],
          r[$.department]
        )
      ),
      source.filter((r) => {
        if (!glAccount || glAccount.length === 0) {
          return true;
        }

        const rowAccountValue = r[$.account] ?? "";

        if (glAccount.some((acc) => acc.value === "notEmpty")) {
          return rowAccountValue.length > 0;
        } else if (glAccount.some((acc) => acc.value === "empty")) {
          return rowAccountValue.length === 0;
        }

        return glAccount.some((acc) => {
          const rowAccountValues = rowAccountValue
            .split(",")
            .map((accVal) => accVal.trim());
          return rowAccountValues.some((v) => v.startsWith(acc.value));
        });
      }),
    ];
  };

export const transformNonTaxTransactionsSource = ({
  months = [],
  accounts,
  entities,
} = {}) => {
  const selectedMonths = months.includes("YTD")
    ? new Set([
        ...months,
        ..._.range(0, new Date().getMonth() + 1).map(
          (m) =>
            `${formatDate(new Date(new Date().getFullYear(), m), "YYYY-MM")}`
        ),
      ])
    : new Set(months);

  return [
    filterNonTaxTransactions,
    source.filter((r) => accountTypes.PLNonTax.has(r[$.from])),
    ...matchSets([
      [accounts, (r) => r[$.from]],
      [entities, (r) => r[$.entity]],
    ]),
    source.filter(
      (r) =>
        selectedMonths.size === 0 ||
        selectedMonths.has(`${r[$.year]}-${r[$.month]}`) ||
        selectedMonths.has(`${r[$.year]}`)
    ),
  ];
};

export const transformBudgetSource =
  (allowedHierarchy) =>
  ({ year, accounts = [], units = [], departments = [] }, budget) =>
  (data) => {
    data = filterWithTeamPriviledges(data, allowedHierarchy);
    budget = budget.slice(1);
    const [titles, ...records] = data;
    const definedBudgets = {};
    const res = [[...titles, "type", "employee", "sourceType", "budgetType"]];
    const hierarchy = [
      $i.domain,
      $i.unit,
      $i.department,
      $i.category,
      $i.center,
    ];
    // 'basic' flexible types
    const flexibleTypes = ["flexible"];
    // flexible types for specified expense types e.g flexible payroll only for expenses/payroll
    const flexibleTypesWithExpType = ["flexible payroll", "flexible overheads"];

    const isRowForAccounts = (
      budgetType,
      selectPayroll,
      selectOverheads,
      selectAll
    ) => {
      if (selectAll) return true;
      return (
        (selectPayroll && budgetType.endsWith($c.payroll)) ||
        (selectOverheads && budgetType.endsWith($c.overheads))
      );
    };

    const selectPayroll = accounts.some((a) => a.endsWith($c.payroll));
    const selectOverheads = accounts.some((a) => a.endsWith($c.overheads));
    const selectAll =
      accounts.length === 0 ||
      accounts.some(
        (a) => !a.endsWith($c.payroll) && !a.endsWith($c.overheads)
      );

    budget.forEach((r) => {
      if (
        !isRowInSelectedUnitsDepartments(
          units,
          departments,
          r[bc.unit],
          r[bc.department]
        )
      )
        return;
      if (
        !isRowForAccounts(r[bc.type], selectPayroll, selectOverheads, selectAll)
      )
        return;
      if (year !== String(r[bc.date])) return;
      const rec = new Array(res[0].length).fill("");
      rec[$.year] = year;
      rec[$.from] = "initial";
      rec[$.amtEur] = r[bc.amtEur];
      rec[$.amtCur] = r[bc.amtEur];
      rec[$.cur] = "EUR";
      rec[$.appx2] = $c.budget;
      rec[$.appx3] = !r[bc.type]
        ? $c.budget
        : budgetType2Names[r[bc.type]] || "";
      // find first level of the hierarchy whose value isn't specified and set it to 'type' value,
      // if they are all specified, 'type' value will be stored in type column
      // only for overheads / payroll / flexible overheads / flexible payroll budgets
      const emptyLevel = hierarchy.findIndex(
        (key) => !(rec[$[key]] = r[bc[key]])
      );
      if (!flexibleTypes.includes(r[bc.type])) {
        rec[emptyLevel >= 0 ? $[hierarchy[emptyLevel]] : $.appx0] = r[bc.type];
      }
      // for all budgets set flag 'budget' which enables to hide this
      // records & avoid empty lines on last level of grouping
      if (r[bc.type]) {
        // if budget type isn't empty set flag into:
        // column 'employee' if hierarchy doesn't have empty level
        // column 'type' if empty level is on last level of hierarchy
        // column on first level after empty level otherwise
        rec[
          emptyLevel >= 0 && emptyLevel < hierarchy.length - 1
            ? $[hierarchy[emptyLevel + 1]]
            : emptyLevel === hierarchy.length - 1
            ? $.appx0
            : $.appx1
        ] = $c.budget;
      } else {
        // if budget type is empty set flag into:
        // column 'type' if hierarchy doesn't have empty level
        // column on empty level otherwise
        rec[emptyLevel >= 0 ? $[hierarchy[emptyLevel]] : $.appx0] = $c.budget;
      }

      const budget = arr2str([
        r[bc.type],
        ...(emptyLevel < 0 ? hierarchy : hierarchy.slice(0, emptyLevel)).map(
          (key) => r[bc[key]]
        ),
      ]);
      let budgetPeriod = String(r[bc.date]);

      if (r[bc.period] === "YEAR") {
        // if budget is for whole year, amount will be divided between 12 budgets,
        // each of them for one specific month of year
        const amtForMonth = r[bc.amtEur] / 12;
        rec[$.amtEur] = amtForMonth;
        rec[$.amtCur] = amtForMonth;
        _.range(0, 12).forEach((month) => {
          const paddedMonth = `${month + 1}`.padStart(2, "0");
          res.push(
            _.merge([...rec], {
              [$.month]: paddedMonth,
              [$.date]: `${year}-${paddedMonth}-01`,
            })
          );
        });
      } else {
        rec[$.amtEur] = r[bc.amtEur];
        rec[$.amtCur] = r[bc.amtEur];
        const month = `${`${r[bc.period]}`.padStart(2, "0")}`;
        rec[$.month] = month;
        rec[$.date] = `${year}-${month}-01`;
        budgetPeriod = `${budgetPeriod}-${month}`;
        res.push(rec);
      }

      if (!definedBudgets[budgetPeriod]) {
        definedBudgets[budgetPeriod] = new Set();
      }
      definedBudgets[budgetPeriod].add(budget);
    });

    records.forEach((r) => {
      if (
        !isRowInSelectedUnitsDepartments(
          units,
          departments,
          r[$.unit],
          r[$.department]
        )
      )
        return;
      if (
        !r[$.date] ||
        !r[$.date].startsWith(year) ||
        !r[$.from] ||
        (accounts && accounts.length > 0 && !new Set(accounts).has(r[$.from]))
      )
        return;
      const t = r[$.from].split("/");
      if (t[0] !== "expenses" || !accountTypes.PL.has(r[$.from])) return;
      const expType = t[1] === $c.payroll || t[1] === $c.overheads ? t[1] : "";
      let flexExpType = false;

      const budgetsForYear =
        definedBudgets[formatDate(new Date(r[$.date]), "YYYY")] || [];
      const budgetsForMonth =
        definedBudgets[formatDate(new Date(r[$.date]), "YYYY-MM")] || [];
      const budgets = new Set([...budgetsForYear, ...budgetsForMonth]);

      const budgetMatchLen = _.reduce(
        hierarchy,
        ([matchLen, arr], key) => {
          arr.push(r[$[key]]);
          if (budgets.has(arr2str([expType, ...arr]))) {
            matchLen = arr.length;
            // if match with budgeted value is founded on some level of hierarchy, flexType have to
            // be marked as false (otherwise this match would be incorrectly considered as flexible
            // in case that flexible match was founded on any of higher levels of hierarchy)
            flexExpType = false;
            return [matchLen, arr];
          }
          // expense belongs to 'basic' type of flexible budget if belongs to cost center of this budget
          if (
            flexibleTypes.some((type) => budgets.has(arr2str([type, ...arr])))
          ) {
            matchLen = arr.length;
            flexExpType = false;
          }
          // expense belongs to flexible budget if belongs to cost center of this budget and
          // type of expense is same as specified type for budget
          // e.g. only payroll expenses can belong to flexible payroll
          if (
            flexibleTypesWithExpType.some(
              (type) =>
                budgets.has(arr2str([type, ...arr])) &&
                type.split(" ")[1] === expType
            )
          ) {
            matchLen = arr.length;
            flexExpType = true;
          }
          return [matchLen, arr];
        },
        [-1, []]
      )[0];
      const rec = [...r, ""];
      const budgeted = budgetMatchLen >= 0;

      if (expType || flexExpType) {
        if (budgeted) {
          hierarchy.slice(budgetMatchLen).forEach((key) => {
            rec[$[key]] = "";
          });
          // set employee value for payroll and overheads expenses matching with budget
          const employee = ((defaultValue) => {
            if (r[$.pid] !== "") {
              return r[$.pid];
            } else if (r[$.uuid].startsWith("ovrh")) {
              return r[$.centerKey];
            }
            return defaultValue;
          })("#NA");

          // employee value will be set into
          // column 'employee' if budget match length is same as hierarchy length
          // column 'type' is budget match length is 1 less than hierarchy length
          // otherwise, first column after hierarchy level on which budget match ends
          rec[
            budgetMatchLen === hierarchy.length
              ? $.appx1
              : budgetMatchLen === hierarchy.length - 1
              ? $.appx0
              : $[hierarchy[budgetMatchLen + 1]]
          ] = employee;

          rec[
            budgetMatchLen === hierarchy.length
              ? $.appx0
              : $[hierarchy[budgetMatchLen]]
          ] = flexExpType && expType ? `flexible ${expType}` : expType;
        }
      }

      rec[$.appx2] = $c.spent;
      rec[$.appx3] = $c.spent;
      res.push(_.set(rec, $.from, budgeted ? "bdg" : "off"));
    });
    return res;
  };

export const transformPayrollSource =
  ({ from, to = from, type } = {}, budget, departments) =>
  (data) => {
    const [titles, ...records] = data;
    let res = [[...titles, "type", "value", "Department", "only hours"]];
    const expenses = [
      "expenses/payroll",
      "expenses/overheads",
      "expenses/equity",
    ];
    departments = departments.sort((a, b) => (a[2] > b[2] ? -1 : 1));
    records.forEach((r) => {
      if (
        !dateBetween(r[$.date], from, to && `${to}-31`) ||
        !expenses.includes(r[$.from]) ||
        (r[$.from] === expenses[1] && r[$.uuid].startsWith("ovrh"))
      )
        return;
      let department = departments.find((d) => {
        const jiraID = d[0];
        const dateFrom = d[2];
        return `e:${jiraID}` === r[$.po] && dateFrom <= r[$.date];
      });
      department = department
        ? departmentsMapping[department[1]] || department[1]
        : "unknown";
      if (r[$.from] === expenses[0]) {
        res.push([...r, $c.payroll, r[$.amtEur], department, false]);
        res.push([...r, $c.hours, r[$.hours], department, false]);
      } else if (r[$.from] === expenses[1]) {
        res.push([...r, $c.overheads, r[$.amtEur], department, false]);
      } else if (r[$.from] === expenses[2]) {
        res.push([...r, $c.equity, r[$.amtEur], department, false]);
      }
    });
    if (type)
      res = [res[0]].concat(res.slice(1).filter((r) => r[$.appx0] === type));
    if (type === $c.hours) {
      res = [res[0]].concat(
        res.slice(1).map((r) => {
          r[$.appx3] = true;
          return r;
        })
      );
    }
    return res;
  };

const monthsFilter = (months) => {
  const fMonths = new Set(months);
  if (fMonths.has("YTD")) {
    fMonths.delete("YTD");
    const now = new Date();
    _.range(0, now.getMonth() + 1).map((i) =>
      fMonths.add(`${now.getFullYear()}-${`${i + 1}`.padStart(2, "0")}`)
    );
  }
  if (fMonths.size === 0) return (r) => true;
  return (r) =>
    fMonths.has(`${r[$.year]}`) || fMonths.has(`${r[$.year]}-${r[$.month]}`);
};

export const transformCardholderSource =
  (allowedHierarchy, cards, jiraId) =>
  ({ months = [] }) =>
    [
      (data) =>
        filterWithCardPriviledges(data, allowedHierarchy, cards, jiraId),
      source.filter(monthsFilter(months)),
      source.filter((r) => _.isString(r[$.to]) && r[$.to].startsWith("cash/")),
      source.filter(
        (r) =>
          _.isString(r[$.description]) && r[$.description].startsWith("card#")
      ),
      (data) => {
        const [titles, ...records] = data;
        const res = [[...titles, "Cardholder", "Card"]];
        // maps card to its cardholder, also remove titles row
        const cMap = _.fromPairs(cards.slice(1));
        records.forEach((r) => {
          const c = r[$.description].split("#")[1];
          if (cMap[c]) res.push([...r, cMap[c], c]);
          else res.push([...r, "N/A", "N/A"]);
        });
        return res;
      },
    ];

export const transformStatutoryAccountingSource =
  ({ entities, period, sources }, __, ___, saData) =>
  (data) => {
    const [titles, ...records] = saData;
    const filteredRecords = records
      .filter((r) => {
        const selectedEntities = new Set(entities);
        if (selectedEntities.size === 0) return true;
        return selectedEntities.has(r[$sa.legalEntity]);
      })
      .filter((r) => {
        if (!period) return true;
        const today = new Date();
        // because we want to calculate end of month states on accounts (not only sum
        // of transactions in month), we filter all transactions in months until the end
        // of the selected period, after calculation (in pivot transform function)
        // months columns which don't belong to selected period will be removed

        // values in filter select can be only 'YTD' or years e.g. '2020'
        const toDate =
          period === "YTD"
            ? `${today.getFullYear()}-${today.getMonth() + 1}-${getDaysInMonth(
                today
              )}`
            : `${period}-12-31`;
        return !isAfter(r[$sa.date], toDate);
      })
      .filter((r) => {
        const selectedSources = new Set(sources);
        if (selectedSources.size === 0) return true;
        return selectedSources.has(r[$sa.source]);
      });
    const res = [[...titles]];
    filteredRecords.forEach((r) => {
      const amount = r[$sa.amtEur];
      const amountCur = r[$sa.amtCur];
      let mdTransaction = _.set([...r], $sa.account, r[$sa.from]);
      mdTransaction = _.set(mdTransaction, $sa.amtEur, -amount);
      mdTransaction = _.set(mdTransaction, $sa.amtCur, -amountCur);
      res.push(mdTransaction);
      res.push(_.set([...r], $sa.account, r[$sa.to]));
    });
    return res;
  };

export const transformEmployeeOverheadsSource =
  ({ from, to = from } = {}, budget, departments) =>
  (data) => {
    const [titles, ...records] = data;
    const res = [[...titles, "Department"]];
    departments = departments.sort((a, b) => (a[2] > b[2] ? -1 : 1));
    records.forEach((r) => {
      if (
        !dateBetween(r[$.date], from, to && `${to}-31`) ||
        r[$.from] !== "incomes/overheads" ||
        r[$.to] !== "overheads"
      )
        return;
      let department = departments.find((d) => {
        const jiraID = d[0];
        const dateFrom = d[2];
        return (
          (`e:${jiraID}` === r[$.po] || jiraID === r[$.pid]) &&
          dateFrom <= r[$.date]
        );
      });
      if (!department) return;
      department = departmentsMapping[department[1]] || department[1];
      res.push([...r, department]);
    });
    return res;
  };

export const reVsLeClaimsSource = ({
  months = [],
  accounts,
  entities,
} = {}) => {
  const selectedMonths = months.includes("YTD")
    ? new Set([
        ...months,
        ..._.range(0, new Date().getMonth() + 1).map(
          (m) =>
            `${formatDate(new Date(new Date().getFullYear(), m), "YYYY-MM")}`
        ),
      ])
    : new Set(months);
  return [
    source.filter((r) => accountTypes.PL.has(r[$.from])),
    ...matchSets([
      [accounts, (r) => r[$.from]],
      [entities, (r) => r[$.entity]],
    ]),
    source.filter(
      (r) =>
        selectedMonths.size === 0 ||
        selectedMonths.has(`${r[$.year]}-${r[$.month]}`) ||
        selectedMonths.has(`${r[$.year]}`)
    ),
  ];
};

export const employeeLiability =
  (employeeActiveUntil) =>
  ({ to } = {}) =>
  (data) => {
    const [baseTitles, ...allRecords] = data;
    const titles = [...baseTitles, "aggregation", "jiraId", "active/liability"];
    const records = [];
    for (const r of allRecords) {
      if (r[$.to] !== "liabilities") continue;
      if (to && r[$.date] > to) continue;
      if (!r[$.po] || !r[$.po].startsWith("e:")) continue;
      const jiraId = r[$.po].slice(2);
      records.push([...r, [r[$.amtCur], r[$.amtEur]], jiraId]);
    }
    const eurSumByJiraId = _.mapValues(_.groupBy(records, $.appx1), (arr) =>
      _.sumBy(arr, (r) => r[$.amtEur])
    );
    for (const r of records) {
      const isActiveOrHasLiability =
        employeeActiveUntil[r[$.appx1]] > to ||
        Math.abs(eurSumByJiraId[r[$.appx1]]) > 0.1;
      r.push(
        isActiveOrHasLiability
          ? "active or liability"
          : "inactive and no liability"
      );
    }
    return [titles, ...records];
  };
