import React, { useContext, useEffect, useState } from "react";
import Select, { components } from "react-select";
import {
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { colors } from "../styles/defaultTheme";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CellDetailContext } from "./context/CellDetailContext";

const useLabelStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.grey.dark,
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
  },
}));

const Label = ({ label }) => {
  const styles = useLabelStyles();

  return (
    <Typography variant="body2" className={styles.text}>
      {label}
    </Typography>
  );
};

const dropdownIconStyles = {
  color: colors.greyDark,
  width: "20px",
  height: "20px",
};

const clearIconStyles = {
  color: colors.error,
  width: "18px",
  height: "18px",
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <KeyboardArrowDownIcon style={{ ...dropdownIconStyles }} />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CloseIcon style={{ ...clearIconStyles }} />
    </components.DropdownIndicator>
  );
};

const useSelectStyles = makeStyles((theme) => ({
  wrapper: {
    "& + &": {
      marginTop: theme.spacing(2),
    },
  },
}));

const selectInnerStyles = (theme) => ({
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided, { isFocused }) => ({
    ...provided,
    borderColor: isFocused ? colors.greyDark : colors.grey,
    boxShadow: "none",
    transition: "none",
    minHeight: "44px",
    padding: theme.spacing(1),
    "&:hover": {
      borderColor: colors.greyDark,
      cursor: "pointer",
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(0.5),
    "& > div": {
      padding: 0,
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    color: colors.greyDark,
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    color: isSelected ? colors.white : colors.text,
    backgroundColor: isSelected
      ? colors.secondary
      : isFocused
      ? colors.greyButton
      : colors.white,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    "&:hover": {
      backgroundColor: isSelected ? colors.secondary : colors.greyButton,
      color: isSelected ? colors.white : colors.text,
      cursor: "pointer",
    },
  }),
  dropdownIndicator: (provided, state) => {
    const { menuIsOpen } = state.selectProps;

    return {
      ...provided,
      transform: menuIsOpen ? "rotate(180deg)" : "",
      color: colors.greyDark,
    };
  },
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: colors.grey,
  }),
});

const StyledSelect = ({ label, ...restProps }) => {
  const theme = useTheme();
  const styles = useSelectStyles();
  const { isCellDetail } = useContext(CellDetailContext);

  return (
    <div className={styles.wrapper}>
      <Label {...{ label }} />
      <Select
        {...restProps}
        // hands over focus after selecting an option
        blurInputOnSelect
        tabSelectsValue={false}
        styles={selectInnerStyles(theme)}
        components={{ DropdownIndicator, ClearIndicator }}
        onKeyDown={(e) => e.stopPropagation()}
        isDisabled={isCellDetail}
      />
    </div>
  );
};

const useCheckboxStyles = makeStyles((theme) => ({
  controlRoot: {
    margin: 0,
    display: "flex",
    alignItems: "flex-start",
    "& + &": {
      marginTop: theme.spacing(1),
    },
    "&:first-of-type": {
      marginTop: theme.spacing(2),
    },
  },
  controlLabel: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },
  checkboxRoot: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.grey.main,
    width: "20px",
    height: "20px",
  },
}));

const StyledCheckbox = ({ label, checked, onChange }) => {
  const styles = useCheckboxStyles();
  const { isCellDetail } = useContext(CellDetailContext);

  return (
    <FormControlLabel
      classes={{
        root: styles.controlRoot,
        label: styles.controlLabel,
      }}
      control={
        <Checkbox
          color="primary"
          classes={{ root: styles.checkboxRoot }}
          disabled={isCellDetail}
          {...{ checked, onChange }}
        />
      }
      label={label}
    />
  );
};

const useDatepickerStyles = makeStyles((theme) => ({
  wrapper: {
    "& .react-datepicker-wrapper": {
      display: "block",
      width: "100%",
    },
    "& .react-datepicker__close-icon": {
      padding: 0,
      marginRight: theme.spacing(1),
      "&::after": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.error.main,
        padding: 0,
        // close icon size
        fontSize: "18px",
      },
    },
  },
  input: {
    display: "block",
    width: "100%",
    border: "1px solid",
    borderColor: theme.palette.grey.main,
    borderRadius: "4px",
    padding: theme.spacing(1),
    boxShadow: "none",
    outline: "none",
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    "&:focus": {
      boxShadow: "none",
      outline: "none",
      borderColor: theme.palette.grey.dark,
    },
  },
  calendarPopper: {
    "&.react-datepicker-popper .react-datepicker__triangle": {
      borderBottomColor: theme.palette.grey.light,
      "&::before": {
        borderBottomColor: theme.palette.grey.main,
      },
    },
  },
  calendar: {
    borderColor: theme.palette.grey.main,
    "& .react-datepicker__header": {
      backgroundColor: theme.palette.grey.light,
      borderBottomColor: theme.palette.grey.main,
      paddingTop: theme.spacing(1.25),
      "& .react-datepicker__current-month": {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        fontWeight: 500,
      },
    },
    "& .react-datepicker__day-names": {
      marginTop: theme.spacing(0.5),
    },
    "& .react-datepicker__day": {
      "&:hover": {
        backgroundColor: theme.palette.grey.light,
      },
      "&--selected, &--keyboard-selected": {
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    "& .react-datepicker__navigation": {
      top: theme.spacing(1.5),
      "&:focus": {
        outline: "none",
        boxShadow: "none",
      },
      "&--next": {
        borderLeftColor: theme.palette.grey.main,
      },
      "&--previous": {
        borderRightColor: theme.palette.grey.main,
      },
    },
  },
  pickerWrapper: {
    display: "flex",
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.grey.button,
    minWidth: "initial",
    marginLeft: theme.spacing(1),
  },
  buttonLabel: {
    fontSize: "0.8em",
    lineHeight: "0.8",
  },
}));

const StyledDatepicker = ({
  label,
  buttonLabel,
  buttonClick,
  Button,
  ...restProps
}) => {
  const dateStyles = useDatepickerStyles();
  const selectStyles = useSelectStyles();
  const { isCellDetail } = useContext(CellDetailContext);

  return (
    <div className={clsx(dateStyles.wrapper, selectStyles.wrapper)}>
      <Label {...{ label }} />
      <div className={dateStyles.pickerWrapper}>
        <DatePicker
          {...restProps}
          className={dateStyles.input}
          popperClassName={dateStyles.calendarPopper}
          popperPlacement="bottom-end"
          calendarClassName={dateStyles.calendar}
          dateFormat="MMM dd, yyyy"
          onKeyDown={(e) => e.stopPropagation()}
          disabled={isCellDetail}
        />
        {Button}
      </div>
    </div>
  );
};

const DatepickerButton = ({ label, onClick }) => {
  const styles = useButtonStyles();
  const { isCellDetail } = useContext(CellDetailContext);

  return (
    <Button className={styles.button} onClick={onClick} disabled={isCellDetail}>
      <Typography className={styles.buttonLabel}>{label}</Typography>
    </Button>
  );
};

const StyledInputDebounced = ({ label, value, onChange }) => {
  const [previousValue, setPreviousValue] = useState(value);
  const [localValue, setLocalValue] = useState(value);
  // after 0.5 seconds we send value to parrent if local is different than current
  useEffect(() => {
    if (previousValue !== localValue) {
      const t = setTimeout(() => {
        onChange(localValue);
      }, 500);
      return () => clearTimeout(t);
    }
    return undefined;
  }, [localValue, previousValue, onChange]);

  // we need this if if value is changed from outside we need to change local value
  if (previousValue !== value) {
    setPreviousValue(value);
    setLocalValue(value);
  }

  return (
    <div>
      {label}:
      <input
        onKeyDown={(e) => e.stopPropagation()}
        value={localValue ?? ""}
        onChange={(e) => setLocalValue(e.target.value)}
        onBlur={() => onChange(localValue)}
      />
      <button onClick={() => onChange(null)}>X</button>
    </div>
  );
};

export {
  StyledInputDebounced,
  StyledSelect,
  StyledCheckbox,
  StyledDatepicker,
  DatepickerButton,
};
