import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button, Drawer, makeStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import {
  topBarHeight,
  filterWidth,
  bottomBarHeight,
} from "../styles/defaultTheme";
import { keyboardKey } from "../utils";
import { FilterDrawerContext } from "./context/FilterDrawerContext";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: `${filterWidth}px`,
    height: `${bottomBarHeight}px`,
  },
  drawer: {
    top: `${topBarHeight}px`,
    width: `${filterWidth}px`,
    backgroundColor: theme.palette.background.default,
    borderLeft: "1px solid",
    borderLeftColor: theme.palette.grey.main,
    display: "flex",
    flexDirection: "column",
    height: `calc(100% - ${topBarHeight}px)`,
    overflow: "visible",
    userSelect: "none",
  },
  drawerInner: {
    flex: 1,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  refetchWrapper: {
    marginTop: "auto",
    padding: theme.spacing(2),
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey.main,
  },
  closeWrapper: {
    height: `${bottomBarHeight}px`,
    borderTop: "1px solid",
    borderTopColor: theme.palette.grey.main,
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  buttonBase: {
    borderRadius: 0,
    display: "block",
    width: "100%",
    height: "100%",
    textTransform: "none",
    padding: theme.spacing(1, 2),
  },
  button: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.grey.dark,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.grey.dark,
    },
    "& $buttonLabel": {
      justifyContent: "flex-end",
    },
    "& svg": {
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(0.75),
      width: "20px",
      height: "20px",
    },
  },
  contrastButton: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.default,
    },
    "& $buttonLabel": {
      flexDirection: "row-reverse",
    },
  },
  buttonLabel: {
    display: "flex",
    alignItems: "center",
  },
}));

const ControlButton = ({ label, onClick, iconComponent, contrastVariant }) => {
  const styles = useButtonStyles();

  return (
    <Button
      aria-label={label}
      onClick={onClick}
      className={clsx(
        styles.buttonBase,
        contrastVariant ? styles.contrastButton : styles.button
      )}
      classes={{ label: styles.buttonLabel }}
    >
      {label}
      {iconComponent}
    </Button>
  );
};

const FilterDrawer = ({ filterComponent, refetchComponent }) => {
  const styles = useStyles();
  const { isFilterOpen, setFilterOpen, toggleFilter } =
    useContext(FilterDrawerContext);

  const onKeyDown = (e) => {
    const key = keyboardKey(e);

    if (key.isCtrlOrMeta && key.isSlash) {
      e.preventDefault();
      toggleFilter();
    } else if (isFilterOpen && key.isEscape) {
      e.stopImmediatePropagation();
      e.preventDefault();
      setFilterOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  });

  return (
    <div className={styles.wrapper}>
      <Drawer
        anchor="right"
        open={isFilterOpen}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        classes={{
          paper: styles.drawer,
        }}
      >
        <div className={styles.refetchWrapper}>{refetchComponent}</div>
        <div className={styles.drawerInner}>{filterComponent}</div>
        <div className={styles.closeWrapper}>
          <ControlButton
            label="Close filter"
            onClick={() => setFilterOpen(false)}
            iconComponent={<CloseIcon />}
          />
        </div>
      </Drawer>
      <ControlButton
        contrastVariant
        label="Open filter"
        onClick={() => setFilterOpen(true)}
        iconComponent={<ChevronLeftIcon />}
      />
    </div>
  );
};

ControlButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  iconComponent: PropTypes.node,
  contrastVariant: PropTypes.bool,
};

FilterDrawer.propTypes = {
  filterComponent: PropTypes.node,
  refetchComponent: PropTypes.node,
};

export default FilterDrawer;
