import React, { useContext } from "react";
import { AppContext } from "../DataLoader";
import { Link, makeStyles, TableCell, TableRow } from "@material-ui/core";
import ValidationAccordion from "./ValidationAccordion";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(0.5, 2),
    textAlign: "right",
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));

const groupInvoicesErrors = (invoicesErrors) => {
  return _.reduce(
    invoicesErrors,
    (acc, error) => {
      const errorType = error.message.startsWith("Unknown entity")
        ? "unknown entity"
        : error.message.startsWith("XXX base")
        ? "XXX base can not be 0"
        : error.message;
      if (!acc[errorType]) {
        acc[errorType] = [];
      }
      acc[errorType].push(error);
      return acc;
    },
    {}
  );
};

const InvoicesErrorsTables = () => {
  const { invoicesErrors } = useContext(AppContext);
  const groupedInvoicesErrors = groupInvoicesErrors(invoicesErrors);

  return _.keys(groupedInvoicesErrors).map((errorType) => {
    return (
      <InvoicesErrorsTable
        key={errorType}
        rule={errorType}
        errors={groupedInvoicesErrors[errorType]}
      />
    );
  });
};

const InvoicesErrorsTable = ({ errors, rule }) => {
  const styles = useStyles();

  const tableRows = errors
    .map((error, i) => {
      return (
        <TableRow key={i}>
          <TableCell className={styles.cell}>{error.name}</TableCell>
          <TableCell className={styles.cell}>
            <Link
              href={error.link}
              className={styles.link}
              target="_blank"
              rel="noopener, noreferrer"
            >
              link
            </Link>
          </TableCell>
        </TableRow>
      );
    })
    .slice(0, 200);

  return (
    <ValidationAccordion
      title={rule}
      headCols={["Row / Id", "Invoice link"]}
      colWidths={[20, 20]}
      tableRows={tableRows}
      errorsCount={errors.length}
      visibleCount={tableRows.length}
      isTruncated={errors.length > tableRows.length}
    />
  );
};

export default InvoicesErrorsTables;
