import React, { useState, useCallback, useContext, useEffect } from "react";
import FullScreenLoader from "../FullScreenLoader";
import { parseMatchingRulesTable } from "../../pairingComponents/matchingRules";
import { pollData } from "../DataLoader";

export const PairingDataContext = React.createContext();

export const PairingDataContextProvider = (props) => {
  const [pairingData, setPairingData] = useState(null);

  // we expose pairing data so user can interact with it in console
  useEffect(() => {
    window.pairingData = pairingData;
  }, [pairingData]);

  const updateCachedData = useCallback(
    (keyToUpdate, updateFn) => {
      setPairingData((pairingData) => {
        const oldData = pairingData?.[keyToUpdate];
        if (!oldData) {
          return pairingData; // well no data no update
        } else {
          return {
            ...pairingData,
            [keyToUpdate]: updateFn(oldData),
          };
        }
      });
      return;
    },
    [setPairingData]
  );

  const deleteExistingPairingFromAlreadyFetchedData = useCallback(
    (pairing) => {
      updateCachedData("transactionInvoicePairing", (oldPairings) =>
        oldPairings.filter((p) => p.uuid !== pairing.uuid)
      );
    },
    [updateCachedData]
  );

  const addNewPairingsToAlreadyFetchedData = useCallback(
    (newPairing) => {
      const paringArray = Array.isArray(newPairing) ? newPairing : [newPairing];
      updateCachedData("transactionInvoicePairing", (oldPairings) => [
        ...oldPairings,
        ...paringArray,
      ]);
    },
    [updateCachedData]
  );

  const addNewTransactionPairingCommentToAlreadyFetchedData = useCallback(
    (newTransactionPairingComments) => {
      updateCachedData(
        "transactionPairingComments",
        (oldTransactionPairingComments) => {
          // well if we have some new pairings that we already have we need to delete them before we are adding them
          const newUuid = new Set(
            newTransactionPairingComments.map((comment) => comment.uuid)
          );
          const oldValidPairings = oldTransactionPairingComments.filter(
            (comment) => !newUuid.has(comment.uuid)
          );
          return [...oldValidPairings, ...newTransactionPairingComments];
        }
      );
    },
    [updateCachedData]
  );

  return (
    <PairingDataContext.Provider
      value={{
        pairingData,
        setPairingData,
        addNewPairingsToAlreadyFetchedData,
        deleteExistingPairingFromAlreadyFetchedData,
        addNewTransactionPairingCommentToAlreadyFetchedData,
      }}
    >
      {props.children}
    </PairingDataContext.Provider>
  );
};

export const PairngDataDataLoader = ({ data, children }) => {
  const { pairingData, setPairingData } = useContext(PairingDataContext);

  useEffect(() => {
    if (!data) return; // we have to wait to load main pairing data
    if (!pairingData) {
      // we will load only if we are missing pairing data otherwise do nothing
      const fetchData = async () => {
        const result = await pollData(`/client/pairing`);
        if (result.matchingRulesTable) {
          result.matchingRules = parseMatchingRulesTable(
            result.matchingRulesTable
          );
        }
        setPairingData(result);
      };
      fetchData();
    }
  }, [data, pairingData, setPairingData]);

  if (!data) {
    return <div>Global data not loaded yet</div>;
  }
  if (!pairingData?.invoices)
    return <FullScreenLoader message="Loading pairing data" forceShow />;

  return children;
};
