import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Link, Button } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { zIndexes } from "../styles/defaultTheme";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: zIndexes.errorOverlay,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    fontSize: "1.5rem",
  },
  icon: {
    fontSize: "3.5rem",
  },
  errorCodeWrapper: {
    margin: theme.spacing(3),
    backgroundColor: theme.palette.grey.light,
    padding: theme.spacing(3, 4),
  },
  pre: {
    margin: 0,
    width: "100%",
    display: "block",
    whiteSpace: "break-spaces",
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "500px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(2),
  },
  infoText: {
    textAlign: "center",
    fontSize: theme.typography.body1.fontSize,
    lineHeight: "1.5rem",
  },
  refetchButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  reloadWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    display: "inline-block",
    marginLeft: theme.spacing(0.5),
  },
  homeLink: {
    display: "block",
    marginTop: theme.spacing(1),
    textAlign: "center",
    textDecoration: "underline",
  },
}));

export const FullScreenError = ({ error, forceRefetch }) => {
  const styles = useStyles();
  const { response: errorMessage, originalError } = error;

  return (
    <div className={styles.wrapper}>
      <WarningIcon color="primary" className={styles.icon} />
      {errorMessage && (
        <Typography className={styles.errorMessage}>{errorMessage}</Typography>
      )}
      <div className={styles.errorCodeWrapper}>
        {originalError.stack ? (
          <pre className={styles.pre}>{originalError.stack}</pre>
        ) : (
          originalError.message && (
            <pre className={styles.pre}>{originalError.message}</pre>
          )
        )}
      </div>
      <div className={styles.infoWrapper}>
        {/* GSheets API rate limiting resets after 100 seconds. */}
        <div className={styles.reloadWrapper}>
          <Typography>You can try to reload the data.</Typography>
          <Button
            className={styles.refetchButton}
            onClick={() => {
              forceRefetch(true, false);
            }}
          >
            Reload data
          </Button>
        </div>
        <Typography className={styles.infoText}>
          If this error persists for more than 2-3 minutes, please post a
          screenshot of it to our slack channel
          <Link
            className={styles.link}
            href="https://app.slack.com/client/T026LE24D/C03JKBLUWCQ"
            target="_blank"
            rel="noopener, noreferrer"
          >
            #internal-tools-finance-users
          </Link>
        </Typography>
      </div>
    </div>
  );
};

FullScreenError.propTypes = {
  error: PropTypes.shape({
    response: PropTypes.string,
    originalError: PropTypes.shape({
      message: PropTypes.string,
      stack: PropTypes.string,
    }),
  }),
  forceRefetch: PropTypes.func,
};

export const PreFetchFullScreenError = ({ message, showHomeLink = false }) => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <WarningIcon color="primary" className={styles.icon} />
      <Typography className={styles.errorMessage}>{message}</Typography>
      {showHomeLink && (
        <Link href={"/"} className={styles.homeLink}>
          Return home
        </Link>
      )}
    </div>
  );
};

PreFetchFullScreenError.propTypes = {
  message: PropTypes.string,
  showHomeLink: PropTypes.bool,
};
