var _slicedToArray = require("/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");
var _ = require("lodash");
var closestMonthlyFactory = function closestMonthlyFactory(monthlyForex) {
  // maps currency to first month we have rates for it
  var curStart = {};
  for (var _i = 0, _Object$entries = Object.entries(monthlyForex); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
      cur = _Object$entries$_i[0],
      monthToRate = _Object$entries$_i[1];
    var allMonths = Object.keys(monthToRate);
    curStart[cur] = _.min(allMonths);
  }
  return function (currency, month) {
    var tolerance = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Infinity;
    var currencyMonthly = monthlyForex[currency];
    if (!currencyMonthly) throw new Error("Unknown curency ".concat(currency));
    var res = currencyMonthly[month];
    for (var i = 0; !res && i < tolerance && month > curStart[currency]; i++) {
      var _month$split$map = month.split("-").map(Number),
        _month$split$map2 = _slicedToArray(_month$split$map, 2),
        y = _month$split$map2[0],
        m = _month$split$map2[1];
      var _ref = m === 1 ? [y - 1, 12] : [y, m - 1];
      var _ref2 = _slicedToArray(_ref, 2);
      y = _ref2[0];
      m = _ref2[1];
      month = "".concat(y, "-").concat("".concat(m).padStart(2, "0"));
      res = currencyMonthly[month];
    }
    if (!res) throw new Error("No rate for ".concat(currency, " in ").concat(month));
    return res;
  };
};
var findAvgRatesForMonth = function findAvgRatesForMonth(monthlyForex, month) {
  var res = {};
  var closestRate = closestMonthlyFactory(monthlyForex);
  for (var _i2 = 0, _Object$entries2 = Object.entries(monthlyForex); _i2 < _Object$entries2.length; _i2++) {
    var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 1),
      cur = _Object$entries2$_i[0];
    try {
      res[cur] = closestRate(cur, month).avgRate;
    } catch (e) {
      // continue
    }
  }
  return res;
};
module.exports = {
  closestMonthlyFactory: closestMonthlyFactory,
  findAvgRatesForMonth: findAvgRatesForMonth
};