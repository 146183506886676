import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import TablePage from "./pages/TablePage";
import { TransactionInvoicePairingPage } from "./pages/TransactionInvoicePairingPage";
import ShortcutsDrawer from "./components/ShortcutsDrawer";
import memoize from "memoize-one";
import _ from "lodash";
import definitions, { tableUrlNames } from "./definitions";
import { LoadingProvider } from "./components/context/LoadingContext";
import FullScreenLoader from "./components/FullScreenLoader";
import { FullScreenError } from "./components/FullScreenError";
import { ThemeProvider } from "@material-ui/core";
import { defaultTheme } from "./styles/defaultTheme";
import { DrawerContextProvider } from "./components/context/DrawerContext";
import { RefContextProvider } from "./components/context/RefContext";
import { PivotMenuContextProvider } from "./components/context/PivotMenuContext";
import ValidationResultPage from "./components/validation/ValidationResultPage";
import { keyboardKey } from "./utils";
import { DataLoader, AppContext } from "./components/DataLoader";
import { PairingDataContextProvider } from "./components/context/PairingDataContext";

const hasValidationIssues = (validation) => {
  // eslint-disable-next-line no-unused-vars
  const { duplicates, ...sources } = validation;
  // sources are empty if they have no errors/warnings, same goes for duplicates
  return (
    _.some(sources, (source) => _.some(source, (type) => !_.isEmpty(type))) ||
    _.some(duplicates, (type) => _.some(type, (source) => !_.isEmpty(source)))
  );
};

class App extends React.Component {
  pivotDefinitions = memoize((...args) => definitions(...args));

  componentDidMount = () => {
    window.addEventListener("keydown", this.onKeyDown);
  };

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.onKeyDown);
  };

  onKeyDown = (e) => {
    const key = keyboardKey(e);

    if (key.isAlt && key.isR) {
      e.preventDefault();
      this.props.forceRefetch(false, false);
    }
  };

  render = () => {
    const {
      validation,
      showValidation,
      rates,
      error,
      cards,
      jiraId,
      allowedHierarchy,
      monthlyForex,
      employeeActiveUntil,
    } = this.props;

    if (error) {
      console.error(error); //eslint-disable-line no-console
      return (
        <ThemeProvider theme={defaultTheme}>
          <FullScreenError
            error={error}
            forceRefetch={this.props.forceRefetch}
          />
        </ThemeProvider>
      );
    }
    const defaultPivotUrl = _.isEqual({}, allowedHierarchy)
      ? tableUrlNames.cardholder
      : tableUrlNames.PLByMonth;
    const definitions = this.pivotDefinitions(
      rates,
      cards,
      allowedHierarchy,
      jiraId,
      monthlyForex,
      employeeActiveUntil
    );
    return (
      <div className="App">
        <ThemeProvider theme={defaultTheme}>
          <FullScreenLoader />
          <Router>
            <Switch>
              <Route
                name="validation"
                path="/pivot/validation"
                render={(props) => {
                  return validation && <ValidationResultPage />;
                }}
              />
              <Route
                name="pairingFirstTransactionThenInvoice"
                path={`/pivot/${tableUrlNames.pairingFirstTransactionThenInvoice}`}
                render={(props) => {
                  return (
                    <TransactionInvoicePairingPage
                      key="firstTransaction"
                      definitions={definitions}
                      tableUrlName={
                        tableUrlNames.pairingFirstTransactionThenInvoice
                      }
                    />
                  );
                }}
              />
              <Route
                name="pairingFirstInvoiceThanTransaction"
                path={`/pivot/${tableUrlNames.pairingFirstInvoiceThanTransaction}`}
                render={(props) => {
                  return (
                    <TransactionInvoicePairingPage
                      key="firstInvoice"
                      definitions={definitions}
                      tableUrlName={
                        tableUrlNames.pairingFirstInvoiceThanTransaction
                      }
                    />
                  );
                }}
              />
              <Route
                name="pairingMatchingRules"
                path={`/pivot/${tableUrlNames.pairingTransationWithMatchingRules}`}
                render={(props) => {
                  return (
                    <TransactionInvoicePairingPage
                      key="pairWithMatchingRules"
                      definitions={definitions}
                      tableUrlName={
                        tableUrlNames.pairingTransationWithMatchingRules
                      }
                    />
                  );
                }}
              />
              <Route
                name="pairingMatchingInvoices"
                path={`/pivot/${tableUrlNames.pairingTransationWithMatchingInvoices}`}
                render={(props) => {
                  return (
                    <TransactionInvoicePairingPage
                      key="pairingTransationWithMatchingInvoices"
                      definitions={definitions}
                      tableUrlName={
                        tableUrlNames.pairingTransationWithMatchingInvoices
                      }
                    />
                  );
                }}
              />
              <Route
                name="pivot"
                path="/pivot/:tableUrlName"
                render={(props) => {
                  const { pathname, search } = props.location;
                  if (showValidation && hasValidationIssues(validation)) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/pivot/validation",
                          search: `?${new URLSearchParams({
                            next: `${pathname}${search}`,
                          })}`,
                        }}
                      />
                    );
                  }
                  return (
                    <TablePage
                      {...props}
                      {...{
                        rates,
                        cards,
                      }}
                      definitions={definitions}
                      tableUrlName={props.match.params.tableUrlName}
                    />
                  );
                }}
              />
              {allowedHierarchy && (
                <Redirect from="/" to={`/pivot/${defaultPivotUrl}`} />
              )}
            </Switch>
          </Router>
          <ShortcutsDrawer />
        </ThemeProvider>
      </div>
    );
  };
}

const AppContent = () => {
  const {
    forceRefetch,
    validation,
    showValidation,
    rates,
    error,
    cards,
    jiraId,
    allowedHierarchy,
    monthlyForex,
    employeeActiveUntil,
  } = useContext(AppContext);
  return (
    <App
      {...{
        forceRefetch,
        validation,
        showValidation,
        rates,
        error,
        cards,
        jiraId,
        allowedHierarchy,
        monthlyForex,
        employeeActiveUntil,
      }}
    />
  );
};

export default () => (
  <PairingDataContextProvider>
    <PivotMenuContextProvider>
      <RefContextProvider>
        <DrawerContextProvider>
          <LoadingProvider>
            <DataLoader>
              <AppContent />
            </DataLoader>
          </LoadingProvider>
        </DrawerContextProvider>
      </RefContextProvider>
    </PivotMenuContextProvider>
  </PairingDataContextProvider>
);
