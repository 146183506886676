import React, { useReducer, useContext, useMemo } from "react";
import {
  reducer,
  getInitialState,
  useExecuteSubmit,
  STEP,
  ACTION_TYPE,
  deletePairingFromServer,
} from "./reducer";
import { PickedOverview } from "./PickedOverview";
import { PickTransaction } from "./PickTransaction";
import { PickInvoice } from "./PickInvoice";
import { FillDetails } from "./FillDetails";
import { SubmitError } from "./Submit";
import { createPairingForTransactionWithMatchingRules } from "./matchingRules";
import { PairingDataContext } from "../components/context/PairingDataContext";
import { tableUrlNames } from "../definitions";

export function MainStateComponent(props) {
  const firstTableToShow =
    props.tableUrlName === tableUrlNames.pairingFirstTransactionThenInvoice
      ? "transaction"
      : "invoice";

  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(firstTableToShow)
  );
  useExecuteSubmit(state, dispatch);

  const { deleteExistingPairingFromAlreadyFetchedData } =
    useContext(PairingDataContext);

  const onDeletePairing = async (pairing) => {
    // eslint-disable-next-line no-alert
    const confirmFromUser = window.confirm(
      `Do you really want to delete pairing:
uuid: ${pairing.uuid}
account_from: ${pairing.account_from} 
account_to: ${pairing.account_to} 
amount: ${pairing.amount} 
currency: ${pairing.currency}
transaction_uuid: ${pairing.transaction_uuid}
invoice_entity: ${pairing.invoice_enity}
invoice_id: ${pairing.invoice_id}
`
    );
    // if user says no we do nothing
    if (!confirmFromUser) return;
    try {
      const result = await deletePairingFromServer(pairing);
      if (!result.ok) {
        // eslint-disable-next-line no-alert
        window.alert(`Failed to delete pairing due to ${result.error}`);
      } else {
        deleteExistingPairingFromAlreadyFetchedData(pairing);
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert(`Failed to delete pairing due to ${e.message}`);
    }
  };

  const entityIdToLegalEntity = useMemo(() => {
    return Object.fromEntries(
      Object.entries(props.legalEntityToId).map(([name, id]) => [id, name])
    );
  }, [props.legalEntityToId]);

  const PickTransactionComponent = (
    <PickTransaction
      {...props}
      transactions={props.transactions}
      initialFilter={state.initialTransactionsFilter}
      onPick={(t) => {
        dispatch({
          type: ACTION_TYPE.PICK_TRANSACTION,
          transaction: t,
          generatedPairing: createPairingForTransactionWithMatchingRules(
            t,
            props.matchingRules
          ),
          entityIdToLegalEntity,
        });
      }}
      onSkip={() => {
        // we can't skip picking in first table
        if (state.step === STEP.FIRST_TABLE) return;
        dispatch({ type: ACTION_TYPE.SKIP_TRANSACTION });
      }}
      onDeletePairing={onDeletePairing}
    />
  );

  const PickInvoiceComponent = (
    <PickInvoice
      {...props}
      invoices={props.invoices}
      initialFilter={state.initialInvoicesFilter}
      onPick={(i) => {
        dispatch({
          type: ACTION_TYPE.PICK_INVOICE,
          invoice: i,
          entityIdToLegalEntity,
        });
      }}
      onSkip={() => {
        // we can't skip picking in first table
        if (state.step === STEP.FIRST_TABLE) return;
        dispatch({ type: ACTION_TYPE.SKIP_INVOICE });
      }}
      onDeletePairing={onDeletePairing}
    />
  );

  let stepComponent;
  switch (state.step) {
    case STEP.FIRST_TABLE: {
      stepComponent =
        firstTableToShow === "transaction"
          ? PickTransactionComponent
          : PickInvoiceComponent;
      break;
    }
    case STEP.SECOND_TABLE: {
      stepComponent =
        firstTableToShow === "transaction"
          ? PickInvoiceComponent
          : PickTransactionComponent;
      break;
    }
    case STEP.FILL_DETAILS: {
      stepComponent = (
        <FillDetails
          accounts={props.accounts}
          centers={props.centers}
          employees={props.employees}
          currencies={props.currencies}
          cashTransitPids={props.cashTransitPids}
          customPo={props.customPo}
          details={state.details}
          detailsKeysEnabled={state.detailsKeysEnabled}
          onChangeField={(fieldName, fieldValue) => {
            dispatch({
              type: ACTION_TYPE.FILL_DETAILS,
              fieldName,
              fieldValue,
            });
          }}
          onSubmit={() => dispatch({ type: ACTION_TYPE.SUBMIT })}
        />
      );
      break;
    }
    case STEP.SUBMITING: {
      stepComponent = <div> Submiting...</div>;
      break;
    }
    case STEP.SUBMIT_ERROR: {
      stepComponent = (
        <SubmitError
          state={state}
          onGoToChangeDetails={() => {
            dispatch({ type: ACTION_TYPE.DISMIS_ERROR_GO_TO_FILL_DETAIL });
          }}
          onDismisGoToBegining={() => {
            dispatch({ type: ACTION_TYPE.GO_TO_BEGINNING });
          }}
          onTryAgain={() => {
            dispatch({ type: ACTION_TYPE.TRY_SENDING_REQUEST_AGAIN });
          }}
        />
      );
      break;
    }
    default: {
      stepComponent = <div>I am in unknown state this should not happen.</div>;
      break;
    }
  }

  return (
    <PickedOverview
      state={state}
      goToBegining={() => {
        dispatch({
          type: ACTION_TYPE.GO_TO_BEGINNING,
        });
      }}
    >
      {stepComponent}
    </PickedOverview>
  );
}
