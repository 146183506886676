import React, { useContext } from "react";
import PropTypes from "prop-types";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { LoadingContext } from "./context/LoadingContext";
import { zIndexes } from "../styles/defaultTheme";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: zIndexes.loaderOverlay,
    backgroundColor: "rgba(255, 255, 255, 0.65)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  message: {
    marginTop: theme.spacing(2),
  },
}));

const FullScreenLoader = ({ message = "Loading data", forceShow = false }) => {
  const styles = useStyles();
  const { isLoading } = useContext(LoadingContext);

  return (
    <>
      {(isLoading || forceShow) && (
        <div className={styles.wrapper}>
          <CircularProgress />
          {message && (
            <Typography className={styles.message}>{message}</Typography>
          )}
        </div>
      )}
    </>
  );
};

FullScreenLoader.propTypes = {
  message: PropTypes.string,
  forceShow: PropTypes.bool,
};

export default FullScreenLoader;
