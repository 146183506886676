import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { DrawerContext } from "./context/DrawerContext";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.grey.button,
    color: theme.palette.text.primary,
    padding: theme.spacing(0.25, 0.75, 0.25, 1),
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "20px",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    width: "20px",
    height: "20px",
    marginLeft: theme.spacing(0.75),
  },
}));

const HelpButton = () => {
  const styles = useStyles();
  const { openDrawer } = useContext(DrawerContext);

  return (
    <div role="button" className={styles.button} onClick={openDrawer}>
      Help
      <img className={styles.icon} src="/assets/f1_icon.svg" alt="" />
    </div>
  );
};

export default HelpButton;
