/**
 * This file contains functions to create and manipulate the TABLE records.
 *
 * TABLE records describe a table with hierarchic labels for both rows and
 * columns, and values including subtotals.
 *
 * Example Table: **************************************************************
 *
 *                    ┃ Men                 │ Women │              │ Total ┃
 *                    ┃ Boys │ Lads │ Total │ Girls │ Gals │ Total │       ┃
 * ━━━━━━┯━━━━━━━━━━━━╋━━━━━━┿━━━━━━┿━━━━━━━┿━━━━━━━┿━━━━━━┿━━━━━━━┿━━━━━━━┫
 * SK    │ Bratislava ┃   10 │    5 │    15 │     1 │   10 │    11 │    26 ┃
 *       │ Zilina     ┃   20 │      │    27 │     2 │    9 │    11 │    38 ┃
 *       │ Kosice     ┃   15 │   10 │    25 │     3 │    8 │    11 │    36 ┃
 *       │ Total      ┃   45 │   15 │    60 │     6 │   27 │    33 │    93 ┃
 * CZ    │ Brno       ┃   10 │    5 │    15 │   100 │   20 │   120 │   135 ┃
 *       │ Praha      ┃   10 │      │    10 │     5 │   30 │    35 │    45 ┃
 *       │ Total      ┃   20 │    5 │    25 │   105 │   50 │   155 │   180 ┃
 * Total │            ┃   65 │   20 │    85 │   111 │   77 │   188 │   273 ┃
 *
 *******************************************************************************
 *
 * Let's describe the structure of TABLE record for RxC table with H columns of
 * horizontal labels, and V rows of vertical labels.
 *
 * TABLE(R, C, H, V) := {
 *   titles: [[R x TITLE(H)], [C x TITLE(V)]],
 *   values: [R x [C x (Number|null)]],
 * }
 *
 * TABLE consists of three simple arrays, array of row titles (R x H Strings),
 * array of column titles (C x V Strings), and array of values (R x C Numbers).
 *
 * TITLE(H) := [(H or less) x String]
 *
 * Each TITLE is array of Strings in hierarchical order
 * (e.g. ['SK', 'Bratislava']) of length max H. If the length of particular
 * title is less than H, it indicates that this is a title for a subotal
 * row/column (e.g. ['SK'] is subtotal for SK). In the similar fashion, Grand
 * Total is indicated by empty array.
 *
 * TITLEs always has to be orderd in such a way, that related TITLES are next to
 * each other.
 *
 * Example of good ordering, where all men and women are grouped together:
 * [['Men', 'Boys'], ['Men', 'Lads'], ['Men'], ['Women', 'Gals'], ['Women',
 * 'Girls'], ['Women'], []]
 *
 * Example of wrong ordering, where men and women are intermixed:
 * [['Men', 'Boys'], ['Women', 'Girls'], ['Men'], ['Women', 'Gals'], ['Men',
 * 'Boys'], ['Women'], []]
 *
 * Besides that, there might be other requirements based on whether the TABLE is
 * CANONIC. CANONIC TABLE has to conform to the following requirements:
 *
 * 1. There always has to be subtotal element for each title. E.g. if there is
 * an element ['Earth', 'Europe', 'SK', 'Bratislava'], then there must be
 * elements ['Earth', 'Europe', 'SK'], ['Earth', 'Europe'], ['Eearth'], and [].
 *
 * 2. Subtotal element is always the last element in the list of grouped
 * elements. Thus ['Men', 'Boys'], ['Men'], ['Men', 'Lads'] would be incorrect,
 * as ['Men'] must come after ['Men', 'Lads'].
 *
 * Function pivotTable and all subsequenet transformations (e.g.
 * pivotTransforms) always have to return CANONIC TABLE, as that is expected by
 * the table function.
 *
 * Example TABLE record: *******************************************************
 *
 * TABLE = {
 *   titles: [                // row titles
 *     ['SK', 'Bratislava'],
 *     ['SK', 'Zilina'],
 *     ['SK', 'Kosice'],
 *     ['SK'],                // subtotal for SK
 *     ['CZ', 'Brno'],
 *     ['CZ', 'Praha'],
 *     ['CZ'],                // subtotal for CZ
 *     [],                    // Grand Total
 *     ], [ // column titles
 *     ['Men', 'Boys'],
 *     ['Men', 'Lads'],
 *     ['Men'],               // subtotal for Men
 *     ['Women', 'Girls'],
 *     ['Women', 'Gals'],
 *     ['Women'],             // subtotal for Women
 *     [],                    // Grand Total
 *   ]],
 *
 *   values: [
 *     [10, 5, 15, 1, 10, 11, 26],
 *     [20, null, 27, 2, 9, 11, 38],
 *     [15, 10, 25, 3, 8, 11, 36],
 *     [45, 15, 60, 6, 27, 33, 93],
 *     [10, 5, 15, 100, 20, 120, 135],
 *     [10, null, 10, 5, 30, 35, 45],
 *     [20, 5, 25, 105, 50, 155, 180],
 *     [65, 20, 85, 111, 77, 188, 273],
 *     ]
 *   }
 *
 *******************************************************************************
 *
 *
 */
import _ from "lodash/fp";
import { str2arr, arr2str } from "./pivot";

const set = _.set.convert({ immutable: false });

const cmp =
  (order, title = _.identity, value = () => null, subtotalsOrder = 1) =>
  (a, b) => {
    [a, b] = [a, b].map(title);

    const l = Math.min(a.length, b.length);
    let cl = 0; // index where common prefix of given arrays ends
    for (let i = 0; i < l; i++) {
      if (a[i] !== b[i]) break;
      cl = cl + 1;
    }
    // We want to display absolute totals as the last items
    if (l === 0) return b.length - a.length;

    // But in the case where one is only a subtotal of the other one, we want to
    // respect subtotalsOrder.
    if (cl === l) return subtotalsOrder * (b.length - a.length);
    // compare values of selected column
    // if no column is selected
    // then av === bv (both null)
    // so rows are compared alphabeticly

    // To keep related items together, we compare the subtotal values of their
    // first different ancestor.
    //
    // Example:
    // a = ["EU", "SK", "BA", "1"]
    // b = ["EU", "SK", "KE", "2"]
    // To order these, we need to compare subtotal values for:
    // ["EU", "SK", "BA"] and ["EU", "SK", "KE"].
    const av = value(a.slice(0, cl + 1));
    const bv = value(b.slice(0, cl + 1));
    const at = typeof av;
    const bt = typeof bv;
    if (at === "number" && bt !== "number") return -1 * order;
    if (at !== "number" && bt === "number") return 1 * order;
    return av === bv ? a[cl].localeCompare(b[cl]) : (av - bv) * order;
  };

/**
 * Transform TREE structure into CANONIC TABLE which can be either input for
 * table(...) or further transformation via pivotTransform(...)
 */
export function pivotTable(tree) {
  const titles = [_.keys(tree), _.keys(tree[""])].map(
    _.flow([_.map(str2arr), (a) => a.sort(cmp(1)), _.map(arr2str)])
  );

  let values = [];
  for (const [i, rowTitle] of titles[0].entries()) {
    for (const [j, colTitle] of titles[1].entries()) {
      values = set([i, j], _.getOr(null, [rowTitle, colTitle], tree), values);
    }
  }
  return { titles: titles.map(_.map(str2arr)), values };
}

// Return -1 if prefix is not a prefix of array
// Return 0 if prefix equals to array
// Return 1 if prefix is a strict (unequal) prefix of array
export function hasPrefix(prefix, array) {
  const l = prefix.length;
  if (array.length < l) return -1;
  for (let i = 0; i < l; i++) if (prefix[i] !== array[i]) return -1;
  if (array.length === l) return 0;
  else return 1;
}

const sortRowsByTitles =
  (rowTitlesOrder, subTotalsOrder = -1) =>
  (a, b) => {
    [a, b] = [a, b].map(([, t]) => t);

    const minLength = Math.min(a.length, b.length);
    // absolute totals should be always the last items
    if (minLength === 0) return b.length - a.length;

    for (let i = 0; i < minLength; i++) {
      // if titles on level 'i' are different, order titles:
      // if rowTitlesOrder contains order for this level of hierarchy, order titles by this order
      // otherwise order them alphabetically
      if (a[i] !== b[i]) {
        if (_.keys(rowTitlesOrder).includes(`${i}`)) {
          const aIdx = rowTitlesOrder[i].indexOf(a[i]);
          const bIdx = rowTitlesOrder[i].indexOf(b[i]);
          if (aIdx === -1 || bIdx === -1) {
            // if some title doesn't have specified order, will be ordered after other one
            return bIdx + 1 - (aIdx + 1);
          } else {
            return aIdx - bIdx;
          }
        }
        return a[i].localeCompare(b[i]);
      }
      // otherwise, if titles on this level of hierarchy are the same, continue to next level
    }
    // if one is subtotal of other one, ordering respects subTotalsOrder
    return subTotalsOrder * (b.length - a.length);
  };

/**
 * Hide and reorder TABLE rows & columns to conform to display requirements
 * based on dropSubTotals, hide, rowTitlesOrder and sort arguments.
 *
 * {titles, values} : CANONIC TABLE to transform
 * dropSubTotals    : don't display subtotals for some levels
 * hide             : which groups to collapse and show only their subtotal
 * sort             : what column & order to use to sort rows
 * rowTitlesOrder   : ordering by row titles for specified levels
 * colTitlesOrder   : ordering by col titles for specified levels
 *
 * returns TABLE that doesn't conform to CANONIC form
 */

export function table(
  { titles, values },
  dropSubTotals = [[], []],
  hide,
  sort,
  rowTitlesOrder,
  colTitlesOrder
) {
  // Sort enumerated row titles
  function sortRows(rowTitles) {
    if (sort == null || sort.col == null || sort.order == null) {
      if (rowTitlesOrder)
        return rowTitles.sort(sortRowsByTitles(rowTitlesOrder));
      return rowTitles.sort(
        cmp(
          1,
          ([, t]) => t,
          (t) => null,
          -1
        )
      );
    }
    const sortCol = str2arr(sort.col);
    const sortIdx = _.findIndex((t) => hasPrefix(sortCol, t) === 0, titles[1]);
    if (sortIdx === -1) return rowTitles;

    // Store values corresponding to title to a map. There is no way around it
    // as the comparison function needs to access multiple value items (see
    // comment in cmp function for more detailed explanation).
    const vMap = Object.create(null);
    for (const [i, row] of values.entries()) {
      vMap[arr2str(rowTitles[i][1])] = row[sortIdx];
    }

    return rowTitles.sort(
      cmp(
        sort.order,
        ([, t]) => t,
        (t) => vMap[arr2str(t)],
        -1
      )
    );
  }

  function sortCols(colTitles) {
    if (!colTitlesOrder) return colTitles;

    const sortedColTitles = _.sortBy(
      ([_index, colArray]) =>
        // replace titles found in colTitles by the index of the same title in colTitlesOrder
        // i.e. with colTitlesOrder {2: ["payroll", "overheads", "hours"]}
        // ["2020", "01", "overheads"] will be replaced by ["2020", "01", 1],
        // which is then used for sorting
        colArray.map((el, j) => {
          const order = colTitlesOrder[j];
          if (!order) return el;
          const index = order.findIndex((v) => v === el);
          if (index === -1) return el;
          return index;
        }),
      colTitles
    );
    return sortedColTitles;
  }

  // Set hide contains titles in SEP separeted string format. We prefer working
  // with array format instead.
  hide = hide.map((h) => Array.from(h).map(str2arr));
  function isVisible(dimension, title) {
    const v = new Set();
    for (const h of hide[dimension]) v.add(hasPrefix(h, title));

    // If h is strict prefix, title must be hidden.
    if (v.has(1)) return false;

    // Unless the specific subtotal should be dropped, nothing prevents
    // displaying this title.
    if (!dropSubTotals[dimension].includes(title.length)) return true;

    // Even if subtotal happens to be dropped, we have to display it if all
    // subtitles happen to be hidden.
    if (v.has(0)) return true;

    return false;
  }

  // Sort and filter enumerated titles. We need to enumerate to keep track of
  // original indices to be able to extract correct values later.
  titles = titles.map((t, d) =>
    _.flow([
      d === 1 ? sortCols : sortRows, // sort cols and rows
      _.filter(([k, v]) => isVisible(d, v)),
    ])(Array.from(t.entries()))
  );

  // Extract values corresponding to filtered-out titles.
  let newValues = [];
  for (const [i, [rowKey]] of titles[0].entries()) {
    for (const [j, [colKey]] of titles[1].entries()) {
      newValues = set([i, j], values[rowKey][colKey], newValues);
    }
  }

  return { titles: titles.map(_.map(([k, v]) => v)), values: newValues };
}
