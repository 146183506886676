import _ from "lodash/fp";

export function getSelectionsContainingCell(selection, cellPos) {
  // get list of indexes of selections which contains cell
  return selection
    .map((s, idx) =>
      cellPos[0] >= s[0] &&
      cellPos[0] < s[0] + s[2] &&
      cellPos[1] >= s[1] &&
      cellPos[1] < s[1] + s[3]
        ? idx
        : null
    )
    .filter((idx) => idx != null);
}

function divideRectangle(selection, cellPos) {
  // divide selection to rectangles excluding cell at cellPos
  // function depends on fact that the cell is within rectangle defined by s
  const rectangles = [];
  if (cellPos[1] > selection[1]) {
    rectangles[0] = [
      selection[0],
      selection[1],
      selection[2],
      cellPos[1] - selection[1],
    ];
  } else {
    rectangles[0] = null;
  }
  if (cellPos[0] > selection[0]) {
    rectangles[1] = [selection[0], cellPos[1], cellPos[0] - selection[0], 1];
  } else {
    rectangles[1] = null;
  }
  if (cellPos[0] < selection[0] + selection[2] - 1) {
    rectangles[2] = [
      cellPos[0] + 1,
      cellPos[1],
      selection[0] + selection[2] - cellPos[0] - 1,
      1,
    ];
  } else {
    rectangles[2] = null;
  }
  if (cellPos[1] < selection[1] + selection[3] - 1) {
    rectangles[3] = [
      selection[0],
      cellPos[1] + 1,
      selection[2],
      selection[1] + selection[3] - cellPos[1] - 1,
    ];
  } else {
    rectangles[3] = null;
  }
  return rectangles.filter((r) => r != null);
}

export function getSelectionAfterCellDeselect(
  selection,
  selectionsContainingCell,
  cellPos
) {
  return _.flatten(
    selection.map((s, index) =>
      selectionsContainingCell.includes(index)
        ? divideRectangle(s, cellPos)
        : [s]
    )
  );
}

export function canMerge(lastSel, newSel) {
  return (
    (lastSel[0] === newSel[0] &&
      lastSel[2] === newSel[2] &&
      ((newSel[1] < lastSel[1] && newSel[1] + newSel[3] >= lastSel[1]) ||
        (newSel[1] >= lastSel[1] && newSel[1] <= lastSel[1] + lastSel[3]))) ||
    (lastSel[1] === newSel[1] &&
      lastSel[3] === newSel[3] &&
      ((newSel[0] < lastSel[0] && newSel[0] + newSel[2] >= lastSel[0]) ||
        (newSel[0] >= lastSel[0] && newSel[0] <= lastSel[0] + lastSel[2])))
  );
}

export function getMergedSelection(lastSel, newSel) {
  return [
    Math.min(lastSel[0], newSel[0]),
    Math.min(lastSel[1], newSel[1]),
    Math.max(lastSel[0] + lastSel[2], newSel[0] + newSel[2]) -
      Math.min(lastSel[0], newSel[0]),
    Math.max(lastSel[1] + lastSel[3], newSel[1] + newSel[3]) -
      Math.min(lastSel[1], newSel[1]),
  ];
}
