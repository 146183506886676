import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { format, getDaysInMonth } from "date-fns";
import { isArray } from "lodash";
import _ from "lodash/fp";
import { List, ListItem, makeStyles, Typography } from "@material-ui/core";
import { filterWidth, bottomBarHeight } from "../styles/defaultTheme";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { CellDetailContext } from "./context/CellDetailContext";
import { tableUrlNames } from "../definitions";

const useBarStyles = makeStyles((theme) => ({
  wrapper: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    width: `calc(100% - ${filterWidth}px)`,
    backgroundColor: theme.palette.background.default,
    padding: "8px 16px 8px 8px",
    height: `${bottomBarHeight}px`,
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid",
    borderTopColor: theme.palette.grey.main,
  },
  list: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    overflowX: "auto",
    padding: 0,
    height: "100%",
    scrollbarWidth: "none",
    scrollBehavior: "smooth",
    "-webkit-overflow-scrolling": "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  controlsWrapper: {
    display: "flex",
    alignItems: "center",
    paddingRight: "8px",
  },
  scrollIcon: {
    width: "20px",
    height: "20px",
    color: theme.palette.grey.dark,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const useTagStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.grey.light,
    display: "flex",
    borderRadius: "8px",
    flex: "0 0 auto",
    width: "initial",
    padding: 0,
    marginRight: "16px",
    height: "100%",
  },
  textWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "8px 10px 8px 12px",
    pointerEvents: "none",
    userSelect: "none",
    height: "100%",
  },
  text: {
    fontSize: "0.75em",
    lineHeight: "1.125em",
  },
  closeWrapper: {
    borderLeft: "1px solid",
    borderLeftColor: theme.palette.grey.border,
    height: "100%",
    padding: "6px 6px 6px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey.border,
    },
  },
  closeWrapperDisabled: {
    pointerEvents: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "initial",
    },
  },
  closeIcon: {
    width: "16px",
    height: "16px",
    color: theme.palette.text.primary,
  },
}));

const tagsWithType = [
  "from",
  "to",
  "whitelisted",
  "unpaired",
  "amountFrom",
  "amountTo",
  "entity",
];
const pivotsWithDateFilter = ["Counterparties balances", "Balance sheet"];

const filterTagValueToLabel = (type, value, pivotName) => {
  if (type === "months") {
    // if value is 'YTD' or value is only year, label and value are the same
    if (value === "YTD" || value.match(/^[\d]{4}$/)) return value;
    // value and label are different only for values in format YYYY-MM
    return `${format(new Date(value), "MMM YYYY")}`;
  } else if (pivotsWithDateFilter.includes(pivotName) && type === "to") {
    // set same label as date format label in DateFilter
    return `${format(new Date(value), "MMM DD, YYYY")}`;
  } else if (type === "departments") {
    return `${value.unit} / ${value.department}`;
  } else if ("P&L" === pivotName) {
    if (type === "from") {
      return `${format(new Date(value), "MMM 1, YYYY")}`;
    }
    if (type === "to") {
      return `${format(new Date(value), "MMM")} ${getDaysInMonth(
        new Date(value)
      )}, 
      ${format(new Date(value), "YYYY")}`;
    }
  } else if (
    "Payroll Expenses Overview" === pivotName &&
    ["from", "to"].includes(type)
  ) {
    return `${format(new Date(value), "MMM YYYY")}`;
  } else if (
    // if value is value of select it should have value and label properties and show label in that case
    typeof value === "object" &&
    value.hasOwnProperty("label") &&
    value.hasOwnProperty("value")
  ) {
    return value.label;
  }
  return value;
};

const Tag = ({ type, value, isClearable, pivotName, onClose }) => {
  const styles = useTagStyles();
  const { isCellDetail } = useContext(CellDetailContext);

  return (
    <ListItem className={styles.wrapper} disabled={isCellDetail}>
      <div className={styles.textWrapper}>
        <Typography className={styles.text}>
          {`${
            tagsWithType.includes(type) ? `${type}: ` : ""
          }${filterTagValueToLabel(type, value, pivotName)}`}
        </Typography>
      </div>
      {isClearable && (
        <div
          className={clsx(
            styles.closeWrapper,
            isCellDetail && styles.closeWrapperDisabled
          )}
          onClick={onClose}
        >
          <CloseIcon className={styles.closeIcon} />
        </div>
      )}
    </ListItem>
  );
};

const unclearableTables = [
  tableUrlNames.counterpartiesBalances,
  tableUrlNames.balanceSheet,
  tableUrlNames.PL,
  tableUrlNames.budgeting,
  tableUrlNames.payrolExpenses,
  tableUrlNames.employeeOverheads,
  tableUrlNames.payrollEmployeeBalance,
];

const isUnclearableFilter = (type, tableUrlName) => {
  return (
    ["from", "to", "year", "view"].includes(type) &&
    unclearableTables.includes(tableUrlName)
  );
};

const typeTagOrder = {
  [tableUrlNames.PL]: ["from", "to", "units", "departments", "entities"],
  [tableUrlNames.PLByMonth]: [
    "accounts",
    "months",
    "units",
    "departments",
    "entities",
  ],
  [tableUrlNames.PLByMonthSplit]: ["accounts", "months", "units", "entities"],
  [tableUrlNames.nonTax]: ["accounts", "months", "entities"],
  [tableUrlNames.budgeting]: [
    "year",
    "view",
    "accounts",
    "units",
    "departments",
  ],
};

const tagsFromFilterState = (filterState, tableUrlName) => {
  const filterTags = {};
  const tagsTypes = _.keys(filterState);
  const order = typeTagOrder[tableUrlName];
  if (order) {
    tagsTypes.sort((a, b) => {
      const aTypeIdx = order.findIndex((type) => type === a);
      const bTypeIdx = order.findIndex((type) => type === b);
      return aTypeIdx - bTypeIdx;
    });
  }
  tagsTypes.forEach((type) => {
    filterTags[type] = {
      tags: filterState[type],
      isClearable: !isUnclearableFilter(type, tableUrlName),
    };
  });
  return filterTags;
};

const BottomBar = ({
  filterState,
  onFilterChange,
  pivotName,
  tableUrlName,
}) => {
  const styles = useBarStyles();
  const listRef = useRef(null);
  const filterTags = tagsFromFilterState(filterState, tableUrlName);

  const isOverflowing = () => {
    if (listRef && listRef.current) {
      return listRef.current.scrollWidth > listRef.current.clientWidth;
    }

    return null;
  };

  const [isScrollable, setIsScrollable] = useState(isOverflowing());

  useEffect(() => {
    setIsScrollable(isOverflowing());
  }, [filterState]);

  const advanceScroll = (e) => {
    e.preventDefault();
    listRef.current.scrollLeft += 500;
  };

  const reverseScroll = (e) => {
    e.preventDefault();
    listRef.current.scrollLeft -= 500;
  };

  const onCloseTag = (type, value) => {
    const tags = filterTags[type].tags;
    const tagsWithoutValue = _.isArray(tags)
      ? // filterTags.tags can be array of values or it can be one value
        tags.filter((tag) => tag !== value)
      : tags === value
      ? null
      : tags;
    if (type === "units" && filterTags.departments) {
      onFilterChange({
        ...filterState,
        [type]: tagsWithoutValue,
        departments: filterTags.departments.tags.filter(
          ({ unit }) => unit !== value
        ),
      });
      return;
    }
    onFilterChange({
      ...filterState,
      [type]: tagsWithoutValue,
    });
  };

  return (
    <div className={styles.wrapper}>
      {isScrollable && (
        <div className={styles.controlsWrapper}>
          <KeyboardArrowLeftIcon
            onClick={reverseScroll}
            className={styles.scrollIcon}
          />
          <KeyboardArrowRightIcon
            onClick={advanceScroll}
            className={styles.scrollIcon}
          />
        </div>
      )}
      <List className={styles.list} ref={listRef}>
        {_.keys(filterTags).map((type, i) =>
          isArray(filterTags[type].tags)
            ? filterTags[type].tags.map((tag, j) => (
                <Tag
                  key={j}
                  type={type}
                  value={tag}
                  isClearable={filterTags[type].isClearable}
                  pivotName={pivotName}
                  onClose={() => onCloseTag(type, tag)}
                />
              ))
            : // tag for one-value filters will be created only if value isn't null
              filterTags[type].tags && (
                <Tag
                  key={i}
                  type={type}
                  value={filterTags[type].tags}
                  isClearable={filterTags[type].isClearable}
                  pivotName={pivotName}
                  onClose={() => onCloseTag(type, filterTags[type].tags)}
                />
              )
        )}
      </List>
    </div>
  );
};

Tag.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  isClearable: PropTypes.bool,
  filterState: PropTypes.object,
  onFilterChange: PropTypes.func,
};

BottomBar.propTypes = {
  pivotName: PropTypes.string,
  tableUrlName: PropTypes.string,
  filterState: PropTypes.object,
  onFilterChange: PropTypes.func,
};

export default BottomBar;
