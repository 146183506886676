import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import { DrawerContext } from "./context/DrawerContext";
import {
  Drawer,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { keyboardKey } from "../utils";
import { zIndexes } from "../styles/defaultTheme";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "600px",
    maxWidth: "60vw",
  },
  contentWrapper: {
    padding: theme.spacing(0, 3, 1.5, 3),
  },
  header: {
    // Safari specific flex style
    // to preserve button size
    flex: "0 0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(1.5, 3),
  },
  closeButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.grey.button,
    color: theme.palette.text.primary,
    padding: theme.spacing(0.5, 0.75, 0.5, 1.5),
    borderRadius: theme.spacing(0.5),
    fontSize: "14px",
    lineHeight: "20px",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  closeIcon: {
    width: "20px",
    height: "20px",
    marginLeft: theme.spacing(0.5),
    color: theme.palette.grey.dark,
  },
  tableContainer: {
    boxShadow: "0 1px 10px rgba(0,0,0,0.08), 0 1px 5px rgba(0,0,0,0.12)",
    "& + &": {
      marginTop: theme.spacing(2),
    },
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: "fixed",
  },
  tableCell: {
    padding: theme.spacing(0.75, 1.5),
  },
  tableHeadCell: {
    backgroundColor: theme.palette.grey.light,
    fontSize: "1rem",
    lineHeight: "1.25rem",
  },
  tableBolderCell: {
    fontWeight: 500,
  },
}));

const shortcuts = [
  {
    title: "Navigation",
    items: [
      ["Move to a neighboring cell", "Arrow keys"],
      ["Move to the top cell in the column", "( Ctrl / ⌘ ) + Up Arrow"],
      ["Move to the bottom cell in the column", "( Ctrl / ⌘ ) + Down Arrow"],
      ["Move to the leftmost cell in the row", "( Ctrl / ⌘ ) + Left Arrow"],
      ["Move to the rightmost cell in the row", "( Ctrl / ⌘ ) + Right arrow"],
      ["Move one page down", "Page Down"],
      ["Move one page up", "Page Up"],
    ],
  },
  {
    title: "Menu",
    items: [
      ["Open menu", "Ctrl + M"],
      ["Close menu", "Ctrl + M / Escape"],
      ["Search in menu", "Start typing"],
      ["Move between pivot tables", "Up / Down Arrow"],
      ["Select pivot table", "Enter"],
      ["Reload data from server", "Alt + R"],
    ],
  },
  {
    title: "Filtering",
    items: [
      ["Open filter", "Ctrl + /"],
      ["Close filter", "Ctrl + / or Escape"],
      ["Navigate between filters", "TAB"],
    ],
  },
  {
    title: "Select",
    items: [
      [
        "Select a range between two cells",
        "First click one cell. Then Shift + click the other cell / Shift + Arrow keys to the other cell.",
      ],
      ["Select whole column", "Ctrl + Space"],
      ["Select whole row", "Shift + Space"],
      ["Select all", "Ctrl + A, Ctrl + Shift + Space"],
      ["Add another cell to the selection", "( Ctrl / ⌘ ) + click"],
      ["Add another range to the selection", "( Ctrl / ⌘ ) + Shift + click"],
      ["Copy", "( Ctrl / ⌘ ) + C"],
      ["Show cell detail", "Double click or Ctrl + Enter"],
    ],
  },
  {
    title: "Search",
    items: [
      ["Start search", "Ctrl + F / F3"],
      ["Show the next result", "Enter"],
      ["Show the previous result", "Shift + Enter"],
      ["End search", "Escape"],
    ],
  },
  {
    title: "Pairing transactions and invoices",
    items: [
      ["Pick from table", "Ctrl + P or double click"],
      ["Ignore picking from table", "Ctrl + I"],
      ["Delete pairing", "Ctrl + D"],
      ["Comment transaction popup", "Ctrl + K"],
    ],
  },
];

const ShortcutsDrawer = () => {
  const { isOpen, closeDrawer, toggleDrawer } = useContext(DrawerContext);

  const onKeyDown = (e) => {
    const key = keyboardKey(e);

    if (key.isF1) {
      e.preventDefault();
      toggleDrawer();
    }

    if (key.isEscape) {
      e.preventDefault();
      closeDrawer();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  });

  const styles = useStyles();

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      classes={{
        paper: styles.drawer,
      }}
      style={{ zIndex: zIndexes.shortcutsOverlay }}
      transitionDuration={0}
    >
      <div className={styles.header}>
        <div role="button" className={styles.closeButton} onClick={closeDrawer}>
          Close
          <CloseIcon className={styles.closeIcon} onClick={closeDrawer} />
        </div>
      </div>
      <div className={styles.contentWrapper}>
        {shortcuts.map(({ title, items }, i) => (
          <TableContainer
            key={i}
            component={Paper}
            className={styles.tableContainer}
          >
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={clsx(styles.tableCell, styles.tableHeadCell)}
                    colSpan={2}
                  >
                    {title}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(([description, shortcut], j) => (
                  <TableRow key={j}>
                    <TableCell className={styles.tableCell}>
                      {description}
                    </TableCell>
                    <TableCell
                      className={clsx(styles.tableCell, styles.tableBolderCell)}
                    >
                      {shortcut}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      </div>
    </Drawer>
  );
};

export default ShortcutsDrawer;
