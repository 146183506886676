var departmentsMapping = {
  it: "IT",
  finance: "Finance",
  reactive: "Reactive",
  sales: "Sales",
  operations: "Operations",
  hr: "HR",
  marketing: "Marketing",
  legal: "Legal",
  recruitment: "Recruitment",
  design: "Design",
  designProduct: "DesignProduct",
  tribal: "Tribal",
  pm: "PM",
  creative: "Creative",
  catering: "Catering",
  trama: "Trama",
  dataScience: "Data Science",
  beMoney: "BeMoney",
  salesAsia: "Sales Asia",
  testing: "Testing",
  growth: "Growth",
  sparring: "Sparring",
  cafe: "Cafe",
  concierge: "Concierge",
  theSpot: "The Spot",
  ceo: "Office of CEO",
  ventures: "Ventures",
  benefits: "Benefits",
  adalite: "NuFi",
  cfoaas: "Capila",
  daycare: "Daycare",
  dex: "WingRiders",
  labU: "LabU",
  leadership: "Leadership",
  nozdormu: "Wincent",
  rcMom: "RcMom",
  robo: "Robo",
  space: "Space",
  techSupport: "TechSupport",
  verdikto: "Verdikto",
  rcCoreTeam: "RcCoreTeam",
  rcInfolinka: "RcInfolinka",
  expansionTeam: "Expansion Team",
  salesAmerica: "Sales America",
  hrGeneral: "HR General",
  hrOperations: "HR Operations",
  benefitManagement: "Benefit Management",
  hrBizPartner: "HR Biz Partner",
  hhhu: "The Spot HU",
  csr: "CSR",
  ksebe: "Ksebe",
  groupRecruitment: "Group Recruitment",
  vlPeopleManagement: "VL People Mngmt",
  rcLab: "RCLab"
};
module.exports = {
  departmentsMapping: departmentsMapping
};