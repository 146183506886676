var _toConsumableArray = require("/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");
var _slicedToArray = require("/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");
var formatDate = function formatDate(date) {
  return "".concat(date.getFullYear(), "-").concat("".concat(date.getMonth() + 1).padStart(2, "0"), "-").concat("".concat(date.getDate()).padStart(2, "0"));
};
var getDateFromNumber = function getDateFromNumber(dateNumber) {
  return new Date(Date.UTC(1899, 11, 30) + 1000 * 24 * 60 * 60 * dateNumber);
};
var simplematicsUrl = "https://vacuumlabs.simplematics.cz/app/find";
var simplematicsSourceLink = function simplematicsSourceLink(row, _$) {
  var _row$_$$source$split = row[_$.source].split("/"),
    _row$_$$source$split2 = _slicedToArray(_row$_$$source$split, 2),
    source = _row$_$$source$split2[0],
    direction = _row$_$$source$split2[1];
  if (source !== "simplematics") return null;
  var _row$_$$uuid$split = row[_$.uuid].split(/-([^/#]*)/),
    _row$_$$uuid$split2 = _slicedToArray(_row$_$$uuid$split, 2),
    prefix = _row$_$$uuid$split2[0],
    number = _row$_$$uuid$split2[1];
  return "".concat(simplematicsUrl, "/").concat(prefix, "/").concat(number).concat(direction === "out" ? "/out" : "");
};
function simplematicsLinkFromInvoice(invoice) {
  return "".concat(simplematicsUrl, "/").concat(invoice.entity, "/").concat(invoice.id).concat(invoice.incoming ? "" : "/out");
}
var simplematicsLinkFromUuidAndSource = function simplematicsLinkFromUuidAndSource(uuid, source) {
  var _source$split = source.split("/"),
    _source$split2 = _slicedToArray(_source$split, 2),
    direction = _source$split2[1];
  var _uuid$split = uuid.split(/-([^/#]*)/),
    _uuid$split2 = _slicedToArray(_uuid$split, 2),
    prefix = _uuid$split2[0],
    number = _uuid$split2[1];
  return "".concat(simplematicsUrl, "/").concat(prefix, "/").concat(number).concat(direction === "out" ? "/out" : "");
};
var mirrorTransactions = function mirrorTransactions(transactions) {
  return transactions.map(function (transaction) {
    var mirroredTransaction = _toConsumableArray(transaction);
    mirroredTransaction[4] *= -1;
    mirroredTransaction[5] *= -1;
    mirroredTransaction[32] = mirroredTransaction[32] ? -mirroredTransaction[32] : null;

    // swap from and to
    var from = mirroredTransaction[2];
    mirroredTransaction[2] = mirroredTransaction[3];
    mirroredTransaction[3] = from;
    return mirroredTransaction;
  });
};
module.exports = {
  formatDate: formatDate,
  getDateFromNumber: getDateFromNumber,
  simplematicsSourceLink: simplematicsSourceLink,
  simplematicsLinkFromInvoice: simplematicsLinkFromInvoice,
  mirrorTransactions: mirrorTransactions,
  simplematicsLinkFromUuidAndSource: simplematicsLinkFromUuidAndSource
};