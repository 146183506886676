import React from "react";
import PropTypes from "prop-types";
import { TextField, IconButton, Chip, makeStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
  },
  input: {
    "& .MuiInputBase-input": {
      padding: theme.spacing(0.75, 1),
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
  },
  counter: {
    backgroundColor: theme.palette.grey.main,
    minWidth: "54px",
    height: "20px",
    textAlign: "center",
    marginLeft: theme.spacing(1),
    padding: 0,
  },
  counterLabel: {
    padding: theme.spacing(0, 1),
  },
  iconButton: {
    padding: 0,
  },
  buttonsWrapper: {
    marginLeft: theme.spacing(1),
  },
}));

const TableSearch = ({
  onChange,
  inputRef,
  searchCount,
  onPrevious,
  onNext,
  onClose,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <TextField
        placeholder="Type to search"
        classes={{ root: styles.input }}
        variant="outlined"
        inputRef={inputRef}
        onChange={onChange}
      />
      <Chip
        classes={{ root: styles.counter, label: styles.counterLabel }}
        label={searchCount}
      />
      <div className={styles.buttonsWrapper}>
        <IconButton
          aria-label="Previous result"
          classes={{ root: styles.iconButton }}
          onClick={onPrevious}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          aria-label="Next result"
          classes={{ root: styles.iconButton }}
          onClick={onNext}
        >
          <ChevronRightIcon />
        </IconButton>
      </div>
      <IconButton
        aria-label="Close search"
        classes={{ root: styles.iconButton }}
        onClick={onClose}
      >
        <CloseIcon color="primary" />
      </IconButton>
    </div>
  );
};

TableSearch.propTypes = {
  onChange: PropTypes.func,
  inputRef: PropTypes.object,
  searchCount: PropTypes.string,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default TableSearch;
