import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import Select from "react-select-virtualized";

function CustomSelect({
  optionsArray,
  details,
  fieldName,
  onChangeField,
  disabled,
}) {
  const options = useMemo(() => {
    return optionsArray.map((o) => ({ value: o, label: o }));
  }, [optionsArray]);
  return (
    <Select
      isClearable
      backspaceRemovesValue
      value={
        details[fieldName] == null
          ? null
          : { value: details[fieldName], label: details[fieldName] }
      }
      options={options}
      onChange={(value) => onChangeField(fieldName, value?.value)}
      isDisabled={disabled}
    />
  );
}

function EmployeesForPo({ employees, setPo, disabled }) {
  const options = useMemo(() => {
    return employees.map((o) => ({ value: o, label: o }));
  }, [employees]);
  return (
    <span>
      Fill PO from{" "}
      <a
        href="https://bee.vacuumlabs.com/table/oxRkvZRrEy5q/oxRkvZRrEy5q"
        target="_blank"
        rel="noreferrer"
      >
        Employees
      </a>
      :
      <Select
        isClearable
        backspaceRemovesValue
        value={null}
        options={options}
        onChange={(value) => setPo(value?.value)}
        isDisabled={disabled}
      />
    </span>
  );
}

function CustomPo({ customPo, setPo, disabled }) {
  const options = useMemo(() => {
    return customPo.map((o) => ({ value: o, label: o }));
  }, [customPo]);
  return (
    <span>
      Fill PO from{" "}
      <a
        href="https://bee.vacuumlabs.com/table/f1k5EAInwNPw/f1k5EAInwNPw"
        target="_blank"
        rel="noreferrer"
      >
        Custom POs
      </a>
      :
      <Select
        isClearable
        backspaceRemovesValue
        value={null}
        options={options}
        onChange={(value) => setPo(value?.value)}
        isDisabled={disabled}
      />
    </span>
  );
}

/**
 * @param {{details: {
 *            currency: string,
 *            amount: string,
 *            account: string
 *            center: string
 *            PO: string
 *            pid: string
 *         },
 *         detailsKeysEnabled: Set<string>,
 *         onChangeField: fn
 *         onSubmit: fn
 *        }} props
 */
export function FillDetails(props) {
  const onChangeValue = (fieldName) => {
    return (event) => {
      const value =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      props.onChangeField(fieldName, value);
    };
  };

  const amountInputRef = React.useRef(null);
  useEffect(() => {
    amountInputRef.current.focus();
  }, []);

  function isDisabled(key) {
    return !props.detailsKeysEnabled.has(key);
  }

  const { employees, cashTransitPids } = props;
  const pidList = useMemo(() => {
    return [...employees.sort(), ...cashTransitPids.sort()];
  }, [employees, cashTransitPids]);

  const accountsSorted = props.accounts.sort();
  const centersSorted = props.centers.sort();
  //const pidListSorted = pidList.sort();
  const custumPOSorted = props.customPo.map(([po]) => po).sort();
  const employeesSorted = props.employees.sort();

  return (
    <Box p={2}>
      <Typography variant="h5" component="h3">
        Fill details
      </Typography>
      <TextField
        label="Amount"
        value={props.details.amount}
        onChange={onChangeValue("amount")}
        disabled={isDisabled("amount")}
        ref={amountInputRef}
        variant="outlined"
        fullWidth
      />
      <br />
      <a
        href="https://bee.vacuumlabs.com/table/M7YOdop0J3VA/M7YOdop0J3VA"
        target="_blank"
        rel="noreferrer"
      >
        Currency
      </a>
      :
      <CustomSelect
        optionsArray={props.currencies}
        details={props.details}
        fieldName="currency"
        onChangeField={props.onChangeField}
        disabled={isDisabled("currency")}
        variant="outlined"
        fullWidth
      />
      <br />
      Account from:
      <CustomSelect
        optionsArray={accountsSorted}
        details={props.details}
        fieldName="account_from"
        onChangeField={props.onChangeField}
        disabled={isDisabled("account_from")}
      />
      <br />
      Account to:
      <CustomSelect
        optionsArray={accountsSorted}
        details={props.details}
        fieldName="account_to"
        onChangeField={props.onChangeField}
        disabled={isDisabled("account_to")}
      />
      <br />
      <a
        href="https://bee.vacuumlabs.com/table/DNSkUCcf6m2T/DNSkUCcf6m2T"
        target="_blank"
        rel="noreferrer"
      >
        Center key
      </a>
      :
      <CustomSelect
        optionsArray={centersSorted}
        details={props.details}
        fieldName="center"
        onChangeField={props.onChangeField}
        disabled={isDisabled("center")}
      />
      <br />
      <TextField
        label="PO (text)"
        value={props.details.po ?? ""}
        onChange={onChangeValue("po")}
        disabled={isDisabled("po")}
        variant="outlined"
        fullWidth
      />
      <br />
      <EmployeesForPo
        employees={employeesSorted}
        setPo={(value) => props.onChangeField("po", value)}
        disabled={isDisabled("po")}
      />
      <CustomPo
        customPo={custumPOSorted}
        setPo={(value) => props.onChangeField("po", value)}
        disabled={isDisabled("po")}
      />
      <br />
      <a
        href="https://bee.vacuumlabs.com/table/qrC8zZxwuw9m/qrC8zZxwuw9m"
        target="_blank"
        rel="noreferrer"
      >
        PID:
      </a>
      <CustomSelect
        optionsArray={pidList}
        details={props.details}
        fieldName="pid"
        onChangeField={props.onChangeField}
        disabled={isDisabled("pid")}
      />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={!!props.details.non_tax_expense}
            onChange={onChangeValue("non_tax_expense")}
            disabled={isDisabled("non_tax_expense")}
          />
        }
        label="Non-tax expense"
      />
      <br />
      <Button
        onClick={(e) => {
          props.onSubmit();
        }}
        variant="contained"
        color="primary"
      >
        SUBMIT
      </Button>
    </Box>
  );
}
