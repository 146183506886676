import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  resolvedText: {
    textAlign: "center",
    color: "#545653",
  },
  resolved: {
    textDecoration: "line-through",
  },
}));

export function Comment({ comment }) {
  const styles = useStyles();

  return (
    <Box component={Paper} mb={1} p={1}>
      <header className={styles.header}>
        <div>
          <b>{comment.created_by}</b>{" "}
          <Typography variant="caption">{comment.created_at}</Typography>
        </div>
        {comment.assigned_to && (
          <Typography variant="caption">@{comment.assigned_to}</Typography>
        )}
      </header>
      <Typography
        className={clsx(
          (comment.resolved_at || comment.resolved_by) && styles.resolved
        )}
      >
        {comment.text}
      </Typography>
      {(comment.resolved_at || comment.resolved_by) && (
        <Typography variant="caption" className={styles.resolvedText}>
          Resolved by <b>{comment.resolved_by}</b> at {comment.resolved_at}.
        </Typography>
      )}
    </Box>
  );
}
