import React from "react";
import _ from "lodash/fp";
import PropTypes from "prop-types";
import {
  canMerge,
  getSelectionAfterCellDeselect,
  getMergedSelection,
  getSelectionsContainingCell,
} from "./SelectionMerger";
import { cellHeight } from "./GridCanvas/style";
import { keyboardKey } from "../utils";

const points2rect = ([ax, ay], [bx, by]) => {
  const [x1, x2] = ax < bx ? [ax, bx] : [bx, ax];
  const [y1, y2] = ay < by ? [ay, by] : [by, ay];
  return [x1, y1, x2 - x1 + 1, y2 - y1 + 1];
};

const moves = {
  ArrowLeft: [-1, 0],
  ArrowRight: [1, 0],
  ArrowUp: [0, -1],
  ArrowDown: [0, 1],
};

export default class Selection extends React.Component {
  state = {
    activeCell: [...this.props.frozen],
    selection: [[...this.props.frozen, 1, 1]],
  };
  tryToMerge = false;

  componentDidMount() {
    if (window.getSelection) window.getSelection().removeAllRanges();
    window.addEventListener("mouseup", this.onMouseUp);
    // in chrome, after drag-scrolling, 'dragend' is fired instead of 'mouseup'
    window.addEventListener("dragend", this.onMouseUp);
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("copy", this.onCopy);
  }

  componentWillUnmount() {
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("dragend", this.onMouseUp);
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("copy", this.onCopy);
  }

  // adjusts selection and activeCell positions
  // based on cols and rows from props
  static getDerivedStateFromProps(props, state) {
    const { activeCell, selection } = state;
    const size = [props.cols, props.rows];
    const nac = activeCell.map((v, d) => Math.min(v, size[d] - 1));
    const ns = _.cloneDeep(selection).map((s) => {
      size.forEach((v, i) => {
        s[i] = Math.min(v - 1, s[i]);
        s[i + 2] = _.clamp(
          s[i] === v || s[i + 2] === 0 ? 0 : 1,
          v - s[i],
          s[i + 2]
        );
      });
      return s;
    });
    return _.isEqual([nac, ns], [activeCell, selection])
      ? null
      : { activeCell: nac, selection: ns };
  }

  onMouseDown = (e) => {
    if (!this.mousePos || this.cellArea !== "middle") return;
    this.mouseDown = true;
    const { selection, activeCell } = this.state;

    const key = keyboardKey(e);

    if (key.isShift) {
      const s = _.cloneDeep(selection);
      s[s.length - 1] = points2rect(activeCell, this.mousePos);
      this.setState({ selection: s });
    } else {
      if (key.isCtrlOrMeta) {
        const selectionsContainingCell = getSelectionsContainingCell(
          selection,
          this.mousePos
        );
        if (selectionsContainingCell.length > 0) {
          const newSelection = getSelectionAfterCellDeselect(
            selection,
            selectionsContainingCell,
            this.mousePos
          );
          this.setState({ selection: newSelection });
        } else {
          this.tryToMerge = true;
          this.setState({
            activeCell: this.mousePos,
            selection: [...selection, [...this.mousePos, 1, 1]],
          });
        }
      } else {
        this.setState({
          activeCell: this.mousePos,
          selection: [[...this.mousePos, 1, 1]],
        });
      }
    }
  };

  handleArrowKey = (diff, shift) => {
    const size = [this.props.cols, this.props.rows];
    const { selection, activeCell } = this.state;
    if (!shift) {
      const nac = activeCell.map((v, d) =>
        _.clamp(0, size[d] - 1, v + diff[d])
      );
      this.setState({ activeCell: nac, selection: [[...nac, 1, 1]] });
      this.props.onScrollToChange(nac);
    } else {
      const lastSel = _.get(selection.length - 1, selection);
      // second point - selection is always between active cell and this point
      const sp = [0, 1].map((d) => {
        const v =
          lastSel[d] === activeCell[d]
            ? lastSel[d] + lastSel[d + 2] - 1
            : lastSel[d];
        return _.clamp(0, size[d] - 1, v + diff[d]);
      });
      this.setState({
        selection: _.set(
          selection.length - 1,
          points2rect(activeCell, sp),
          selection
        ),
      });
      this.props.onScrollToChange(sp);
    }
  };

  onMouseUp = (e) => {
    this.mouseDown = false;
    if (this.tryToMerge) {
      this.tryToMerge = false;
      const s = this.state.selection;
      const prevSel = _.get(s.length - 2, s);
      const newSel = _.get(s.length - 1, s);
      if (prevSel && newSel && canMerge(prevSel, newSel)) {
        const sel = s.slice(0, s.length - 1);
        this.setState({
          selection: _.set(
            sel.length - 1,
            getMergedSelection(prevSel, newSel),
            sel
          ),
        });
      }
    }
  };

  // maximizes selection vertically or horizontally
  expandSelection = (ctrl, shift) => {
    const { selection } = this.state;
    const { rows, cols } = this.props;
    let lastSel = _.get(selection.length - 1, selection);
    const diff = [
      [shift, cols],
      [ctrl, rows],
    ];
    diff.forEach(([button, length], i) => {
      if (!button) return;
      lastSel = _.set(i, 0, lastSel); //lastSel[i] = 0
      lastSel = _.set(i + 2, length, lastSel); //lastSel[i + 2] = length
    });
    this.setState({
      selection: _.set(selection.length - 1, lastSel, selection),
      activeCell: lastSel.slice(0, 2),
    });
  };

  movePage = (direction) => {
    if (!this.props.gridCanvasWrapperRef) return;

    const { rows, gridCanvasWrapperRef, frozen } = this.props;
    const { activeCell } = this.state;
    const [xPos, yPos] = activeCell ? [activeCell[0], activeCell[1]] : [0, 0];

    // calculate how many rows active cell should move in required direction
    let stepLength =
      Math.floor(gridCanvasWrapperRef.clientHeight / cellHeight) - frozen[1];
    if (yPos < frozen[1]) {
      // if current active cell is in column titles area, step should be longer based on it
      stepLength += frozen[1] - yPos;
    }

    // calculate future y position of active cell
    let newYPos = direction === 1 ? yPos + stepLength : yPos - stepLength;

    if (newYPos < frozen[1] || newYPos > rows - 1) {
      // if new y position would be out of table, active cell will move to first/last row,
      newYPos = direction === 1 ? rows - 1 : frozen[1];
    } else if (rows - newYPos < stepLength) {
      // if new y position would be on the last page, active cell will be in first row of last page
      newYPos = rows - stepLength;
    }

    const newActiveCell = [xPos, newYPos];
    this.setState({
      activeCell: newActiveCell,
      selection: [[xPos, newYPos, 1, 1]],
    });

    // move view that new active cell will be on top of the page
    const viewYPos =
      direction === 1
        ? Math.min(newYPos + stepLength - 2, rows - 1)
        : Math.max(newYPos, frozen[1]);
    this.props.onScrollToChange([xPos, viewYPos]);
  };

  onKeyDown = (e) => {
    if (this.props.disableKeys) return;

    const key = keyboardKey(e);

    const { rows, cols } = this.props;
    if (e.key in moves) {
      e.preventDefault();
      let diff = moves[e.key];
      if (key.isCtrlOrMeta)
        diff = diff.map((v) => (v === 0 ? v : v * Infinity));
      this.handleArrowKey(diff, key.isShift);
    } else if (key.isCtrlOrMeta && key.isA) {
      e.preventDefault();
      this.setState({ selection: [[0, 0, cols, rows]], activeCell: [0, 0] });
    } else if (key.isSpace) {
      this.expandSelection(key.isCtrlOrMeta, key.isShift);
    } else if (key.isCtrlOrMeta && key.isEnter) {
      if (this.props.openCellDetail)
        this.props.openCellDetail(this.state.activeCell);
    } else if (key.isPageDown) {
      this.movePage(1);
    } else if (key.isPageUp) {
      this.movePage(-1);
    }
  };

  onPaint = (v) => {
    const { mousePos, cellArea } = v;
    if (_.isEqual([mousePos, cellArea], [this.mousePos, this.cellArea])) return;
    this.mousePos = mousePos;
    this.cellArea = cellArea;
    if (!mousePos || !this.mouseDown) return;
    const s = [...this.state.selection];
    s[s.length - 1] = points2rect(this.state.activeCell, this.mousePos);
    this.setState({ selection: s });
  };

  onCopy = (e) => {
    e.preventDefault();
    const rows = [];
    const { selection } = this.state;
    if (selection.length === 0) return;
    const [x, y, w, h] = selection[selection.length - 1];
    for (let i = y; i < y + h; i++) {
      const items = [];
      for (let j = x; j < x + w; j++) {
        items.push(this.props.getCell(j, i)[0]);
      }
      rows.push(items);
    }
    e.clipboardData.setData(
      "text/plain",
      rows.map((row) => row.join("\t")).join("\n")
    );
    e.clipboardData.setData(
      "text/html",
      `<table>${rows
        .map(
          (row) => `<tr>${row.map((cell) => `<td>${cell}</td>`).join("")}</tr>`
        )
        .join("")}</table>`
    );
  };

  render() {
    return this.props.children({
      ...this.state,
      onPaint: this.onPaint,
      onCopy: this.onCopy,
      onMouseDown: this.onMouseDown,
    });
  }
}

Selection.propTypes = {
  getCell: PropTypes.func.isRequired,
  rows: PropTypes.number.isRequired,
  cols: PropTypes.number.isRequired,
  frozen: PropTypes.arrayOf(PropTypes.number).isRequired,
  openCellDetail: PropTypes.func,
};
