import _ from "lodash/fp";
import {
  tableUrlNames,
  urlNamesThatRequireFinanceTeamMembership,
} from "../definitions";

// I have no better idea where this function should be
// i dont like it here but both Pages need it so at least it is in one place
export function getAllowedTables(
  originalPivotLinks,
  allowedHierarchy,
  hasCard,
  isInFinanceTeam
) {
  const pivotLinks = isInFinanceTeam
    ? originalPivotLinks
    : originalPivotLinks.filter(
        (l) => !urlNamesThatRequireFinanceTeamMembership.has(l.urlName)
      );

  if (allowedHierarchy === true) return pivotLinks;
  const tablesToShow = new Set();
  if (!_.isEqual(allowedHierarchy, {})) {
    tablesToShow
      .add(tableUrlNames.PL)
      .add(tableUrlNames.PLByMonth)
      .add(tableUrlNames.budgeting)
      .add(tableUrlNames.PLByMonthSplit);
  }
  if (hasCard) tablesToShow.add(tableUrlNames.cardholder);
  return pivotLinks.filter(({ urlName }) => tablesToShow.has(urlName));
}
