import { v4 as uuidv4 } from "uuid";

function toRegExp(str) {
  if (str[0] !== "/") return str;
  const regExpEnd = str.lastIndexOf("/");
  if (regExpEnd === 0) return str;

  return RegExp(str.slice(1, regExpEnd), str.slice(regExpEnd + 1));
}

export function parseMatchingRulesTable(matchingRulesTable) {
  const headerRow = matchingRulesTable.header;
  return matchingRulesTable.data.map((row) => {
    const parsedMatchingRule = { match: {}, fill: {}, row_id: null };
    headerRow.forEach((key, index) => {
      if (key === "row_id") {
        parsedMatchingRule.row_id = row[index];
        return;
      }
      if (key.startsWith("match_")) {
        const originalKey = key.substr("match_".length);
        parsedMatchingRule.match[originalKey] = toRegExp(row[index]);
        return;
      }
      if (key.startsWith("fill_")) {
        const originalKey = key.substr("fill_".length);
        parsedMatchingRule.fill[originalKey] = toRegExp(row[index]);
        return;
      }
      return;
    });
    return parsedMatchingRule;
  });
}

function isMatch(str, strOrRegexp) {
  if (typeof strOrRegexp === "string") return str.indexOf(strOrRegexp) !== -1;
  else return str.match(strOrRegexp) != null;
}

function createPairingFromRule(transaction, rule) {
  // use rule.match
  return {
    uuid: uuidv4(),
    transaction_uuid: transaction.uuid,
    invoice_id: null,
    invoice_entity: null,
    currency: transaction.currency,
    amount: transaction.amount,
    account_from: `cash/${transaction.bank_account}`,
    account_to: rule.fill.account_to,
    center: rule.fill.center,
    po: rule.fill.po,
    pid:
      // if we have pid use it, if we have cash_transit_pid use it empty string otherwise
      rule.fill.pid != null && rule.fill.pid !== ""
        ? rule.fill.pid
        : rule.fill.cash_transit_pid != null &&
          rule.fill.cash_transit_pid !== ""
        ? transaction.amount > 0
          ? `${rule.fill.cash_transit_pid}>${transaction.bank_account}`
          : `${transaction.bank_account}>${rule.fill.cash_transit_pid}`
        : "",
    non_tax_expense:
      rule.fill.non_tax_expense.trim() !== "" &&
      rule.fill.non_tax_expense.toLowerCase().trim() !== "false"
        ? true
        : null,
    matching_method: `MATCHING_RULE_${rule.row_id}`,
  };
}

// can return null if no matching rules is applicable for this transaction
export function createPairingForTransactionWithMatchingRules(
  transaction,
  matchingRules
) {
  for (const rule of matchingRules) {
    const everyFieldIsMatch = Object.keys(rule.match).every((fieldKey) => {
      return isMatch(transaction[fieldKey] ?? "", rule.match[fieldKey]);
    });
    if (everyFieldIsMatch) {
      return createPairingFromRule(transaction, rule);
    }
  }
  return null;
}
