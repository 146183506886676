var _ = require("lodash");
var sa = {
  legalEntity: {
    colWidth: 150,
    header: "Legal entity"
  },
  date: {
    colWidth: 100,
    header: "Date"
  },
  taxDate: {
    colWidth: 100,
    header: "Tax date"
  },
  cur: {
    colWidth: 60,
    header: "Cur"
  },
  amtCur: {
    colWidth: 90,
    header: "Amt (Cur)"
  },
  source: {
    colWidth: 120,
    header: "Source"
  },
  accountingId: {
    colWidth: 120,
    header: "Accounting Id"
  },
  description: {
    colWidth: 150,
    header: "Description"
  },
  to: {
    colWidth: 120,
    header: "To"
  },
  from: {
    colWidth: 120,
    header: "From"
  },
  amtEur: {
    colWidth: 90,
    header: "Amt (EUR)"
  },
  trackingId: {
    colWidth: 120,
    header: "Tracking Id"
  },
  counterParty: {
    colWidth: 120,
    header: "Counterparty"
  },
  pid: {
    colWidth: 180,
    header: "PID"
  },
  centerKey: {
    colWidth: 180,
    header: "Center Key"
  },
  createdAt: {
    colWidth: 100,
    header: "Created at"
  },
  savedAt: {
    colWidth: 100,
    header: "Saved at"
  },
  year: {
    colWidth: 100,
    header: "Year"
  },
  month: {
    colWidth: 100,
    header: "Month"
  },
  internalSourceLink: {
    colWidth: 0,
    header: "Source link"
  },
  account: {
    colWidth: 120,
    header: "Account"
  }
};
module.exports = {
  $sa: _.reduce(Object.keys(sa), function (a, v, k) {
    a[v] = k;
    return a;
  }, {}),
  headers: Object.values(sa).map(function (_ref) {
    var header = _ref.header;
    return header;
  }),
  colWidths: Object.values(sa).map(function (_ref2) {
    var colWidth = _ref2.colWidth;
    return colWidth;
  })
};